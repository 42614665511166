import TextInput2 from 'components/TextInput2';
import TextArea from 'components/TextArea';

function InputGroup({
  disabled,
  label,
  placeholder,
  rows,
  textarea,
  value,
  style,
  children,
}: {
  disabled: boolean;
  label?: string;
  placeholder?: string;
  rows?: number;
  textarea?: boolean;
  value?: string;
  style?: object;
  children?: any;
}) {
  return (
    <div className="flex justify-between" style={{ width: '480px' }}>
      <div className="flex flex-col justify-start" style={{ marginTop: '5px' }}>
        <label>{label}</label>
      </div>
      {textarea ? (
        <TextArea
          disabled={disabled}
          example={placeholder}
          rows={rows}
          value={value}
          style={{ width: 300 }}
        />
      ) : (
        <TextInput2
          disabled={disabled}
          example={placeholder}
          value={value}
          style={{ width: 300 }}
        >
          {children}
        </TextInput2>
      )}
    </div>
  );
}

export default InputGroup;
