import styled from 'styled-components';
import TextInput2 from 'components/TextInput2';

export const Label = styled.label`
  color: gray;
`;

export const ContainerSignUp = styled.div<any>`
  margin-top: ${(props) =>
    props.$marginTop ? props.$marginTop : '-3em !important'};
  margin: auto;
  width: 100%;
`;

export const Title = styled.div`
  font-size: '20px';
  color: 'black;';
`;

export const AlignerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 70%;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Container = styled.div<any>`
  display: flex;
  width: 900px;
  ${(props) =>
    props?.$withAddress
      ? 'align-items: start; margin-top: 4rem;'
      : 'align-items: center;'}
`;

export const Input = styled(TextInput2)``;

export const InputGroup = styled.div`
  align-items: center;
  display: flex;
  height: 57.95px;
`;

export const InputGroupIE = styled.div`
  align-items: center;
  display: flex;
  height: 57.95px;
  margin-top: 15px;
`;

export const IconContainer = styled.div`
  height: 80px;
  width: 80px;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  padding-bottom: 30px;
`;

export const ImageIE = styled.img`
  height: auto;
  width: 100%;
  padding-bottom: 30px;
`;

export const LeftSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  /* padding: 1rem 0; */
  margin-right: 0.5rem;
`;

export const RightSection = styled.div`
  align-items: center;
  display: flex;
  width: 50%;
  padding: 25px;
`;

export const NoAasra = styled.div`
  ul {
    li {
      list-style-type: circle;
    }
  }
  a {
    color: blue;
    padding: 0.5rem 0;
  }
`;
