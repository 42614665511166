import {
  lazy,
  Suspense,
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import Carousel from 'components/Carousel';
import LaunchContent from 'components/LaunchContent';
import { AppContext } from 'components/App/Context';
import SubaruLaunchContent from 'components/LaunchContent/SubaruLaunchContent';
import FooterModals from 'features/FooterModals';
import UnderConstructionModal from 'features/UnderConstructionModal';

import * as S from './styled';
import Footer from 'components/Footer';
import QuickLinks from 'components/QuickLinks';
import Header from 'components/Header';
import ResetPassword from '../login/screens/ResetPassword';
// import Signup from '../signup';
import { APIContext } from 'components/API/Context';
import { OEMList as OEMListType } from 'types';
import { ThemeContext } from 'components/Theme/Context';
import {
  useConfig,
  useLanguages,
  useLoggedIn,
  useSessionExpiry,
  useLongToast,
  useToast,
  useClient,
} from 'utils/hooks';
import { useOEMList, useOEMUserList } from './hooks';
import PDFViewer from './components/PDFViewer';
import AasraModal from 'features/aasra';
import { redirectTokenFetch } from 'utils/apis';
import LoginModal from 'features/LoginModal';
import VersionCheck from './components/VersionCheck';
import HondaLaunchContent from 'components/LaunchContent/HondaLaunchContent';
import DeepalLaunchContent from 'components/LaunchContent/DeepalLaunchContent';
import SearchRoot from 'features/search';

const HomePage = lazy(() => import('features/HomePage'));
const StripePayment = lazy(() => import('features/StripePayment'));
const Login = lazy(() => import('../login'));
const GAPAdmin = lazy(() => import('features/gapAdmin'));
const OEMAdmin = lazy(() => import('features/oemAdmin'));
const AssetCategoryEdit = lazy(
  () => import('features/assets/categories/screens/Edit')
);
const AssetCategoryList = lazy(
  () => import('features/assets/categories/screens/List')
);
const AssetEdit = lazy(() => import('features/assets/assets/screens/Edit'));
const AssetList = lazy(() => import('features/assets/assets/screens/List'));
const AssetAccessEdit = lazy(
  () => import('features/assets/access/screens/Edit')
);
const AssetAccessList = lazy(
  () => import('features/assets/access/screens/List')
);
const OEMEdit = lazy(() => import('features/OEM/screens/Edit'));
const OEMList = lazy(() => import('features/OEM/screens/List'));
const Search = lazy(() => import('features/search/screens/Search'));
const VinSearch = lazy(() => import('features/search/screens/VinSearch'));
const URLAuthenticator = lazy(
  () => import('features/urlAuthenticator/screens/Search')
);
const DivisionEdit = lazy(() => import('features/divisions/screens/Edit'));
const DivisionList = lazy(() => import('features/divisions/screens/List'));
const BrandEdit = lazy(() => import('features/brand/screens/Edit'));
const BrandList = lazy(() => import('features/brand/screens/List'));
const ModelList = lazy(() => import('features/model/screens/List'));
const ModelEdit = lazy(() => import('features/model/screens/Edit'));
const ModelFamilyList = lazy(() => import('features/modelFamily/screens/List'));
const ModelFamilyEdit = lazy(() => import('features/modelFamily/screens/Edit'));
const ModelSeriesList = lazy(() => import('features/modelSeries/screens/List'));
const ModelSeriesEdit = lazy(() => import('features/modelSeries/screens/Edit'));
const ModelYearList = lazy(() => import('features/modelYear/screens/List'));
const ModelYearEdit = lazy(() => import('features/modelYear/screens/Edit'));
const ProductionYearList = lazy(
  () => import('features/productionYear/screens/List')
);
const ProductionYearEdit = lazy(
  () => import('features/productionYear/screens/Edit')
);
const RepairGroupList = lazy(() => import('features/repairGroup/screens/List'));
const RepairGroupEdit = lazy(() => import('features/repairGroup/screens/Edit'));
const RepairSubGroupList = lazy(
  () => import('features/repairSubGroup/screens/List')
);
const RepairSubGroupEdit = lazy(
  () => import('features/repairSubGroup/screens/Edit')
);
const RepairComponentList = lazy(
  () => import('features/repaircomponent/screens/List')
);
const RepairComponentEdit = lazy(
  () => import('features/repaircomponent/screens/Edit')
);
const AssetTypeList = lazy(() => import('features/assetsType/screens/List'));
const AssetTypeEdit = lazy(() => import('features/assetsType/screens/Edit'));
const AASRAProfileList = lazy(
  () => import('features/aasraProfile/screens/List')
);
const AASRAProfileEdit = lazy(
  () => import('features/aasraProfile/screens/Edit')
);
const Bulletins = lazy(() => import('features/bulletins/screens/Bulletins'));
const TSR = lazy(() => import('features/bulletins/screens/TSR'));
const RequestCode = lazy(() => import('features/Request/screens/RequestCode'));
const RequestCodeResponse = lazy(
  () => import('features/Request/screens/RequestCodeResponse')
);
const RequestSoftware = lazy(
  () => import('features/Request/screens/RequestSoftware')
);

const Signup = lazy(() => import('features/R2RSignupModal'));
// const GAPData = lazy(() => import('features/oemAdmin/components/GAPsTable'));
const Subscriptions = lazy(() => import('features/Subscriptions'));
const Support = lazy(() => import('features/Support'));
const ClientOEMAdmin = lazy(() => import('features/oemAdminClient'));
const PortalAccess = lazy(() => import('features/PortalAccess'));
const DiagnosticAccess = lazy(() => import('features/DiagnosticAccess'));
const SupportGaps = lazy(() => import('features/SupportGaps'));

const _pages = [
  'assetCategory',
  'oems',
  'brands',
  'divisions',
  'modelFamily',
  'modelSeries',
  'modelYear',
  'model',
  'productionYear',
  'repairGroup',
  'repairSubGroup',
  'repairComponent',
  'assetType',
  'assets',
  'aasraProfile',
  'oem',
  'gap',
  'usersdata',
  'gaps',
  'subscriptions',
  'activesubs',
  'clientoemadmin',
  'portal',
  'diagnostic',
  '',
];

const Redirecter = ({ doLogout }: { doLogout: Function }) => {
  const { user, status } = useLoggedIn(true, true);
  const {
    hasAdmin,
    hasAssets,
    hasAccessProfiles,
    hasOemAdmin,
    hasGapAdmin,
    hasSupport,
    hasClientAdmin,
  } = useOEMUserList();
  const history = useHistory();
  const location = useLocation();
  const { data: oems }: { data: OEMListType[] | undefined } = useOEMList();
  const { setUser, user: appUser, noSubSignin } = useContext(AppContext);
  const { toast } = useToast();
  const { messages } = useLanguages();

  useEffect(() => {
    if (!oems) return;
    const client = location.pathname.split('/')[1];
    const slugs = location.pathname.split('/');
    const search = window.location.search;
    const storedClient = window.sessionStorage.getItem('client');
    const data = oems.find((x) => x.path === client);
    // console.log('slugs ::::', user?.email, slugs, storedClient, client, data);

    const urlParams = new URLSearchParams(window.location.href.split('?')?.[1]);
    const subType = urlParams.get('subtype');
    const redirectToken = urlParams.get('redirecttoken');

    if (!!subType || !!redirectToken || noSubSignin) return;

    if (
      slugs.length === 4 &&
      !!client &&
      slugs[2] === 'requestApproval' &&
      !!slugs[3]
    ) {
      return;
    }

    if (slugs.length === 3 && !!client && slugs[2] === 'signup') {
      return;
    }

    // console.log(
    //   'HERE 1 ::::::", ',
    //   slugs,
    //   status,
    //   user?.email,
    //   storedClient,
    //   client
    // );

    if (slugs.length === 2 && !!client) {
      // console.log('APPROOT RESET');
      setUser(appUser);
    }
    if (slugs[1] === 'auth') return;
    if ((!data || !data?.path) && !!client) {
      history.push(`/${client}`);
      return;
    }
    if (slugs[2] === 'pdf-viewer') return;
    if (slugs[2] === 'login') return;
    if (!user && slugs.length > 2 && !!client) {
      history.push(`/${client}`);
      return;
    } else if (!user) return;
    if (storedClient !== client && !!storedClient) {
      history.push(`/${storedClient}`);
      return;
    }
    if (!storedClient && user?.email) {
      console.log('no storedclient');
      doLogout('no stored client');
      history.push(`/${client || ''}`);
      return;
    }

    // console.log('HERE 2 ::::::", ', slugs, user?.email, hasAdmin, hasOemAdmin, hasGapAdmin);

    if (user?.email && slugs.length === 3 && slugs[2] === 'assets') {
      if (hasAssets) return;
      else history.push(`/${client}`);
    }
    if (
      user?.email &&
      slugs.length > 3 &&
      slugs[2] === 'assets' &&
      (slugs[3] === 'find' ||
        slugs[3] === 'vinsearch' ||
        slugs[3] === 'bulletins' ||
        slugs[3] === 'tsr' ||
        slugs[3] === 'request')
    ) {
      if (hasAssets) return;
      else history.push(`/${client}`);
    }

    if (
      user?.email &&
      slugs.length > 3 &&
      slugs[2] === 'support' &&
      (slugs[3] === 'activesubs' ||
        slugs[3] === 'clientoemadmin' ||
        slugs[3] === 'dashboard' ||
        slugs[3] === 'diagnostic' ||
        slugs[3] === 'gaps')
    ) {
      if (hasAdmin || hasSupport || hasClientAdmin) return;
      else history.push(`/${client}`);
    }

    if (
      user?.email &&
      slugs.length > 3 &&
      slugs[2] === 'support' &&
      (slugs[3] === 'usersdata' || slugs[3] === 'portal')
    ) {
      if (hasAdmin || hasSupport) return;
      else history.push(`/${client}`);
    }

    if (
      user?.email &&
      slugs.length > 3 &&
      slugs[2] === 'administrator' &&
      slugs[3] === 'activesubs'
    ) {
      if (hasClientAdmin) return;
      else history.push(`/${client}`);
    }

    if (
      user?.email &&
      slugs.length > 3 &&
      slugs[2] === 'admin' &&
      slugs[3] === 'access'
    ) {
      if (hasAccessProfiles) return;
      else history.push(`/${client}`);
    }
    if (user?.email && slugs[2] === 'admin' && slugs[3] === 'gap') {
      if (hasGapAdmin) return;
      console.log('no gapadmin');
      doLogout('no gapadmin');
      history.push(`/${client || ''}`);
      return;
    }
    // console.log('HERE 3 ::::::", ', slugs, user?.email, hasAdmin, hasOemAdmin, hasGapAdmin);
    if (
      user?.email &&
      slugs.length === 3 &&
      (slugs[2] === 'admin' || slugs[2] === 'access')
    ) {
      if (
        hasAdmin ||
        hasOemAdmin ||
        hasGapAdmin ||
        hasClientAdmin ||
        hasSupport
      )
        return;
      else return history.push(`/${client}`);
    }
    if (
      user?.email &&
      slugs.length > 3 &&
      (slugs[2] === 'admin' || slugs[2] === 'access')
    ) {
      if (
        !hasAdmin &&
        !hasOemAdmin &&
        !hasGapAdmin &&
        !hasClientAdmin &&
        !hasSupport
      )
        return history.push(`/${client}`);
    }
    if (user?.email && slugs.length >= 4 && _pages.indexOf(slugs[4]) < 0) {
      history.push(`/${client}`);
    }
  }, [
    location,
    history,
    user,
    doLogout,
    oems,
    hasAssets,
    hasAdmin,
    hasAccessProfiles,
    hasOemAdmin,
    hasGapAdmin,
    hasSupport,
    hasClientAdmin,
    setUser,
    appUser,
    noSubSignin,
  ]);

  return <span />;
};

const ContentHome = styled.iframe`
  width: 100%;
  height: 100%;
  display: block !important;
  margin: auto;
  box-sizing: border-box;
  /* height: 125vh; */
  border: none;
  overflow: hidden;
`;

const IframeContent = () => {
  const { messages } = useLanguages();
  const { defaults } = useConfig();

  const slugs = window.location.pathname.split('/');
  const client = slugs[1];

  if (client !== 'ineos') {
    return (
      <>
        <Helmet>
          <title>{messages[client || 'r2r']}</title>
        </Helmet>
        {client === 'r2r' || client == '' ? (
          <>
            <QuickLinks />
          </>
        ) : (
          <>
            <Carousel imageList={defaults?.carouselList} />
          </>
        )}

        {client === 'subaru' ? (
          <SubaruLaunchContent />
        ) : client === 'honda' ? (
          <HondaLaunchContent />
        ) : client === 'deepal' ? (
          <DeepalLaunchContent launchContent={defaults?.launchContent} />
        ) : (
          <LaunchContent launchContent={defaults?.launchContent} />
        )}
      </>
    );
  }

  const src = `/content/${
    client === 'ineos' ? 'ineosWebsite' : client === 'mmal' ? 'mmal' : 'r2r'
  }/home/index.html`;

  return (
    <>
      <Helmet>
        <title>{messages[client]}</title>
      </Helmet>
      <ContentHome id="iframe-content" src={src} scrolling="yes" />
    </>
  );
};

export const FSLoader = ({ which }: { which?: string }) => (
  <>
    <S.FullScreenLoaderBG />
    <S.FullScreenLoader>
      <div className="sbl-circ-path"></div>
      {/* <FormattedMessage id="loading" /> */}
      {/* {which ? `::${which}::` : ''} */}
    </S.FullScreenLoader>
  </>
);

const NoSubRedirect = () => {
  const { client } = useClient();
  const { doLogout } = useLoggedIn();
  const { longToast } = useLongToast();
  const { messages } = useLanguages();
  const history = useHistory();

  useEffect(() => {
    longToast(messages.noSubscriptionGap, 'error');
    doLogout();
    setTimeout(() => history.push(`/${client}`), 5000);
    /* eslint-disable-next-line */
  }, []);
  // }, [longToast, messages, client, doLogout, history]);

  return <FSLoader />;
};

let _aasra: any = null;

export default function App() {
  const { user, status, doLogout, aasraRoles, setAASRARole, refreshLogin } =
    useLoggedIn(true, true);
  // const { theme } = useContext(ThemeContext);
  const [resetState, setResetState] = useState<any>();
  const { messages } = useLanguages();
  const { inProgress, prefetch } = useContext(APIContext);
  const { defaults } = useConfig();
  const history = useHistory();
  const { toast } = useToast();
  const { longToast } = useLongToast();
  const { client } = useClient();
  const [aasraFetch, setAasraFetch] = useState<'' | 'fetching' | 'done'>('');
  const {
    hasAdmin,
    hasAssets,
    hasAccessProfiles,
    hasClientAdmin,
    hasUrlAuth,
    hasOemAdmin,
    hasGapAdmin,
    hasGAPAccess,
    hasNoAasraLogin,
    isLoading,
    isLoadingUser,
    subscription,
    hasSupport,
    // setSubscriptionStatus
  } = useOEMUserList();
  const {
    closeFooterModal,
    pdfStr,
    openFooterModal,
    showFooterModal,
    modalType,
    noSubSignin,
    setNoSubSignin,
  } = useContext(AppContext);
  const [aToken, setAToken] = useState<string | undefined | null>();
  const [timedout, setTimedout] = useState<boolean>(false);
  const [showUnderConstructionModal, setShowUnderConstructionModal] =
    useState<boolean>(false);
  const [subtype, setSubtype] = useState<string>('');
  const loc = useLocation();

  const signupClient = window.localStorage.getItem('signup_client');
  if (signupClient && client !== signupClient) {
    window.localStorage.removeItem('signup_client');
    const searchPart = window.location.href.split('?')?.[1];
    history.push(`/${signupClient}?${searchPart}`);
  }

  const resetpwdClient = window.localStorage.getItem('resetpwd_client');
  if (resetpwdClient && client !== signupClient) {
    window.localStorage.removeItem('resetpwd_client');
    const searchPart = window.location.href.split('#')?.[1];
    history.push(`/${resetpwdClient}#${searchPart}`);
  }

  useEffect(() => {
    const isDev =
      window.location.host.includes('dev') ||
      window.location.host.includes('localhost');
    console.log('DEEPAL', isDev, defaults, loc.pathname);
    if (!isDev && defaults?.devEnabled && loc.pathname.includes(defaults.path))
      history.replace('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc, defaults]);

  useEffect(() => {
    const f = async () => {
      // console.log('auth ::::', defaults, user?.email);
      if (!user?.email) {
        setTimeout(() => refreshLogin(false), 100);
      }
    };
    f();
    /* eslint-disable-next-line */
  }, [defaults, user, refreshLogin]);

  useEffect(() => {
    if (noSubSignin) {
      prefetch();
    }
    /* eslint-disable-next-line */
  }, [noSubSignin]);

  useEffect(() => {
    let tout;
    // console.log('status::::', status, user);
    if (status === 'loading') {
      tout = setTimeout(() => {
        setTimedout(true);
      }, 15000);
    } else {
      setTimedout(false);
      clearTimeout(tout);
    }
    return () => {
      tout && clearTimeout(tout);
      setTimedout(false);
    };
  }, [status, user]);

  useEffect(() => {
    if (user && _aasra?.length) {
      setAASRARole(_aasra);
    }
    /* eslint-disable-next-line */
  }, [user]);

  const urlParams = new URLSearchParams(window.location.href.split('?')?.[1]);
  let redirectToken = urlParams.get('redirecttoken');
  const email = urlParams.get('email');
  const subType = urlParams.get('subtype') || 'subscribe';
  const noSubs = urlParams.get('noSubs');

  useEffect(() => {
    if (noSubs === 'y') setNoSubSignin(true);
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (aasraFetch !== '') return;
    if (_aasra?.length) {
      setAASRARole(_aasra);
      return;
    }

    const f = async () => {
      const resp: any = await redirectTokenFetch(
        email || 'none',
        redirectToken || 'none'
      );
      if (resp.error) {
        toast(`Error: ${resp.error}`, 'error');
        history.push(`/${client}/login`);
        setAasraFetch('done');
        return;
      }
      setAASRARole(resp?.aasra?.roles);
      _aasra = resp?.aasra?.roles;
      setTimeout(() => {
        // setAASRARole(resp?.aasra?.roles);
        if (['subscribe', 'individual', 'gap'].includes(subType)) {
          history.push(`/${client}/login`);
        }
      }, 4000);
      prefetch();
      setAasraFetch('done');
      console.log('REDIRECT TOKEN :::', resp);
    };

    if (redirectToken) {
      setAasraFetch('fetching');
      f();
    }
    /* eslint-disable-next-line */
  }, [aasraFetch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')?.[1]);
    const email = urlParams.get('email');
    const status = urlParams.get('status');
    const isadmin = urlParams.get('isadmin');
    const hasloggedin = urlParams.get('hasloggedin');
    const client = location.pathname.split('/')[1];

    if (!subType) return;
    console.log('urlParams ::::', window.location.href, subType, status);

    setSubtype(subType);

    if (['resubscribe', 'gapresub'].includes(subType) && status === 'success') {
      longToast(messages.subSuccess, 'success');
      // longToast(messages.subSuccess2, 'success');
      // longToast(messages.subSuccess3, 'success');
      //  toast(messages.subSuccess, 'success');
    } else if (
      ['resubscribe', 'gapresub'].includes(subType) &&
      status === 'cancelled'
    ) {
      toast(messages.subCancelled, 'warning');
    } else if (
      ['subscribe', 'individual', 'gap'].includes(subType) &&
      status === 'success'
    ) {
      longToast(messages.subSuccess, 'success');
      if (hasloggedin !== 'yes') longToast(messages.subSuccess2, 'success');
      if (subType === 'gap' && isadmin !== 'yes')
        longToast(messages.subSuccess3, 'success');
      console.log(messages.subSuccessLogin);
      // toast(messages.subSuccessLogin, 'success');
    } else if (
      ['subscribe', 'individual', 'gap'].includes(subType) &&
      status === 'cancelled'
    ) {
      toast(messages.subCancelled, 'warning');
    } else if (['diagnostic'].includes(subType) && status === 'success') {
      longToast(messages.subSuccess, 'success');
      console.log(messages.subSuccessLogin);
      // toast(messages.subSuccessLogin, 'success');
    } else if (['diagnostic'].includes(subType) && status === 'cancelled') {
      toast(messages.subCancelled, 'warning');
    }
    if (!['diagnostic', 'keycode', 'software'].includes(subType)) {
      // setTimeout(() => {
      // history.push(`/${client}`);
      // window.location.reload();
      // }, 7500);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('#')?.[1]);
    const ptype = urlParams.get('type');
    const accessToken = urlParams.get('access_token');
    // http://localhost:3000/#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjQwMzE5NjU2LCJzdWIiOiJkZjE5NDZmYi0wMWUxLTQ4YWQtOTE4ZC04MDMxYjMwNDk2MDIiLCJlbWFpbCI6ImpvaG4uYm93ZGVuQGVuY29sbGFiLmNvbSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnt9LCJyb2xlIjoiYXV0aGVudGljYXRlZCJ9.yRmTSiIFAxcP5bc5kOGvXx7vYJbIN_Lh-HwnTmQjXs8&expires_in=3600&refresh_token=VIqQVyN4P1b8sEKgX9Ry2g&token_type=bearer&type=recovery

    // console.log('PAGE *******', window.location.href, ptype, accessToken);
    setAToken(accessToken);

    if (ptype === 'recovery' && accessToken) {
      setResetState({ accessToken });
    } else if (ptype) {
      toast('verifySuccess', 'success');
    }

    /* eslint-disable-next-line */
  }, []);

  function showModal(e: any, str?: string) {
    const modalType = e.target.id || e.detail.description;
    const pdfStr = str || e.detail.data;
    openFooterModal(modalType, pdfStr);
  }

  // console.log(
  //   'PAGE ::::',
  //   !hasAdmin,
  //   !hasOemAdmin,
  //   !(hasGapAdmin && client === 'r2r') && !hasAccessProfiles,
  //   !!user,
  //   aasraRoles,
  //   status
  // );

  // console.log('inProgress ::::', inProgress);

  let mustAasra =
    !hasAdmin &&
    !hasOemAdmin &&
    // !hasGapAdmin &&
    client !== 'r2r' &&
    !hasNoAasraLogin &&
    !hasAccessProfiles &&
    !hasOemAdmin &&
    !hasUrlAuth &&
    !!user &&
    aasraRoles === null;

  if (!!redirectToken) mustAasra = false;

  // console.log('must aasra ::::', status, client, mustAasra, aasraRoles);
  // console.log('user page ::::', user);

  let hasOemAdminClient = false;
  const cfg = defaults?.oemadminconfig;
  if (cfg) {
    const users = cfg.split('|')[0];
    if (users.includes(user?.email)) hasOemAdminClient = true;
  }

  return (
    <>
      <Header />

      <VersionCheck />

      {aasraFetch === 'fetching' && <FSLoader which="redirectToken" />}
      {/* {['subscribe', 'individual', 'gap', 'resubscribe', 'gapresub'].includes(
        subtype
      ) && <FSLoader which="subType" />} */}

      {/* TEMPORARY*/}
      {/* {!['ineos'].includes(location.pathname.split('/')[1].toLowerCase()) &&
        showUnderConstructionModal && (
          <UnderConstructionModal
            close={() => setShowUnderConstructionModal(false)}
          />
        )} */}
      {/* TEMPORARY*/}
      {showFooterModal && (
        <>
          <FooterModals
            close={closeFooterModal}
            description={modalType}
            pdf={pdfStr}
          />
        </>
      )}
      {resetState?.accessToken ? (
        <ResetPassword
          accessToken={resetState.accessToken}
          resetSuccess={() => setResetState(undefined)}
        />
      ) : (
        <div id="r2r-content" style={{ height: '100%', overflow: 'auto' }}>
          {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
          {!isLoading && !isLoadingUser && status === 'done' && user && (
            <Redirecter doLogout={() => doLogout('page1')} />
          )}
          {status === 'done' && !user && (
            <Redirecter doLogout={() => doLogout('page2')} />
          )}
          <Suspense fallback={<FSLoader which="FB" />}>
            <Switch>
              <Route exact path="/:client/requestApproval/:token">
                <RequestCodeResponse />
              </Route>

              {(status === 'loading' || isLoading || isLoadingUser) &&
              !(
                (status === 'done' && !!user) ||
                (status === 'done' && !user)
              ) &&
              // status !== 'done' &&
              !aToken &&
              !timedout ? (
                <FSLoader which="doneNoUser" />
              ) : !!redirectToken && !aasraRoles?.length ? (
                <FSLoader which="redirectToken" />
              ) : !user?.email ? (
                <>
                  {/* <Route exact path="/auth/signup">
                    <Signup
                      client={client}
                      close={() => {}}
                      page={2}
                      image={defaults?.loginmodalimg}
                    />
                  </Route> */}
                  <Route exact path="/">
                    <>
                      <IframeContent />
                    </>
                  </Route>
                  <Route exact path="/:client/login">
                    <LoginModal
                      close={() => history.push(`/${client}`)}
                      client={client}
                    />
                  </Route>
                  <Route exact path="/:client/signup">
                    <Signup client={client} close={null} page={2} />
                  </Route>
                  <Route exact path="/:client">
                    <>
                      {/* <StripePayment close={() => {}}></StripePayment> */}
                      <IframeContent />
                    </>
                  </Route>
                </>
              ) : noSubSignin && !mustAasra ? (
                <>
                  <Route exact path="/:client/assets/find/:assetCategoryName">
                    {/* {defaults?.resultsfromvinlookup ? (
                      <VinSearch />
                    ) : (
                      <Search />
                    )} */}
                    <SearchRoot defaults={defaults} />
                  </Route>

                  <Route exact path={`/${client}/admin`}>
                    <>
                      <Helmet>
                        <title>{messages.maintenance}</title>
                      </Helmet>
                      <IframeContent />
                    </>
                  </Route>
                  <Route exact path={`/${client}/admin/maintenance/gap`}>
                    <GAPAdmin />
                  </Route>

                  <Route exact path="/:client">
                    <>
                      <IframeContent />
                    </>
                  </Route>
                  <Route exact path="/:client/assets">
                    <>
                      <IframeContent />
                    </>
                  </Route>

                  <Route exact path="/:client/assets/request/key">
                    <Helmet>
                      <title>{messages.requestCode}</title>
                    </Helmet>

                    <RequestCode type="Code" />
                  </Route>
                  <Route exact path="/:client/assets/request/software">
                    <Helmet>
                      <title>{messages.softwareUpdate}</title>
                    </Helmet>

                    <RequestSoftware type="software" />
                  </Route>
                </>
              ) : mustAasra ? (
                <AasraModal close={() => doLogout('close aasra 2')} noClose />
              ) : !hasNoAasraLogin &&
                !!user &&
                user?.gapid === 1 &&
                !hasAdmin &&
                !hasOemAdmin &&
                !hasAccessProfiles &&
                !hasOemAdmin &&
                !subscription?.find((x) => x?.isvalid) ? (
                <StripePayment
                  close={async () => {
                    const client = window.location.pathname.split('/')[1];
                    console.log('close stripe ****');
                    await doLogout('close stripe');
                    window.location.href = `/${client}`;
                  }}
                  type={'resubscribe'}
                  gapid={1}
                  email={user?.email}
                />
              ) : !hasNoAasraLogin &&
                !!user &&
                (hasGapAdmin || hasGAPAccess) &&
                !hasAdmin &&
                !hasOemAdmin &&
                !hasAccessProfiles &&
                !hasOemAdmin &&
                !subscription?.find((x) => x?.isvalid) ? (
                <StripePayment
                  close={async () => {
                    const client = window.location.pathname.split('/')[1];
                    console.log('close stripe ****');
                    await doLogout('close stripe');
                    window.location.href = `/${client}`;
                  }}
                  type={'gapresub'}
                  gapid={user?.gapid}
                  email={user?.email}
                />
              ) : !hasNoAasraLogin &&
                !!user &&
                !hasGapAdmin &&
                user?.gapid !== 1 &&
                !hasAdmin &&
                !hasOemAdmin &&
                !hasAccessProfiles &&
                !hasOemAdmin &&
                !subscription?.find((x) => x?.isvalid) ? (
                <NoSubRedirect />
              ) : (
                <>
                  {(!inProgress?.f || !inProgress?.n || !inProgress?.a) && (
                    <FSLoader which="API" />
                  )}
                  <Route exact path="/:client/pdf-viewer">
                    <>
                      <PDFViewer />
                    </>
                  </Route>

                  <Route exact path="/">
                    <div className="w-100 p-5 text-center">
                      <FormattedMessage id="unknownUser" />
                    </div>
                  </Route>

                  <Route exact path="/:client">
                    <>
                      {/* <StripePayment close={() => {}}></StripePayment> */}
                      <IframeContent />
                    </>
                  </Route>

                  <Route path="*">
                    <>
                      {mustAasra && (
                        <AasraModal
                          close={() => doLogout('close aasra 1')}
                          noClose
                        />
                      )}
                      {/* <IframeContent /> */}
                    </>
                  </Route>

                  {hasAssets && !mustAasra && (
                    <>
                      <Route exact path="/:client/assets">
                        <>
                          <Helmet>
                            <title>{messages.assetsSearch}</title>
                          </Helmet>
                          <IframeContent />
                        </>
                      </Route>
                      <Route
                        exact
                        path="/:client/assets/find/:assetCategoryName"
                      >
                        {/* {defaults?.resultsfromvinlookup ? (
                          <VinSearch />
                        ) : (
                          <Search />
                        )} */}
                        <SearchRoot defaults={defaults} />
                      </Route>
                      <Route exact path="/:client/assets/vinsearch">
                        <VinSearch />
                      </Route>
                      <Route
                        exact
                        path="/:client/assets/bulletins/:assetCategoryName"
                      >
                        <Bulletins />
                      </Route>
                      <Route
                        exact
                        path="/:client/assets/tsr/:assetCategoryName"
                      >
                        <TSR />
                      </Route>
                      <Route exact path="/:client/assets/request/key">
                        <Helmet>
                          <title>{messages.requestCode}</title>
                        </Helmet>

                        <RequestCode type="Code" />
                      </Route>
                      <Route exact path="/:client/assets/request/software">
                        <Helmet>
                          <title>{messages.softwareUpdate}</title>
                        </Helmet>

                        <RequestSoftware type="software" />
                      </Route>
                    </>
                  )}

                  {hasUrlAuth && (
                    <>
                      <Route exact path="/:client/urlAuthenticator">
                        <URLAuthenticator />
                      </Route>
                    </>
                  )}

                  {hasAccessProfiles && (
                    <>
                      <Route exact path="/:client/access">
                        <>
                          <Helmet>
                            <title>{messages.accessProfiles}</title>
                          </Helmet>
                          <IframeContent />
                        </>
                      </Route>

                      <Route exact path="/:client/access/maintenance/assets">
                        <AssetAccessList />
                      </Route>
                      <Route
                        exact
                        path="/:client/access/maintenance/assets/edit"
                      >
                        <AssetAccessEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/access/maintenance/assets/edit/:id"
                      >
                        <AssetAccessEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/access/maintenance/assets/edit/:id/:languageId"
                      >
                        <AssetAccessEdit />
                      </Route>
                    </>
                  )}

                  {hasGapAdmin && (
                    <>
                      <Route exact path={`/${client}/admin`}>
                        <>
                          <Helmet>
                            <title>{messages.maintenance}</title>
                          </Helmet>
                          <IframeContent />
                        </>
                      </Route>
                      <Route exact path={`/${client}/admin/maintenance/gap`}>
                        <GAPAdmin />
                      </Route>
                    </>
                  )}

                  {hasOemAdmin && (
                    <>
                      {!hasAdmin && (
                        <Route exact path="/:client/admin">
                          <>
                            <Helmet>
                              <title>{messages.maintenance}</title>
                            </Helmet>
                            <IframeContent />
                          </>
                        </Route>
                      )}
                      <Route exact path="/:client/admin/maintenance/oem">
                        <OEMAdmin />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/subscriptions"
                      >
                        <Subscriptions />
                      </Route>
                    </>
                  )}

                  {hasAdmin && (
                    <>
                      <Route exact path="/:client/admin">
                        <>
                          <Helmet>
                            <title>{messages.maintenance}</title>
                          </Helmet>
                          <IframeContent />
                        </>
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/aasraProfile"
                      >
                        <AASRAProfileList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/aasraProfile/edit"
                      >
                        <AASRAProfileEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/aasraProfile/edit/:id"
                      >
                        <AASRAProfileEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/aasraProfile/edit/:id/:languageId"
                      >
                        <AASRAProfileEdit />
                      </Route>

                      <Route exact path="/:client/admin/maintenance/oems">
                        <OEMList />
                      </Route>
                      <Route exact path="/:client/admin/maintenance/oems/edit">
                        <OEMEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/oems/edit/:id"
                      >
                        <OEMEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/oems/edit/:id/:languageId"
                      >
                        <OEMEdit />
                      </Route>

                      <Route exact path="/:client/admin/maintenance/brands">
                        <BrandList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/brands/edit"
                      >
                        <BrandEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/brands/edit/:id"
                      >
                        <BrandEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/brands/edit/:id/:languageId"
                      >
                        <BrandEdit />
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/assetCategory"
                      >
                        <AssetCategoryList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetCategory/edit"
                      >
                        <AssetCategoryEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetCategory/edit/:assetId"
                      >
                        <AssetCategoryEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetCategory/edit/:assetId/:languageId"
                      >
                        <AssetCategoryEdit />
                      </Route>

                      {/* <Route exact path="/:client/admin/maintenance/assets">
                        <AssetList />
                      </Route> */}
                      {/* <Route
                        exact
                        path="/:client/admin/maintenance/assets/edit"
                      >
                        <AssetEdit />
                      </Route> */}
                      {/* <Route
                        exact
                        path="/:client/admin/maintenance/assets/edit/:assetId"
                      >
                        <AssetEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assets/edit/:assetId/:languageId"
                      >
                        <AssetEdit />
                      </Route> */}

                      <Route exact path="/:client/admin/maintenance/divisions">
                        <DivisionList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/divisions/edit"
                      >
                        <DivisionEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/divisions/edit/:id"
                      >
                        <DivisionEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/divisions/edit/:id/:languageId"
                      >
                        <DivisionEdit />
                      </Route>

                      <Route exact path="/:client/admin/maintenance/model">
                        <ModelList />
                      </Route>
                      <Route exact path="/:client/admin/maintenance/model/edit">
                        <ModelEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/model/edit/:id"
                      >
                        <ModelEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/model/edit/:id/:languageId"
                      >
                        <ModelEdit />
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/modelFamily"
                      >
                        <ModelFamilyList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelFamily/edit"
                      >
                        <ModelFamilyEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelFamily/edit/:id"
                      >
                        <ModelFamilyEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelFamily/edit/:id/:languageId"
                      >
                        <ModelFamilyEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelSeries"
                      >
                        <ModelSeriesList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelSeries/edit"
                      >
                        <ModelSeriesEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelSeries/edit/:id"
                      >
                        <ModelSeriesEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelSeries/edit/:id/:languageId"
                      >
                        <ModelSeriesEdit />
                      </Route>
                      <Route exact path="/:client/admin/maintenance/modelYear">
                        <ModelYearList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelYear/edit"
                      >
                        <ModelYearEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelYear/edit/:id"
                      >
                        <ModelYearEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/modelYear/edit/:id/:languageId"
                      >
                        <ModelYearEdit />
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/productionYear"
                      >
                        <ProductionYearList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/productionYear/edit"
                      >
                        <ProductionYearEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/productionYear/edit/:id"
                      >
                        <ProductionYearEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/productionYear/edit/:id/:languageId"
                      >
                        <ProductionYearEdit />
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/repairGroup"
                      >
                        <RepairGroupList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairGroup/edit"
                      >
                        <RepairGroupEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairGroup/edit/:id"
                      >
                        <RepairGroupEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairGroup/edit/:id/:languageId"
                      >
                        <RepairGroupEdit />
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/repairSubGroup"
                      >
                        <RepairSubGroupList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairSubGroup/edit"
                      >
                        <RepairSubGroupEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairSubGroup/edit/:id"
                      >
                        <RepairSubGroupEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairSubGroup/edit/:id/:languageId"
                      >
                        <RepairSubGroupEdit />
                      </Route>

                      <Route
                        exact
                        path="/:client/admin/maintenance/repairComponent"
                      >
                        <RepairComponentList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairComponent/edit"
                      >
                        <RepairComponentEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairComponent/edit/:id"
                      >
                        <RepairComponentEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/repairComponent/edit/:id/:languageId"
                      >
                        <RepairComponentEdit />
                      </Route>

                      <Route exact path="/:client/admin/maintenance/assetType">
                        <AssetTypeList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetType/edit"
                      >
                        <AssetTypeEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetType/edit/:id"
                      >
                        <AssetTypeEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetType/edit/:id/:languageId"
                      >
                        <AssetTypeEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assetType/edit/:id/:languageId"
                      >
                        <AssetTypeEdit />
                      </Route>
                    </>
                  )}

                  {(hasAdmin || hasSupport || hasClientAdmin) && (
                    <>
                      <Route exact path={`/${client}/support`}>
                        <>
                          <Helmet>
                            <title>{messages.maintenance}</title>
                          </Helmet>
                          <IframeContent />
                        </>
                      </Route>
                      {client === 'mmal' && (
                        <Route exact path={`/${client}/support/dashboard`}>
                          <>
                            <br />
                            <iframe
                              style={{
                                margin: 'auto',
                                height: '81vh',
                                width: '97.5vw',
                              }}
                              title="R2R - MMAL - DASHBOARD - DASHBOARD"
                              // width="1900"
                              // height="90vh"
                              src="https://app.powerbi.com/view?r=eyJrIjoiOGMxZWEwNWEtNDhiMi00MGE3LWEyY2YtZThkMmM3ZmVjMmRlIiwidCI6IjA0YmRiMTA1LWQ5MTgtNDg4My04OTVkLTcxOWNhZGFjN2UxNSJ9"
                              frameBorder="0"
                              allowFullScreen
                            ></iframe>
                          </>
                        </Route>
                      )}
                      {client === 'honda' && (
                        <Route exact path={`/${client}/support/dashboard`}>
                          <>
                            <br />
                            <iframe
                              style={{
                                margin: 'auto',
                                height: '81vh',
                                width: '97.5vw',
                              }}
                              title="R2R - HAPL - DASHBOARD - DASHBOARD"
                              // width="1900"
                              // height="840"
                              src="https://app.powerbi.com/view?r=eyJrIjoiODRlYzFhZDUtYTRmZi00ZWRlLWFkNWEtZTI3YjQzNDVlZDIzIiwidCI6IjA0YmRiMTA1LWQ5MTgtNDg4My04OTVkLTcxOWNhZGFjN2UxNSJ9"
                              frameBorder="0"
                              allowFullScreen
                            ></iframe>
                          </>
                        </Route>
                      )}
                      {client === 'subaru' && (
                        <Route exact path={`/${client}/support/dashboard`}>
                          <>
                            <br />
                            <iframe
                              style={{
                                margin: 'auto',
                                height: '81vh',
                                width: '97.5vw',
                              }}
                              title="R2R - SAPL - DASHBOARD - DASHBOARD"
                              // width="1900"
                              // height="840"
                              src="https://app.powerbi.com/view?r=eyJrIjoiNTUwZjBlZGQtYzU3Mi00ZGJmLWIxYTctZTNiMDg2ODBmNGM4IiwidCI6IjA0YmRiMTA1LWQ5MTgtNDg4My04OTVkLTcxOWNhZGFjN2UxNSJ9"
                              frameBorder="0"
                              allowFullScreen
                            ></iframe>
                          </>
                        </Route>
                      )}
                      <Route exact path="/:client/support/activesubs">
                        <Support onlyActiveSubs={true} defaults={defaults} />
                      </Route>
                      <Route exact path="/:client/admin/maintenance/assets">
                        <AssetList />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assets/edit"
                      >
                        <AssetEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assets/edit/:assetId"
                      >
                        <AssetEdit />
                      </Route>
                      <Route
                        exact
                        path="/:client/admin/maintenance/assets/edit/:assetId/:languageId"
                      >
                        <AssetEdit />
                      </Route>
                    </>
                  )}

                  {(hasOemAdminClient || hasSupport) && (
                    <Route exact path="/:client/support/clientoemadmin">
                      <ClientOEMAdmin />
                    </Route>
                  )}

                  {(client === 'honda' || client === 'subaru') &&
                    (hasAdmin || hasSupport) && (
                      <Route exact path="/:client/support/diagnostic">
                        <DiagnosticAccess />
                      </Route>
                    )}

                  {(hasAdmin || hasSupport) && (
                    <>
                      <Route exact path="/:client/support/usersdata">
                        <Support defaults={defaults} onlyActiveSubs={false} />
                      </Route>
                      <Route exact path="/:client/support/portal">
                        <PortalAccess />
                      </Route>
                      <Route exact path="/:client/support/gaps">
                        <SupportGaps />
                      </Route>
                    </>
                  )}

                  {hasClientAdmin && (
                    <>
                      <Route exact path={`/${client}/administrator`}>
                        <>
                          <Helmet>
                            <title>{messages.maintenance}</title>
                          </Helmet>
                          <IframeContent />
                        </>
                      </Route>
                      <Route exact path="/:client/administrator/activesubs">
                        <Support defaults={defaults} onlyActiveSubs={true} />
                      </Route>
                    </>
                  )}
                </>
              )}
            </Switch>
          </Suspense>
          {client === 'ineos' && <Footer showModal={(e) => showModal(e)} />}
        </div>
      )}
      {client != 'ineos' && (
        <Footer showModal={(e, str) => showModal(e, str)} />
      )}
    </>
  );
}

function About() {
  return <h2>About</h2>;
}
