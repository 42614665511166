import { useState } from 'react';
import { useLanguages } from 'utils/hooks';
import styled from 'styled-components';

const Circle = styled.div<any>`
  background-color: #ffffff;
  border-radius: 50%;
  height: 15px;
  transform: ${(props) =>
    props.$isEnabled ? 'translateX(120%)' : 'translateX(0)'};
  transition: transform 0.5s;
  width: 15px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
`;

const Frame = styled.div<any>`
  align-items: center;
  background-color: ${(props) =>
    props.$isEnabled ? '#c00000' : 'rgb(209 213 219)'};
  border-radius: 18px;
  display: flex;
  height: 18px;
  transition: background-color 0.5s;
  width: 34px;
`;

const Label = styled.h2`
  margin-right: 0.4em;
`;

function SwitchButton({ onClick }: { onClick: Function }) {
  const [enabled, setEnabled] = useState<boolean>(false);
  const { messages } = useLanguages();

  return (
    <Container>
      <Label>{messages.okta}</Label>
      <Frame
        $isEnabled={enabled}
        onClick={() => {
          setEnabled(!enabled);
          onClick();
        }}
      >
        <Circle $isEnabled={enabled} />
      </Frame>
    </Container>
  );
}

export default SwitchButton;
