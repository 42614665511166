import { atom } from 'recoil';

import { AASRAProfile, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: AASRAProfile[];
  ids?: AASRAProfile[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const AASRAProfilesState = atom({
  key: 'AASRAProfiles',
  default: initialState,
});

export function useAASRAProfiles() {
  const xport = useGeneric(
    AASRAProfilesState,
    'tb_aasraprofile',
    'aasraprofile',
    (data: AASRAProfile, idData: AASRAProfile) => {
      delete data?.aasraprofileorder;
    },
    undefined,
    true
  );

  return xport;
}
