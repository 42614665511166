import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { RowClickedEvent } from 'ag-grid-community';
import { debounce } from 'lodash';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import isIE from 'utils/isIE';
import { useLanguages } from 'utils/hooks';
import TextInput2 from 'components/TextInput2';

type Props = {
  columnDefs: any[];
  data: any[];
  selectRecord?: (event: RowClickedEvent) => void;
  gridOptions?: any;
  defaultColDef?: any;
  filterFields?: string[];
  skipHeader?: boolean;
  ref?: any;
};

function AgGrid({
  columnDefs,
  data,
  selectRecord,
  gridOptions,
  defaultColDef,
  filterFields,
  skipHeader = true,
  ref,
}: Props): React.ReactElement {
  const { messages } = useLanguages();
  const gridRef: any = useRef();
  const [forceUpdate, setForceUpdate] = useState<Boolean>(false);
  const [filter, setFilter] = useState<string | undefined>();
  const [filtered, setFiltered] = useState<any[] | undefined>();

  useEffect(() => {
    if (isIE()) {
      setTimeout(() => setForceUpdate(true), 500);
    }
  }, []);

  const autoSizeAll = useCallback((skipheader) => {
    const allColumnIds: any[] = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipheader);
  }, []);

  const defaultColDefs = {
    cellStyle: { textAlign: 'center' },
    resizable: true,
    sortable: true,
  };

  const IE = isIE();

  let styles: any = { width: '100%' };
  if (!IE) {
    styles = { ...styles, flexGrow: 1 };
  } else {
    styles = { ...styles, flex: '1 1 auto' };
  }

  const dbFilter = useMemo(
    () =>
      debounce((e: string | undefined) => {
        if (e) {
          setFiltered(
            data?.filter((rec) => {
              return filterFields?.some((x) =>
                e
                  .split(' ')
                  .every((y) =>
                    rec[x]?.toLowerCase().includes(y?.toLowerCase())
                  )
              );
            })
          );
        } else {
          setFiltered(undefined);
        }
      }, 750),
    [data, filterFields]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {filterFields && filterFields?.length > 0 && (
        <div className="mr-0 xl:mr-6 mb-8 search__input-box">
          <TextInput2
            style={{ height: '42px', maxWidth: 450 }}
            value={filter}
            label={messages.search}
            example={messages.search}
            onChange={(e) => {
              setFilter(e);
              dbFilter(e);
            }}
          />
        </div>
      )}
      <div className="ag-theme-alpine" style={styles}>
        {forceUpdate && <span>--</span>}
        <AgGridReact
          ref={gridRef}
          onFirstDataRendered={IE ? undefined : () => autoSizeAll(skipHeader)}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef || defaultColDefs}
          // gridOptions={gridOptions}
          rowData={filtered || data || []}
          onRowClicked={selectRecord}
          overlayNoRowsTemplate={messages.noRows}
          pagination
          paginationPageSize={25}
        />
      </div>
    </div>
  );
}

export default AgGrid;
