import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { format } from 'date-fns';
import download from 'downloadjs';

import * as S from './ResultsTable.styled';
import {
  useAnalytics,
  useClient,
  useConfig,
  useLanguages,
  useLoggedIn,
  useNavigator,
} from 'utils/hooks';
import { Result } from '../hooks';
import { useSearch } from 'features/search/hooks';
import { useAdminNav } from 'utils/hooks';
import { useSupabase, supabase as getSupabase } from 'utils/supabase';
import { blobToBase64 } from 'utils/functions';
import { FSLoader } from 'features/page';
import DataTable from 'components/DataTable';
import Modal from 'components/Modal';
import PDFViewer from 'features/page/components/PDFViewer';
import config from 'config';
import { useOEM } from 'features/OEM/hooks';
import { OEM } from 'types';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import AgGrid from 'components/AgGrid';
import { useOEMUserList } from 'features/page/hooks';
import { urlApi } from 'utils/apis';
import AsyncButton from 'components/AsyncButton';

function ResultsTable({
  assetOnboard,
  type,
  results,
  children,
  fullList,
  searchData,
  buildResults,
}: {
  assetOnboard?: boolean;
  type?: string;
  results?: Result[];
  children: any;
  fullList: any;
  searchData?: any;
  buildResults?: Function;
}) {
  const { hasAdmin } = useOEMUserList();
  const { assetCategoryName: catName }: any = useParams();
  const { downloadFile, removeFile, status } = useSupabase();
  const { messages, languageId } = useLanguages();
  const [data, setData] = useState<any[]>();
  // const { fullList, data: searchData, buildResults } = useSearch(catName);
  const { defaults } = useConfig();
  const history = useAdminNav();
  const { isIE } = useNavigator();
  const [showPdf, setShowPdf] = useState<string | null>(null);
  const [noWatermark, setNoWatermark] = useState<boolean>(false);
  const { client } = useClient();
  const { user } = useLoggedIn(true, true);
  const { data: oem }: { data: OEM[] | undefined } = useOEM();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { asset: aAsset } = useAnalytics();
  const [showDSHonda, setshowDSHonda] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const host = window.document.URL.includes('localhost')
    ? 'http://localhost:3010'
    : !window.document.URL.toLowerCase().includes('dev.right-2-repair.com')
    ? 'https://api.right-2-repair.com.au'
    : 'https://api.right-2-repair.com.au:8080';
  const downloadUrl = `${host}/SubaruVM/downloadShellScript`;

  useEffect(() => {
    if (data || !fullList) return;
    const d = fullList;
    setData(d as any);
    console.log({ d });
  }, [fullList, searchData, languageId, data, buildResults, messages]);

  function ActionRenderer(params: any) {
    if (!params?.data?.hasAccess) {
      return <span>{messages.noAccess}</span>;
    }

    const auth: any = sessionStorage.getItem('auth');
    const store = JSON.parse(auth || '{}');

    return (
      <span className="my-renderer">
        <S.Button
          onClick={async (e) => {
            e.stopPropagation();
            console.log({ params });
            try {
              setIsProcessing(true);
              const r = await fetch(
                `${urlApi}/echoPDF?email=${encodeURIComponent(
                  user.email
                )}&oemid=${defaults.oemid}&client=${client}&oemname=${
                  oem?.[0]?.oemdescription
                }&assetid=${encodeURIComponent(params?.data?.assetid)}&host=${
                  config.host
                }&token=${encodeURIComponent(
                  store?.token
                )}&domain=${`${window.location.hostname}`}`
              );

              const obj = await r.json();
              const {
                type,
                file,
                error,
                singleUseToken,
                files,
                message,
                newPassword,
              } = obj;

              console.log(r, obj);
              if (error) {
                toast(error, { type: 'error' });
                setIsProcessing(false);
                return;
              }

              aAsset(params?.data?.assetid);

              if (type === 'pdfloc') {
                setNoWatermark(false);
                setShowPdf(file);
              } else if (type === 'pdfupload') {
                const data = await downloadFile(file, defaults.oemid);
                const str = await blobToBase64(data?.data);
                setNoWatermark(false);
                setShowPdf(str);
              } else if (type === 'pdf') {
                const data = await downloadFile(file, defaults.oemid);
                await removeFile(file, defaults.oemid);
                const str = await blobToBase64(data?.data);
                setNoWatermark(true);
                setShowPdf(str);
              } else if (type === 'website') {
                // window.open(
                //   `${
                //     config.host
                //   }.netlify/functions/assetRedirect?email=${encodeURIComponent(
                //     `${user.email}|${store?.token}`
                //   )}&assetid=${params?.data?.assetid}&client=${client}&oemid=${
                //     defaults.oemid
                //   }&host=${config.host}`,
                //   '_blank'
                // );
                window.open(
                  `${urlApi}/assetRedirect?email=${encodeURIComponent(
                    `${user.email}|${store?.token}`
                  )}&assetid=${params?.data?.assetid}&client=${client}&oemid=${
                    defaults.oemid
                  }&host=${config.host}&singleUseToken=${singleUseToken}`,
                  '_blank'
                );
              } else if (type === 'downloadFile') {
                const isDS = params?.data?.category?.includes(
                  'Diagnostic Software'
                );
                const client = window.location.pathname.split('/')[1];
                if (isDS && client === 'honda') {
                  setIsProcessing(false);
                  setshowDSHonda(file);
                  return;
                }
                window.open(file);
              } else if (type === 'custom') {
                setIsProcessing(false);
                if (message) {
                  alert(message);
                } else {
                  files.forEach((file) => {
                    window.open(file);
                  });
                  setPassword(newPassword);
                }
              } else {
                const cli = getSupabase();
                const resp = await cli.storage
                  .from(defaults?.oemid?.toString())
                  .createSignedUrl(file, 1000 * 60);
                if (resp?.data?.signedURL) {
                  window.open(resp?.data?.signedURL, 'bulletin');
                } else {
                  toast('File not found', { type: 'error' });
                }
                // const data = await downloadFile(file, defaults.oemid);
                // download(
                //   data?.data,
                //   file.split('/')[file.split('/').length - 1],
                //   data?.data?.type
                // );
              }
            } catch (e: any) {
              console.log(e);
              // toast(e.message, { type: 'error' });
              toast(
                `Access Denied - Code 430 - Contact Support - ${client}-support@right-2-repair.com.au`,
                { type: 'error' }
              );
            }
            setIsProcessing(false);
          }}
        >
          <span className="fas fa-eye" />
        </S.Button>
      </span>
    );
  }

  function DateRenderer(params: any) {
    return (
      <span className="my-renderer">
        {format(new Date(params.value || ''), 'yyyy.MM.dd')}
      </span>
    );
  }

  function AssetDescriptionRenderer(params: any) {
    return (
      <div className="flex flex-col items-start justify-center">
        <S.TableRowType>{params.data.category}</S.TableRowType>
        <span style={{ textAlign: 'left' }}>{params.data.description}</span>
      </div>
    );
  }

  function IsActiveRenderer(params: any) {
    return (
      <div className="flex flex-col items-start justify-center">
        <span style={{ textAlign: 'left' }}>
          {params.data.isactive ? 'Yes' : 'No'}
        </span>
      </div>
    );
  }

  const selectAsset = (e: any) => {
    const path = e.data
      ? `/assets/edit/${e.data.assetid}/${languageId}`
      : `/assets/edit/${e.assetid}/${languageId}`;
    history.push(path);
  };

  const defaultColDef = {
    autoHeight: true,
    cellStyle: { lineHeight: 1.6, textAlign: 'center', wordBreak: 'normal' },
    minWidth: 120,
    resizable: true,
    sortable: true,
    wrapText: true,
  };

  const columnDefs: any = [
    {
      cellClass: 'ag-custom-cell',
      field: 'assetid',
      headerName: messages.assetId,
      minWidth: 135,
      maxWidth: 300,
      sort: 'desc',
    },
    {
      cellClass: 'ag-custom-cell',
      cellRendererFramework: ActionRenderer,
      field: 'assetid',
      headerName: messages.action,
      noBorder: '',
      textAlign: 'center',
      width: 120,
    },
    {
      autoHeight: true,
      cellClass: 'ag-lineheight',
      cellRendererFramework: AssetDescriptionRenderer,
      flex: 1,
      field: 'description',
      headerName: messages.asset,
      minWidth: 300,
      // maxWidth: 500,
      type: 'rightAligned',
    },
    {
      cellClass: 'ag-custom-cell',
      field: 'modelFamilyDescription',
      headerName: messages.modelFamily,
      maxWidth: 300,
    },
    {
      cellClass: 'ag-custom-cell',
      field: 'modelSeriesDescription',
      headerName: messages.modelSeries,
      maxWidth: 300,
    },
    {
      cellClass: 'ag-custom-cell',
      field: 'modelCode',
      headerName: messages.modelCode,
      maxWidth: 300,
    },
    {
      cellClass: 'ag-custom-cell',
      field: 'year',
      headerName: messages.modelYear,
      maxWidth: 300,
      minWidth: 200,
    },
    // {
    //   field: 'date',
    //   headerName: messages.date,
    //   cellRendererFramework: DateRenderer,
    //   cellClass: 'ag-custom-cell',
    // },
    {
      cellClass: 'ag-custom-cell',
      field: 'assetType',
      headerName: messages.assetType,
      maxWidth: 300,
    },
  ];

  if (hasAdmin) {
    columnDefs.push({
      cellClass: 'ag-custom-cell',
      cellRendererFramework: IsActiveRenderer,
      field: 'isactive',
      headerName: messages.isactive,
      maxWidth: 100,
    });
  }
  // console.log({ results });
  return (
    <S.Container>
      {password !== undefined && (
        <Modal
          style={{ width: 'auto', height: 'auto', overflow: 'scroll' }}
          close={() => setPassword(undefined)}
        >
          <h2 style={{ fontSize: '2rem', marginTop: '1rem' }}>
            Diagnostic Software
          </h2>
          <br />
          <p style={{ fontSize: '1.3rem' }}>
            <b>IMPORTANT INSTRUCTIONS</b>: If you are running the software for
            first time from R2R, please download this software from{' '}
            <a href={downloadUrl} style={{ color: 'blue' }}>
              here
            </a>
            , install it and reboot the PC, make sure you keep the password
            shown below else you won't be able to connect after reboot.
            <br />
            <br />
            Another File should have dowloaded automatically, which is a RDP
            file, double click on it, and enter the password to connect. Then
            plug in the hardware and wait for diagnostic software to load.
            <br />
            <br />
            <b style={{ color: 'red' }}>
              Windows will auto shut down after 2 hours, please complete the
              operations within same time or if you require more time then shut
              down the current VM machine, go back to R2R and relaunch another
              session with new 2 hour limit.
            </b>
            <br />
            <br />
            <b>WARNING</b>: copy this password to access the Virtual Desktop,
            without it you will be unable to proceed.
            <br />
            <br />
            Remote Desktop Password: <b>{password}</b>
            <br />
            <br />
            <b>Please shut down the VM after use.</b>
            <br />
            <br />
            <b>Requirements</b>: Windows 10/11 professional.
          </p>
        </Modal>
      )}
      {showPdf !== null && (
        <Modal
          style={{ width: '90vw', height: '90vh', overflow: 'scroll' }}
          close={() => setShowPdf(null)}
        >
          <PDFViewer path={showPdf} noWatermark={noWatermark} />
        </Modal>
      )}
      {showDSHonda && (
        <Modal style={{}} close={() => setshowDSHonda(undefined)}>
          <br />
          <br />
          <p>
            Before downloading the software, ensure your computer’s Region is
            set to Australia and that the time is set to the correct local time
            so as not to affect your subscription.
          </p>
          <AsyncButton
            type="button"
            messageId="download"
            onClick={() => {
              window.open(showDSHonda);
              setshowDSHonda(undefined);
            }}
          />
        </Modal>
      )}
      {isProcessing && <FSLoader which="Results" />}
      {children}
      {isIE ? (
        <DataTable
          data={results || []}
          config={{ cols: columnDefs }}
          onRowClicked={assetOnboard ? selectAsset : undefined}
        />
      ) : (
        <AgGrid
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          data={results || []}
          selectRecord={assetOnboard ? selectAsset : undefined}
        />
      )}
    </S.Container>
  );
}

export default memo(ResultsTable);
