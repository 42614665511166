import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: grey;
  opacity: 0.5;
  z-index: 998;
`;

export const ModalContainer = styled.div<any>`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2em 2em;
  border-radius: 6px;
  max-height: 90vh;
  max-width: 90vw;
  overflow: ${(props) => (props.$overflow ? props.$overflow : 'hidden')};
  height: ${(props) => (props.$height ? props.$height : 'auto')};
`;

export const Close = styled.button<any>`
  border: none;
  background: transparent;
  float: right;
  position: relative;
  z-index: 999999;
  top: ${(props) => (props.$yPosition ? props.$yPosition : '20px')};
  right: ${(props) => (props.$xPosition ? props.$xPosition : '20px')};
`;
