const config: {
  host?: string;
  url?: string;
  key?: string;
} = {
  host: process.env.REACT_APP_HOST,
  url: process.env.REACT_APP_SB,
  key: process.env.REACT_APP_SB_KEY,
};

console.log('CONFIG ::::', config, window.location.host);

export default config;
