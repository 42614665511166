import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { LanguageContext } from 'components/Language/Context';
import { useLoggedIn, useConfig } from 'utils/hooks';
import { useOEMUserList } from 'features/page/hooks';

function Generic({
  noHamburger,
  showAssetMenu,
  showAssetMenuState,
  showMenuFn,
}: {
  noHamburger?: boolean;
  showAssetMenu?: any;
  showAssetMenuState?: any;
  showMenuFn?: Function;
}) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { user: authUser } = useLoggedIn(true, true);
  const { messages } = useContext(LanguageContext);
  const { defaults } = useConfig();
  const {
    hasAdmin,
    hasAssets,
    hasAccessProfiles,
    hasUrlAuth,
    hasOemAdmin,
    hasGapAdmin,
    hasUsersData,
    hasActiveSubs,
    hasSupport,
    hasClientAdmin,
  } = useOEMUserList();
  const history = useHistory();

  const loc = window.location.pathname.split('/')[2];
  const sub = window.location.pathname.split('/')[3];
  const client = window.location.pathname.split('/')[1];

  useEffect(() => {
    if (!defaults) return;
    const fav: any = document.getElementById('favicon');
    // console.log('FAVICON', defaults);
    if (defaults?.faviconurl && client) fav.href = defaults?.faviconurl;
    else fav.href = '/public/favicon.ico';
  }, [defaults, client]);

  // keep the Generic (top menu) synchrozined with the AssetMenu state.
  useEffect(() => {
    setShowMenu(showAssetMenuState);
  }, [showAssetMenuState]);

  if (!defaults) return null;

  const toggleMenu = () => setShowMenu(!showMenu);

  const navigateAndToggleMenu = async (path?: string) => {
    await history.push(`/${client}/${path}`);
    showMenuFn?.();
    if (!showMenu) {
      toggleMenu();
      showAssetMenu();
    }
  };

  // console.log('MAIN MENU ::::', hasGapAdmin);
  const userName = !!authUser?.firstname
    ? `${authUser?.firstname} ${authUser?.surname}`
    : authUser?.email?.split('@')[0];

  return (
    <div className="flex w-full items-center pr-3">
      <div className="flex">
        <span
          className={`text-center text-xxs font-normal font-sans mr-10 ${
            loc === undefined ? 'nav-active' : 'nav-inactive'
          }`}
        >
          <Link onClick={() => navigateAndToggleMenu()} to={`/${client}`}>
            {messages.home}
          </Link>
        </span>
        {hasAssets && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'assets' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('assets')}
              to={`/${client}/assets`}
              className="top-menu"
            >
              {messages.findAssets}
            </Link>
          </span>
        )}
        {((hasAdmin && client !== 'r2r') ||
          (hasOemAdmin && client !== 'r2r') ||
          hasGapAdmin) && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'admin' && sub !== 'access'
                ? 'nav-active'
                : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('admin')}
              to={`/${client}/admin`}
              className="top-menu"
            >
              {messages.admin}
            </Link>
          </span>
        )}
        {hasAccessProfiles && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'access' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('access')}
              to={`/${client}/access`}
              className="top-menu"
            >
              {messages.accessProfiles}
            </Link>
          </span>
        )}
        {hasUrlAuth && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'urlAuthenticator' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link to={`/${client}/urlAuthenticator`}>
              {messages.AssetExplorer}
            </Link>
          </span>
        )}
        {hasClientAdmin && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'administrator' ||
              sub === 'activesubs' ||
              sub === 'clientoemadmin' ||
              sub === 'dashboard'
                ? 'nav-active'
                : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('administrator')}
              to={`/${client}/administrator`}
              className="top-menu"
            >
              {messages.clientAdmin}
            </Link>
          </span>
        )}
        {/* {hasUsersData && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              sub === 'usersdata' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link to={`/${client}/support/usersdata`} className="top-menu">
              {messages.hasUsersData}
            </Link>
          </span>
        )}
        {hasActiveSubs && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              sub === 'activesubs' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link to={`/${client}/support/activesubs`} className="top-menu">
              {messages.activeSubsShort}
            </Link>
          </span>
        )} */}
        {hasSupport && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'support' && !sub ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('support')}
              to={`/${client}/support`}
              className="top-menu"
            >
              {messages.support}
            </Link>
          </span>
        )}
        <span className="text-center text-xxs font-normal font-sans">
          {messages.hello}, {userName}
        </span>
      </div>
      {!noHamburger && (
        // <button className="p-3 m4" onClick={() => toggleMenu()}>
        <span />
        // </button>
      )}
    </div>
  );
}

export default Generic;
