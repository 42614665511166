import { atom } from 'recoil';

import { ModelSeries, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: ModelSeries[];
  ids?: ModelSeries[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const modelSeriesState = atom({
  key: 'modelseries',
  default: initialState,
});

export function useModelSeries() {
  const xport = useGeneric(
    modelSeriesState,
    'tb_modelseries',
    'modelseries',
    (data: ModelSeries, idData: ModelSeries) => {
      idData.modelfamilyid = data.modelfamilyid;
      delete data?.brandid;
      // delete data?.oemid;
      delete data?.divisionid;
    },
    (data: ModelSeries[], iData: ModelSeries[]) => {
      return data.map((d) => {
        const i = iData?.find(
          ({ modelseriesid }) => modelseriesid === d.modelseriesid
        );
        return {
          ...d,
          oemid: parseInt(i?.oemid?.toString() || '0'),
          divisionid: i?.divisionid,
          brandid: i?.brandid,
          modelfamilyid: i?.modelfamilyid,
        };
      });
    }
  );

  return xport;
}
