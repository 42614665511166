import styled from 'styled-components';
import asraLogo from 'assets/asra-logo.png';
import { useLanguages } from 'utils/hooks';
import { useConfig } from 'utils/hooks';

const oemFullName = {
  mmal: 'Mitsubishi Motors Australia LTD',
  subaru: 'Subaru (Aust) Pty Ltd',
  honda: 'Honda Australia Pyt Ltd',
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3em 0.7em 1em 0;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1px 8px 0 0;
  gap: 10px;
  text-align: right;
`;

const OEMNAme = styled.h3`
  font-family: 'mmcregular', sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
`;

const AssetCategory = styled.h2<{ $color?: string; $fontSize?: string }>`
  color: ${(props) => (props.$color ? props.$color : '#000000')};
  font-family: 'mmcregular', sans-serif;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '1rem')};
  font-weight: bold;
`;

const RedLine = styled.div`
  border: 2px solid #c00000;
  margin-bottom: 1em;
  width: 100%;
`;

const SubTitle = styled.h3`
  color: gray;
  font-family: 'mmcregular', sans-serif;
  font-size: 0.8rem;
  width: 400px;
`;

function ModalHeader({ client, type }: { client: string; type: string }) {
  const { messages } = useLanguages();
  const { defaults } = useConfig();

  if (!defaults) return null;

  const renderLogoImage = () => {
    if (
      type === 'Special Tools' ||
      type === 'MUT Software Info' ||
      type === 'CLIP Software Info'
    ) {
      return (
        <img
          className="w-auto"
          style={{ height: '126px' }}
          src={defaults?.popuplogourl}
          alt="oem logo image"
        />
      );
    } else {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '60%',
          }}
        >
          <img
            className="w-auto"
            style={{ height: '126px' }}
            src={defaults?.popuplogourl}
            alt="oem logo image"
          />
          <img
            className="w-auto h-16 ml-2 mt-3"
            src={asraLogo}
            style={{ height: '140px' }}
            alt="AASRA logo image"
          />
        </div>
      );
    }
  };

  const renderAssetCategory = () => {
    if (type === 'Special Tools') {
      return (
        <AssetCategory $color="#c00000">{messages.specialTools}</AssetCategory>
      );
    } else if (type === 'MUT Software Info') {
      return (
        <AssetCategory $color="#c00000">{messages.mutModal}</AssetCategory>
      );
    } else if (type === 'CLIP Software Info') {
      return (
        <AssetCategory $color="#c00000">{messages.clipModal}</AssetCategory>
      );
    } else if (type === 'Security') {
      return (
        <AssetCategory $color="#c00000">
          {messages.securityModalP1}
        </AssetCategory>
      );
    } else {
      return (
        <AssetCategory $color="#c00000">{messages.safetyModalP1}</AssetCategory>
      );
    }
  };

  const renderSubTitle = () => {
    if (type === 'CLIP Software Info') {
      return <SubTitle>{messages.clipModalSubTitle}</SubTitle>;
    } else if (type === 'MUT Software Info') {
      return <SubTitle>{messages.mutModalSubTitle}</SubTitle>;
    }
  };

  return (
    <>
      <Header>
        {renderLogoImage()}
        <RightSection>
          <OEMNAme>{oemFullName[client]}</OEMNAme>
          {renderAssetCategory()}
          {renderSubTitle()}
        </RightSection>
      </Header>
      {client === 'mmal' && <RedLine />}
    </>
  );
}

export default ModalHeader;
