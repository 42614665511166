import { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { uniqBy } from 'lodash';

import { supabase as getSupabase } from 'utils/supabase';
import { useToast, useLanguages, useConfig, useLoggedIn } from 'utils/hooks';
import { useModelFamily } from 'features/modelFamily/hooks';
import {
  AASRAProfile,
  AssetCategory,
  AssetType,
  Model,
  ModelFamily,
  ModelSeries,
  ModelYear,
} from 'types';
import { useModelSeries } from 'features/modelSeries/hooks';
import { useModel } from 'features/model/hooks';
import { useModelYear } from 'features/modelYear/hooks';
import { useAssetTypes } from 'features/assetsType/hooks';
import {
  AssetsModel,
  AssetsModelFamily,
  AssetsModelSeries,
  AssetsModelYear,
  useAssetCategories,
  useAssets,
  useAssetsCats,
  useAssetsDescription,
  useAssetsModelFamily,
  useAssetsModelSeries,
  useAssetsModel,
  useAssetsModelYear,
  // useAssetsLocation,
  useAssetsOEM,
  useAssetsType,
  useAssetsBrand,
  useAssetsDivision,
  useAssetsRepairGroup,
  useAssetsRepairSubGroup,
  useAssetsRepairComponent,
  useAssetsAASRAProfile,
} from 'features/assets/hooks';
import { useAssetsCats as useAccessCats } from 'features/assets/accessHooks';
import { useParams } from 'react-router';
import { useOEMUserList } from 'features/page/hooks';
import { useAASRAProfiles } from 'features/aasraProfile/hooks';

type State = any;

const initialState: SearchData | undefined = undefined;

const AssetSearchState = atom({
  key: 'AssetSearch',
  default: initialState,
});

export type SearchData = {
  asset: any;
  description: any;
  // location: any;
  model: Array<{ assetid: number; modelid: number }>;
  family: Array<{ assetid: number; modelfamilyid: number }>;
  series: Array<{ assetid: number; modelseriesid: number }>;
  year: Array<{ assetid: number; modelyearid: number }>;
  cats: Array<{ assetid: number; assetcategoryid: number }>;
  oems: Array<{ assetid: number; oemid: number }>;
  ats: any;
  acats: any;
  aps: any;
};

export type Result = {
  assetid: number;
  isactive: boolean;
  files: string[];
  description: string;
  date: string;
  year: number;
  modelFamilyDescription: string;
  modelSeriesDescription: string;
  modelDescription: string;
  modelCode: string;
  category: string;
  assetType: string;
  isFile: boolean;
  hasAccess: boolean;
};

export type VinResult = {
  modelfamilyid: number;
  modelseriesid: number;
  modelid: number;
  modelyearid: number;
};

function useSearch(catName?: string) {
  const supabase = getSupabase();
  const { hasViewAllAssets, hasAdmin, access, aasra } = useOEMUserList();
  const { aasraRoles, user } = useLoggedIn(true, true);
  const [state, setState] = useRecoilState<any>(AssetSearchState);
  const [allIds, setAllIds] = useState<number[]>();
  const [fullList, setFullList] = useState<Result[]>();
  const [status, setStatus] = useState<'loading' | 'done'>('loading');
  const { languageId, messages } = useLanguages();
  const [data, setData] = useState<SearchData>();
  const { data: categories } = useAssetCategories();
  const { data: modelFamilies }: { data: ModelFamily[] | undefined } =
    useModelFamily();
  const { data: modelSeries }: { data: ModelSeries[] | undefined } =
    useModelSeries();
  const { data: models }: { data: Model[] | undefined } = useModel();
  const { data: modelYears }: { data: ModelYear[] | undefined } =
    useModelYear();
  const { data: assetCats }: { data: AssetCategory[] | undefined } =
    useAssetCategories();
  const { data: assetTypes }: { data: AssetType[] | undefined } =
    useAssetTypes();
  const { data: aasraProfiles }: { data: AASRAProfile[] } = useAASRAProfiles();

  // console.time('use result');
  const { data: assets } = useAssets();
  const { data: cats } = useAssetsCats();
  const { data: descr } = useAssetsDescription();
  const { data: mf } = useAssetsModelFamily();
  const { data: ms } = useAssetsModelSeries();
  const { data: m } = useAssetsModel();
  const { data: my } = useAssetsModelYear();
  // const { data: an } = useAssetsLocation();
  const { data: oem } = useAssetsOEM();
  const { data: at } = useAssetsType();
  const { data: acats } = useAccessCats();
  // const { data: div } = useAssetsDivision();
  // const { data: bra } = useAssetsBrand();
  // const { data: rgs } = useAssetsRepairGroup();
  // const { data: rsgs } = useAssetsRepairSubGroup();
  // const { data: rcs } = useAssetsRepairComponent();
  const { data: aps } = useAssetsAASRAProfile();
  // console.timeEnd('use result');

  const [currentCat, setCurrentCat] = useState<string>();
  const { defaults } = useConfig();
  const { toast } = useToast();
  const { client }: any = useParams();
  const [vinResults, setVinResults] = useState<VinResult | undefined>();

  const resetVinSearch = () => {
    setVinResults({} as any);
  };

  useEffect(() => {
    if (client === state?.client && data) return;
    // console.log(
    //   !defaults,
    //   !client,
    //   !assets,
    //   !descr,
    //   !assetTypes,
    //   !at,
    //   // !an,
    //   !m,
    //   !mf,
    //   !ms,
    //   !my,
    //   !cats,
    //   !oem,
    //   !acats,
    //   !aps
    // );
    if (
      !defaults ||
      !client ||
      !assets ||
      !descr ||
      !assetTypes ||
      !at ||
      // !an ||
      !m ||
      !mf ||
      !ms ||
      !my ||
      !cats ||
      !oem ||
      !acats ||
      !aps
    )
      return;
    const f = async () => {
      console.log('ASSETS SEARCH DATA FETCH ******');
      try {
        const d = {
          asset: assets,
          description: descr,
          // location: an,
          model: m,
          family: mf,
          series: ms,
          year: my,
          cats: cats,
          oems: oem,
          ats: at,
          acats,
          aps,
        };
        setData(d);
        setStatus('done');
        setState({ client });
      } catch (error: any) {
        toast('error', 'error', { message: error.message });
      }
    };
    f();
    /* eslint-disable-next-line */
  }, [
    defaults,
    client,
    assets,
    descr,
    assetTypes,
    // an,
    m,
    mf,
    ms,
    my,
    cats,
    oem,
    at,
    acats,
    aps,
  ]);

  const buildResults = useCallback(
    (ids: number[]): Result[] => {
      const results: Result[] = [];
      console.log('BUILD RESULTS ::::', data, assetCats);
      console.time('build result');
      ids?.forEach((id) => {
        const catId: number | undefined = data?.cats?.find(
          ({ assetid }) => assetid?.toString() === id?.toString()
        )?.assetcategoryid;
        const cat: AssetCategory | undefined = assetCats?.find(
          ({ assetcategoryid }) => assetcategoryid === catId
        );
        const mfs: AssetsModelFamily[] =
          data?.family.filter(({ assetid }) => assetid === id) || [];
        const modelFamilyDescription: ModelFamily[] =
          modelFamilies?.filter(({ modelfamilyid: xid }) =>
            mfs.find(({ modelfamilyid: x }) => x === xid)
          ) || [];
        const mss: AssetsModelSeries[] =
          data?.series.filter(({ assetid }) => assetid === id) || [];
        const modelSeriesDescription = modelSeries?.filter(
          ({ modelseriesid: xid }) =>
            mss.find(({ modelseriesid: x }) => x === xid)
        );
        const ms: AssetsModel[] =
          data?.model?.filter(({ assetid }) => assetid === id) || [];
        const modelCode: Model[] =
          models?.filter(({ modelid: xid }) =>
            ms.find(({ modelid: x }) => x === xid)
          ) || [];
        const asset = data?.asset.find(({ assetid }) => assetid === id);
        const at = data?.ats.find(({ assetid }) => assetid === id);
        const atd = assetTypes?.find(
          ({ assettypeid }) => assettypeid === at?.assettypeid
        );
        // const loc = data?.location.find(({ assetid }) => assetid === id);
        const desc = data?.description.find(({ assetid }) => assetid === id);
        const mys: AssetsModelYear[] =
          data?.year.filter(({ assetid }) => assetid === id) || [];
        const modelYear: ModelYear[] =
          modelYears?.filter(({ modelyearid: xid }) =>
            mys.find(({ modelyearid: x }) => x === xid)
          ) || [];
        // const file = loc
        //   ? `${loc?.assettrunk || ''}${loc?.assetbranch || ''}${
        //       loc?.assetleaf || ''
        //     }${loc?.assetname}`
        //   : messages.none;
        let hasAccess = false;
        const dc = data?.cats.find((c) => c.assetid === id)?.assetcategoryid;
        let hAasra = false;
        const standard = aasraProfiles?.find(
          (x) =>
            x?.oemid === defaults?.oemid &&
            x?.aasraprofiledescriptionshort === 'standard'
        );
        // if (id === 1665) console.log(id, data?.aps, aasraRoles);
        if (aasraRoles?.length) {
          hAasra = !!data?.aps?.find(
            (x: any) =>
              x.assetid === id &&
              aasraRoles?.find(
                (a: any) => x.aasraprofileid === a.aasraprofileid
              )
          );
          // if (id === 1665) console.log(1, id, hAasra);
        } else if (aasra?.length) {
          hAasra = !!data?.aps?.find(
            (x: any) =>
              x.assetid === id &&
              aasra?.find((a: any) => x.aasraprofileid === a.aasraprofileid)
          );
          // if (id === 1665) console.log(2, id, hAasra);
        } else {
          hAasra = !!data?.aps?.find(
            (x: any) =>
              x.assetid === id && x.aasraprofileid === standard?.aasraprofileid
          );
          // if (id === 1665) console.log(3, id, hAasra);
        }
        // const hAccess = true;
        const hAccess =
          ((aasraRoles?.length || aasra?.length) && !access?.length) ||
          !!data?.acats?.find(
            (x: any) =>
              x.assetcategoryid === dc &&
              access?.find(
                (a: any) =>
                  x.assetsaccessid === a.accessid ||
                  x.assetsaccessid === a.assetsaccessid
              )
          );
        // console.log('HERE ******', {
        //   id,
        //   dc,
        //   hAasra,
        //   hAccess,
        //   aps: data?.aps,
        //   acats: data?.acats,
        //   aasra,
        //   access,
        //   aasraRoles,
        //   found: data?.acats?.find(
        //     (x: any) =>
        //       x.assetcategoryid === dc &&
        //       access?.find(
        //         (a: any) =>
        //           x.assetsaccessid === a.accessid ||
        //           x.assetsaccessid === a.assetsaccessid
        //       )
        //   ),
        // });
        if (hAasra && hAccess) {
          hasAccess = true;
        }

        // console.log('TEST :::', id, hAccess, hAasra, access);

        const r = {
          assetid: id,
          hasAccess,
          description: desc?.assetdescription || messages.none,
          category: cat?.assetcategorydescription,
          isactive: asset?.isactive,
          year:
            modelYear
              ?.map(
                ({ modelyeardescriptionshort }) => modelyeardescriptionshort
              )
              .join(', ') || messages.none,
          date: asset?.created_at || messages.none,
          // files: [file],
          modelFamilyDescription:
            modelFamilyDescription
              ?.map(({ modelfamilydescription }) => modelfamilydescription)
              ?.join(', ') || messages.none,
          modelSeriesDescription:
            modelSeriesDescription
              ?.map(
                ({ modelseriesdescriptionshort }) => modelseriesdescriptionshort
              )
              ?.join(', ') || messages.none,
          modelCode:
            modelCode
              ?.map(({ modeldescriptionshort }) => modeldescriptionshort)
              .join(', ') || messages.none,
          assetType: atd?.assettypedescription || messages.none,
          isFile: atd?.file_upload,
        } as Result;
        // console.log(id, r);
        results.push(r);
      });
      console.log('buildResults', results);
      console.timeEnd('build result');
      return uniqBy(results, 'assetid');
    },
    [
      data,
      messages,
      modelFamilies,
      modelSeries,
      models,
      modelYears,
      assetCats,
      assetTypes,
      aasra,
      access,
      aasraRoles,
      aasraProfiles,
      defaults,
    ]
  );

  useEffect(() => {
    if (
      !categories ||
      !catName ||
      catName === currentCat ||
      !modelFamilies ||
      !modelSeries ||
      !models ||
      !data ||
      !assetCats
    )
      return;
    let allids: number[] = [];
    const cId = categories.find(
      ({ assetcategorydescription }) =>
        assetcategorydescription.toLowerCase().replaceAll(' ', '-') === catName
    );
    console.log('CATNAME', catName);
    if (
      catName &&
      cId &&
      !['vin-lookup', 'vin-search'].includes(catName.toLowerCase())
    )
      allids = data?.cats
        ?.filter(
          ({ assetcategoryid }) =>
            assetcategoryid.toString() === cId.assetcategoryid.toString()
        )
        .map(({ assetid }) => assetid);
    else allids = data?.asset?.map(({ assetid }) => assetid);

    allids = allids?.filter(
      (id) =>
        !!data?.asset?.find((a) => a.assetid === id)?.isactive ||
        (!catName && !!hasAdmin) ||
        !!hasViewAllAssets
    );

    allids =
      data?.oems
        ?.filter(
          ({ oemid, assetid }) =>
            allids.find((x) => x === assetid) &&
            oemid?.toString() === defaults?.oemid.toString()
        )
        ?.map(({ assetid }) => assetid) || [];

    setAllIds(allids);
    console.log('with cat', allids, defaults);
    setFullList(buildResults(allids));
    setCurrentCat(catName);
  }, [
    buildResults,
    data,
    catName,
    categories,
    currentCat,
    modelFamilies,
    modelSeries,
    models,
    defaults,
    assetCats,
    hasViewAllAssets,
    hasAdmin,
  ]);

  useEffect(() => {
    if (
      !modelFamilies ||
      !modelSeries ||
      !models ||
      !data ||
      !assetCats ||
      fullList
    )
      return;
    if (!catName) {
      console.log('without cat', catName, data);
      let allids = data?.asset?.map(({ assetid }) => assetid);
      allids =
        data?.oems
          ?.filter(
            ({ oemid, assetid }) =>
              allids.find((x) => x === assetid) &&
              oemid?.toString() === defaults?.oemid.toString()
          )
          ?.map(({ assetid }) => assetid) || [];
      console.log('allids ::::', allids);
      setAllIds(allids);
      setFullList(buildResults(allids));
    }
    /* eslint-disable-next-line */
  }, [
    buildResults,
    data,
    catName,
    currentCat,
    modelFamilies,
    modelSeries,
    models,
    defaults,
    assetCats,
    client,
  ]);

  const search = (q: State): Result[] => {
    let list: number[] = allIds || [];
    // console.log(' list 1 ::::', list, data?.asset, catName, hasViewAllAssets);
    if (q.search) {
      const x = data?.description
        .filter(
          ({ assetid, assetdescription, assetdescriptionshort }) =>
            list.find((id) => id === assetid) &&
            (assetdescription.toLowerCase().includes(q.search.toLowerCase()) ||
              assetdescriptionshort
                .toLowerCase()
                .includes(q.search.toLowerCase()))
        )
        .map(({ assetid }) => assetid);
      list = x;
    }
    if (q.modelfamilyid >= 0) {
      const x = data?.family
        .filter(
          ({ assetid, modelfamilyid }) =>
            list.find((id) => id === assetid) &&
            modelfamilyid === q.modelfamilyid
        )
        .map(({ assetid }) => assetid);
      if (x) list = x;
    }

    if (q.assetcategoryid >= 0) {
      const x = data?.cats
        .filter(
          ({ assetid, assetcategoryid }) =>
            list.find((id) => id === assetid) &&
            assetcategoryid === q.assetcategoryid
        )
        .map(({ assetid }) => assetid);
      if (x) list = x;
    }

    if (q.assettypeid >= 0) {
      const x = data?.ats
        .filter(
          ({ assetid, assettypeid }) =>
            list.find((id) => id === assetid) && assettypeid === q.assettypeid
        )
        .map(({ assetid }) => assetid);
      if (x) list = x;
    }

    if (q.modelseriesid >= 0) {
      const x = data?.series
        .filter(
          ({ assetid, modelseriesid }) =>
            list.find((id) => id === assetid) &&
            modelseriesid === q.modelseriesid
        )
        .map(({ assetid }) => assetid);
      if (x) list = x;
    }

    if (q.modelid >= 0) {
      const x = data?.model
        .filter(
          ({ assetid, modelid }) =>
            list.find((id) => id === assetid) && modelid === q.modelid
        )
        .map(({ assetid }) => assetid);
      if (x) list = x;
    }

    if (q.modelyearid >= 0) {
      const x = data?.year
        .filter(
          ({ assetid, modelyearid }) =>
            list.find((id) => id === assetid) && modelyearid === q.modelyearid
        )
        .map(({ assetid }) => assetid);
      if (x) list = x;
    }
    console.log('result list ::::', q, list);
    return buildResults(list);
  };

  const vinSearch = async (q: State) => {
    let list: any = allIds;
    const vinResult: any = await supabase
      .from('tb_vin_lookup')
      .select('*')
      .eq('VIN', q.vin?.toUpperCase())
      .eq('oemid', defaults?.oemid);
    const found: VinResult | undefined = vinResult?.data?.[0];
    console.log('vinSearch 0 ::::', q);
    console.log('vinSearch 1 ::::', found);
    if (!found) {
      setVinResults({} as VinResult);
      return [];
    }

    setVinResults(found);

    console.log('list', found, list);
    // console.log('data', data?.series);

    if (list?.length && found.modelfamilyid >= 0) {
      const x = data?.family
        .filter(
          ({ assetid, modelfamilyid }) =>
            list.find((id) => id === assetid) &&
            modelfamilyid === found.modelfamilyid
        )
        .map(({ assetid }) => assetid);
      list = x;
      console.log('len 0::::', x?.length);
    }

    if (list?.length && found.modelseriesid >= 0) {
      const x = data?.series
        .filter(
          ({ assetid, modelseriesid }) =>
            list.find((id) => id === assetid) &&
            modelseriesid === found.modelseriesid
        )
        .map(({ assetid }) => assetid);
      if (x?.length) list = x;
      console.log('len 1::::', x?.length);
    }

    // if (list?.length && found.modelid >= 0) {
    //   const x = data?.model
    //     .filter(
    //       ({ assetid, modelid }) =>
    //         list.find((id) => id === assetid) && modelid === found.modelid
    //     )
    //     .map(({ assetid }) => assetid);
    //   if (x?.length) list = x;
    //   console.log('len 2 ::::', x?.length);
    // }

    if (list?.length && found.modelyearid >= 0) {
      const short = modelYears?.find(
        (x) => x?.modelyearid === found.modelyearid
      )?.modelyeardescriptionshort;
      const all = modelYears?.filter((x) =>
        x?.modelyeardescriptionshort?.includes(short || '')
      );
      const x = data?.year
        .filter(
          ({ assetid, modelyearid }) =>
            list.find((id) => id === assetid) &&
            all?.find((y) => y.modelyearid === modelyearid)
        )
        .map(({ assetid }) => assetid);
      if (x?.length) list = x;
      console.log('len 3 ::::', x?.length);
    }

    if (q.assetcategoryid >= 0) {
      const x = data?.cats
        .filter(
          ({ assetid, assetcategoryid }) =>
            list.find((id) => id === assetid) &&
            assetcategoryid === q.assetcategoryid
        )
        .map(({ assetid }) => assetid);
      list = x;
    }

    console.log('vinSearch', found, list);

    return buildResults(list);
  };

  const resetQuery = () => {
    setVinResults({} as VinResult);
  };

  return {
    search,
    status,
    fullList,
    data,
    buildResults,
    vinResults,
    vinSearch,
    resetQuery,
    resetVinSearch,
  };
}

export { useSearch };
