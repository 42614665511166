import { useEffect, useState } from 'react';
import styled from 'styled-components';

import TextInput2 from 'components/TextInput2';
import { useLanguages } from 'utils/hooks';
import AddressForm from './AddressForm';
import Select from 'components/Select';

const ToggleButton = styled.button`
  padding: 1rem 0;
`;

function GAPForm({
  data,
  setData,
  validateAbnPostcode,
  availableGaps,
  gapid,
  selectGap,
  disabled,
}: {
  data: any;
  setData: Function;
  validateAbnPostcode: Function;
  availableGaps: any[];
  gapid?: number;
  selectGap: Function;
  disabled?: boolean;
}) {
  const { messages } = useLanguages();
  const [toggleAddress, setToggleAddress] = useState<boolean>(false);

  return (
    <div style={{ marginTop: '2em' }}>
      {!toggleAddress && (
        <>
          <TextInput2
            id1="abn"
            value={data?.abn}
            label={messages.abn}
            required
            onBlur={validateAbnPostcode}
            example=""
            type="number"
            onChange={(e) => {
              let abn = e?.replaceAll(' ', '');
              abn = abn.replace(/[^\d]/g, '');
              setData({ ...data, abn });
            }}
            disabled={!!gapid}
          />
          {availableGaps?.length > 0 && (
            <Select
              data={availableGaps}
              placeholder={'Existing GAPs'}
              idKey="gapid"
              valueKey="gapdescription"
              value={gapid}
              withNullOption
              onChange={(e) => selectGap(e.target.value)}
              containerStyle={{ width: '100%', marginLeft: '0' }}
            />
          )}
          <TextInput2
            id1="companyname"
            value={data?.gapcompanyname}
            label={messages.companyName}
            autoFocus
            required
            example="e.g. Ultratune"
            onChange={(e) => setData({ ...data, gapcompanyname: e })}
            disabled={!!gapid}
          />
        </>
      )}
      {/* {
        <>
          {toggleAddress && (
            <AddressForm
              data={data}
              setData={setData}
              // noFocus
              required
              // validateAbnPostcode={validateAbnPostcode}
              disabled={!!gapid}
            />
          )}
          {toggleAddress ? (
            <ToggleButton type="button" onClick={() => setToggleAddress(false)}>
              Business details
            </ToggleButton>
          ) : (
            <ToggleButton type="button" onClick={() => setToggleAddress(true)}>
              Address details (required)
            </ToggleButton>
          )}
        </>
      } */}
    </div>
  );
}

export default GAPForm;
