import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import AsyncButton from 'components/AsyncButton';
import LinkButton from 'components/LinkButton';
import InputGroup from '../LoginModal/components/InputGroup';

import { useAuth, useConfig, useLanguages, useLoggedIn } from 'utils/hooks';
import { aasraLogin, validateNewUser } from 'utils/apis';
import isIE from 'utils/isIE';
import { FSLoader } from 'features/page';
import { AASRAProfile } from 'types';
import * as S from './styled';
import { useAASRAProfiles } from 'features/aasraProfile/hooks';
import stepFiveImg from 'assets/login_step_5.jpeg';
import stepSixImg from 'assets/login_step_6.jpeg';

type State = {
  email: string;
  password: string;
  otp: string;
};

// isLogin is a temporary flag to prevent the step-by-step images to display on sign up
// process as the set of images are incomplete.
// function AasraModal({ close, client }: { close: Function; client: string }) {
function AasraModal({
  back,
  email,
  setRoles,
  noOkta,
  close,
  noClose,
  setEmail,
  isLogin = true,
}: {
  back?: any;
  email?: string;
  setRoles?: Function;
  noOkta?: boolean;
  close?: any;
  noClose?: boolean;
  setEmail?: Function;
  isLogin?: boolean;
}) {
  const [state, setState] = useState<State>({
    email: '',
    password: '',
    otp: '',
  });
  const { messages } = useLanguages();
  const { defaults } = useConfig();
  const { user, setAASRARole } = useLoggedIn(true, true);
  const { data: aasraRoles }: { data: AASRAProfile[] } = useAASRAProfiles();
  const history = useHistory();
  const location = useLocation();
  const slugs = window.location.pathname.split('/');
  const client = slugs[1];
  const [status, setStatus] = useState<
    'password' | 'otp' | 'fetching' | 'failed' | 'success' | 'done'
  >('password');
  useEffect(() => {
    console.log('This is from useeffect');
    console.log(state);
  }, [state]);

  const redirectSignUp = () => {
    const role = aasraRoles?.find(
      (x) =>
        x.oemid === defaults?.oemid &&
        x.aasraprofiledescriptionshort === 'standard'
    );
    console.log('ROLE', role);
    setAASRARole([role]);
  };

  const handleSubmit = async () => {
    if (!state.password) {
      // Show toast
      toast(messages.missingInput, { type: 'error' });
      return;
    }
    if (client === 'subaru' && location?.pathname?.includes('signup')) {
      const validate = await validateNewUser(state.email);
      if (validate?.data?.status === 'error') {
        toast(validate?.data?.message, { type: 'error' });
        return;
      }
    }
    // if ([state?.email, email, user?.email].includes('j.j.b1980@live.com')) {
    //   toast(messages?.aasraSuccess, { type: 'success' });
    //   setStatus('done');
    //   setRoles
    //     ? setRoles([{ aasraprofileid: 12, oemid: 2 }])
    //     : setAASRARole([{ aasraprofileid: 12, oemid: 2 }]);
    //   !noClose && close?.('success');
    //   return;
    // }
    setStatus('fetching');
    const response = await aasraLogin(
      'standard',
      defaults?.oemid,
      state?.email || email || user.email,
      state.password,
      undefined,
      noOkta
    );
    // 2fa logic TODO
    if (response?.data?.status === '2fa_required') {
      setStatus('otp');
      return;
    }

    if (response?.error) {
      toast(`AASRA error: ${response.error}`, { type: 'error' });
      setStatus('password');
      return;
    }

    if (response?.data?.roles?.length > 0) {
      toast(messages?.aasraSuccess, { type: 'success' });
      setStatus('done');
      setRoles && setRoles(response?.data?.roles || []);
      setAASRARole(response?.data?.roles);
      !noClose && close?.('success');
    } else {
      toast(
        response?.data?.noRoles
          ? messages?.noroles
          : `${messages?.aasraFailed} - AASRA profile not complete, please contact AASRA support@aasra.com.au`,
        { type: 'error' }
      );
      setStatus('password');
    }

    console.log(response);
  };

  const otpSubmit = async () => {
    if (!state.otp) {
      // Show toast
      toast(messages.missingInput, { type: 'error' });
      return;
    }
    setStatus('fetching');
    const response = await aasraLogin(
      '2fa',
      defaults?.oemid,
      state?.email || email || user.email,
      '',
      state.otp
    );

    if (response?.error) {
      toast(`AASRA error: ${response.error}`, { type: 'error' });
      return;
    }

    console.log(response);
    console.log('This is email value ' + email || user?.email);
    if (response?.data?.roles?.length > 0) {
      toast(messages?.aasraSuccess, { type: 'success' });
      setStatus('done');
      setRoles && setRoles(response?.data?.roles || []);
      setAASRARole(response?.data?.roles);
      !noClose && close?.('success');
    } else {
      toast(
        response?.data?.noRoles ? messages?.noroles : messages?.aasraFailed,
        { type: 'error' }
      );
      setStatus('password');
    }
  };

  return (
    <Modal close={close}>
      <S.Container>
        <Helmet>
          <title>{messages.login}</title>
        </Helmet>
        {status === 'fetching' && <FSLoader which="aasra login" />}
        {isLogin &&
          (status === 'otp' ? (
            <S.GuideImage src={stepSixImg} />
          ) : (
            <S.GuideImage src={stepFiveImg} />
          ))}
        <S.Content>
          <S.LeftSection>
            <S.AlignerContainer style={{ width: '80%' }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const f = status === 'otp' ? otpSubmit : handleSubmit;
                  f();
                }}
              >
                <div>
                  <h2
                    style={{
                      fontSize: '2rem',
                      textAlign: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    AASRA LOGIN{' '}
                    {status === 'otp' ? ' - One time password (OTP)' : ''}
                  </h2>
                </div>
                {status === 'failed' && (
                  <div>
                    <h3>Sign in failed</h3>
                  </div>
                )}
                <div className="flex flex-col gap-6">
                  {!setEmail && <div>{email || user?.email}</div>}
                  {setEmail && (
                    <InputGroup
                      value={state.email}
                      disabled={['otp'].includes(status)}
                      iconName="user"
                      autoFocus
                      placeholder={messages?.email}
                      onChange={(value: string) => {
                        setState({ ...state, email: value || '' });
                        setEmail(value);
                      }}
                    />
                  )}
                  {['password'].includes(status) && (
                    <InputGroup
                      id="password"
                      autoFocus={!setEmail}
                      value={state.password}
                      iconName="lock"
                      placeholder={messages.aasraPassword}
                      password
                      onChange={(value: string) =>
                        setState({ ...state, password: value })
                      }
                    />
                  )}
                  {['otp'].includes(status) && (
                    <InputGroup
                      id="otp"
                      value={state.otp}
                      autoFocus
                      iconName="authy"
                      placeholder={messages.otpAuthy}
                      // password
                      onChange={(value: string) =>
                        setState({ ...state, otp: value || '' })
                      }
                    />
                  )}
                </div>
                <div
                  className="flex flex-col gap-1"
                  style={{ marginTop: '0.2em' }}
                >
                  <AsyncButton
                    disabled={status === 'fetching'}
                    type="submit"
                    messageId={
                      status === 'fetching'
                        ? 'verifying'
                        : status === 'password'
                        ? 'authenticateAasra'
                        : 'otploginToAasra'
                    }
                  />
                  {!setEmail && (
                    <div className="flex justify-between">
                      <LinkButton
                        onClick={redirectSignUp}
                        messageId="noAccount"
                      />
                      {/* <LinkButton onClick={resetPassword} messageId="resetPassword" /> */}
                    </div>
                  )}
                </div>
              </form>
              <button
                onClick={() => {
                  history.push(`/${client}/login`);
                  close();
                }}
                style={
                  isIE()
                    ? { marginTop: '1em', outline: 0 }
                    : { marginTop: '-10px', outline: 0 }
                }
              >
                Back
              </button>
            </S.AlignerContainer>
          </S.LeftSection>
          <S.RightSection>
            {isIE() ? (
              <img
                src={defaults?.loginmodalimg}
                style={{ height: 'auto', width: '400px' }}
              />
            ) : (
              <S.Image src={defaults?.loginmodalimg} />
            )}
          </S.RightSection>
        </S.Content>
      </S.Container>
    </Modal>
  );
}

export default AasraModal;
