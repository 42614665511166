import { createClient } from '@supabase/supabase-js';
import { useHistory } from 'react-router';
import { useContext, useState } from 'react';

import { useLoggedIn, useToast } from 'utils/hooks';
import config from 'config';
import { APIContext } from 'components/API/Context';

const { url, key } = config;

const _cli = createClient(url || '', key || '', {
  autoRefreshToken: true,
  persistSession: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
    'Access-Control-Allow-Credentials': 'true',
  },
});

export const supabase = () => {
  return _cli;
};

export const useSupabase = () => {
  const client = window.location.pathname.split('/')[1];
  const [status, setStatus] = useState<'idle' | 'processing' | ''>();

  const { doLogout, user } = useLoggedIn();
  const { toast } = useToast();
  const { start, finish } = useContext(APIContext);
  const history = useHistory();

  const cli = () => {
    return _cli;
  };

  const handleError = () => {
    toast('sessionExpired', 'warning');
    console.log('sb error *****');
    doLogout('sub error').then(() => history.push(`/${client}`));
  };

  const stripSpecial = (storageName) => {
    const singleSpace = storageName.trim().replace(/  +/g, ' ');
    const singleSpaceToHyphen = singleSpace.replace(/ /g, '-').toLowerCase();
    return singleSpaceToHyphen.replace(/[&%.^]/g, '');
  };

  const createBucket = async (bucket: string) => {
    // const strippedName = stripSpecial(bucket);
    const { data, error } = await cli().storage.listBuckets();
    if (error) throw error;
    const t = data?.filter((b) => b.name === bucket);
    if (t && t.length === 0) {
      const { data: data1, error: error1 } = await cli().storage.createBucket(
        bucket,
        {
          public: false,
        }
      );
      if (error1) throw error1;
    }
  };

  const uploadFile = async (
    what: any
  ): Promise<{ data?: any; error?: Error }> => {
    try {
      console.log({ what });
      setStatus('processing');
      createBucket(what.store.toString());
      const r2: any = await cli()
        .storage.from(what.store.toString())
        .upload(what.path, what.file, {
          cacheControl: '3600',
          upsert: false,
        });
      setStatus('idle');
      return r2;
    } catch (error: any) {
      if (error.message.includes('JWT')) handleError();
      else throw error;
      return { error };
    }
  };

  const removeFile = async (what: string, store: string) => {
    try {
      console.log({ what });
      setStatus('processing');
      const r2 = await cli().storage.from(store).remove([what]);
      setStatus('idle');
      return r2;
    } catch (error: any) {
      if (error.message.includes('JWT')) handleError();
      else throw error;
    }
  };

  const downloadFile = async (what: string, store: string) => {
    try {
      console.log({ what });
      setStatus('processing');
      const r2 = await cli().storage.from(store?.toString()).download(what);
      setStatus('idle');
      return r2;
    } catch (error: any) {
      if (error.message.includes('JWT')) handleError();
      else throw error;
    }
  };

  const from = (table: string) => {
    const client: any = window.sessionStorage.getItem('client');
    window.sessionStorage.setItem('last_active', Date.now().toString());
    const r1 = cli().from(table);
    return {
      select: async (how: string): Promise<any> => {
        try {
          // console.log(table, client);
          if (
            !client &&
            !['tb_oem_list', 'tb_oem_userlist', 'tb_language_id'].find(
              (x) => x === table
            )
          )
            return { data: undefined };
          start();
          const r2 = await r1.select(how).throwOnError();
          finish();
          if (r2.error) handleError();
          else return r2;
        } catch (error: any) {
          finish();
          if (error.message.includes('JWT')) handleError();
          else throw error;
        }
      },
      selectEq: async (
        how: string,
        field: string,
        query: any
      ): Promise<any> => {
        try {
          // console.log(table, client);
          if (
            !client &&
            !['tb_oem_list', 'tb_oem_userlist', 'tb_language_id'].find(
              (x) => x === table
            )
          )
            return { data: undefined };
          start();
          const r2 = await r1.select(how).eq(field, query).throwOnError();
          finish();
          if (r2.error) handleError();
          else return r2;
        } catch (error: any) {
          finish();
          if (error.message.includes('JWT')) handleError();
          else throw error;
        }
      },
      selectFilter: async (
        how: string,
        field: string,
        query: any
      ): Promise<any> => {
        try {
          // console.log(table, client);
          if (
            !client &&
            !['tb_oem_list', 'tb_oem_userlist', 'tb_language_id'].find(
              (x) => x === table
            )
          )
            return { data: undefined };
          start();
          const r2 = await r1
            .select(how)
            .filter(field, 'eq', query)
            .throwOnError();
          finish();
          if (r2.error) handleError();
          else return r2;
        } catch (error: any) {
          finish();
          if (error.message.includes('JWT')) handleError();
          else throw error;
        }
      },
      update: async (what: any, match: any): Promise<any> => {
        try {
          const r2 = await r1.update(what).match(match).throwOnError();
          if (r2.error) handleError();
          else return r2;
        } catch (error: any) {
          if (error.message.includes('JWT')) handleError();
          else throw error;
        }
      },
      insert: async (what: any): Promise<any> => {
        try {
          const r2 = await r1.insert(what).throwOnError();
          if (r2.error) handleError();
          else return r2;
        } catch (error: any) {
          if (error.message.includes('JWT')) handleError();
          else throw error;
        }
      },
      delete: async (match: any): Promise<any> => {
        try {
          const r2 = await r1.delete().match(match).throwOnError();
          if (r2.error) handleError();
          else return r2;
        } catch (error: any) {
          if (error.message.includes('JWT')) handleError();
          else throw error;
        }
      },
    };
  };

  return {
    from,
    auth: cli().auth,
    uploadFile,
    removeFile,
    downloadFile,
    cli: cli(),
    createBucket,
    status,
  };
};
