import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useState, useContext, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import LanguageSelector from 'components/Language/Selector';
import { useOEMList, useOEMUserList } from 'features/page/hooks';
import { OEMList } from 'types';
import { useConfig, useLanguages } from 'utils/hooks';
import { useSupabase } from 'utils/supabase';
import { blobToBase64 } from 'utils/functions';

const Foot = styled.footer<{ $data: OEMList; $fixed: boolean }>`
  ${(props) => {
    return !!props.$fixed
      ? 'position: fixed; bottom: 0; left: 0; right: 0;'
      : '';
  }}
  width: 100%;
  background-color: ${(props) => props.$data?.backgroundcolour};
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: #ccc;
    text-align: center;
    font-size: 14px;
    padding: 16px;
    text-decoration: none;
    transition: color linear 100ms;
    &:hover {
      color: red;
    }
  }

  /* @media (max-width: 600px) {
    position: relative;
  } */
`;

function Footer({ showModal }: { showModal: any }) {
  const { messages } = useLanguages();
  const { defaults } = useConfig();
  const location = useLocation();
  const [hideFooter, setHideFooter] = useState<boolean>(false);
  const { isLoading, isLoadingUser } = useOEMUserList();
  const slugs = window.location.pathname.split('/');
  const loc = slugs[2];
  const client = slugs[1];
  const { downloadFile } = useSupabase();

  useEffect(() => {
    // const slugs = location.pathname.split('/');
    // const hideFooter =
    //   client === 'ineos' &&
    //   [2, 3].includes(slugs.length) &&
    //   slugs[2] !== 'login' &&
    //   slugs[3] !== 'gap';
    // setHideFooter(hideFooter);
    const hideFooter = client === 'ineos';
    setHideFooter(hideFooter);
  }, [client, location]);

  const resized = useCallback(() => {
    const slugs = location.pathname.split('/');
    // const hideFooter =
    //   client === 'ineos' &&
    //   [2, 3].includes(slugs.length) &&
    //   slugs[2] !== 'login' &&
    //   slugs[3] !== 'gap' &&
    //   slugs[2] !== 'signup';
    const hideFooter = client === 'ineos';
    setHideFooter(hideFooter);
    const root = document.documentElement;
    if (
      (slugs.length === 3 || slugs.length === 2) &&
      slugs[2] !== 'signup' &&
      slugs[2] !== 'login'
    )
      root.style.setProperty('--body-bg', '#white');
    else root.style.setProperty('--body-bg', '#f3f4f6');
    const h = document.getElementById('r2r-header');
    const c = document.getElementById('r2r-content');
    const r = document.getElementById('root');
    const f = document.getElementById('footer');
    const i = document.getElementById('iframe-content');
    // console.log('HEIGHTS ::::', window.innerHeight, { hideFooter, h, f, i, c });
    if (!h || (!f && !i && hideFooter)) setTimeout(resized, 50);
    if (i)
      i.style.height = `${window.innerHeight - (h?.clientHeight || 0) - 2}px`;
    if (c)
      c.style.height = `${
        window.innerHeight -
        (!hideFooter ? f?.clientHeight || 0 : 0) -
        (h?.clientHeight || 0) -
        1
      }px`;
    if (r) r.style.height = window.innerHeight + 'px';
  }, [client, location]);

  useEffect(() => {
    if (!isLoading || !isLoadingUser) setTimeout(resized, 100);
  }, [isLoading, isLoadingUser, resized]);

  useEffect(() => {
    window.addEventListener('resize', resized);

    resized();
    return () => {
      window.removeEventListener('resize', resized);
    };
  }, [location, resized]);

  // if (!defaults || hideFooter) return null;
  if (hideFooter) return null;

  return (
    <Foot
      id="footer"
      className="p-2 w-full"
      $fixed={!!slugs[3] || slugs[2] === 'login'}
      $data={defaults}
    >
      <div className="flex flex-initial justify-between mx-8">
        <div style={{ padding: '2px' }}>
          <a
            href={defaults?.oemHomeUrl}
            style={{ color: defaults?.fontcolour, textDecoration: 'none' }}
            target="_blank"
          >
            © {defaults?.footerOemName}
          </a>
        </div>
        <div className="flex flex-1 justify-center">
          {/* <p>{defaults?.footerLabels}</p> */}
          {defaults?.footerLabels
            ?.map((label) => messages[label])
            .map((description) => (
              <Link
                key={description}
                id={description}
                onClick={async (e) => {
                  let str;
                  try {
                    const data = await downloadFile(
                      defaults?.[description],
                      defaults.oemid
                    );
                    str = await blobToBase64(data?.data);
                    console.log('str');
                    console.log(str);
                    showModal(e, str);
                  } catch (error) {
                    console.log('error');
                    console.log(error);
                    showModal(e);
                  }
                }}
                style={{
                  padding: '2px',
                  color: defaults?.fontcolour,
                  marginLeft: '4%',
                  marginRight: '4%',
                }}
                to="#"
              >
                {description}
              </Link>
            ))}
        </div>
        <ul>
          <li
            style={{ height: '100%', position: 'relative', top: '-1.5px' }}
            className="flex items-center"
          >
            <span
              style={{ color: defaults?.fontcolour }}
              className="fas fa-globe"
            />{' '}
            <LanguageSelector color={defaults?.fontcolour} />
          </li>
        </ul>
      </div>
    </Foot>
  );
}

export default Footer;
