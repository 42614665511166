import { FormattedMessage } from 'react-intl';

import * as S from './styled';

type Props = {
  messageId: string;
  onClick?: Function;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

function AsyncButton({ messageId, onClick, disabled, type }: Props) {
  const status: any = '';
  return (
    <S.LoginButton
      type={type}
      disabled={disabled || status === 'loading'}
      onClick={onClick as any}
      className="rounded"
    >
      {status === 'loading' ? (
        <FormattedMessage id="waiting" />
      ) : (
        <FormattedMessage id={messageId} />
      )}
    </S.LoginButton>
  );
}

export default AsyncButton;
