import { atom } from 'recoil';

import { RepairComponent, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: RepairComponent[];
  ids?: RepairComponent[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const RepairComponentState = atom({
  key: 'RepairComponent',
  default: initialState,
});

export function useRepairComponent() {
  const xport = useGeneric(
    RepairComponentState,
    'tb_repaircomponent',
    'repaircomponent',
    (data: RepairComponent, idData: RepairComponent) => {
      idData.repairgroupid = data.repairgroupid;
      idData.repairsubgroupid = data.repairsubgroupid;
    },
    undefined,
    true
  );

  return xport;
}
