import * as S from './styled';

function PrimaryButton({
  children,
  onClick,
  margin,
  width,
  borderRadius,
}: {
  children: any;
  onClick?: Function;
  margin?: string;
  width?: string;
  borderRadius?: string;
}) {
  return (
    <S.Button
      $width={width}
      $margin={margin}
      onClick={onClick as any}
      $borderRadius={borderRadius}
    >
      {children}
    </S.Button>
  );
}

export default PrimaryButton;
