import Modal from 'components/Modal';
import PrimaryButton from 'components/PrimaryButton';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useLanguages } from 'utils/hooks';
import isIE from 'utils/isIE';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 3.5em;
  padding: 1em 3em 4em;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 2em;
  margin-top: 1em;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  width: 300px;

  :hover {
    border: 1px solid #000;
    border-radius: 6px;
  }
`;

const OemLogo = styled.img<any>`
  max-height: ${(props) => (props.$isIE ? '300px' : '')};
  max-width: ${(props) => (props.$isIE ? '300px' : '')};
  object-fit: cover;
`;

const Title = styled.h2`
  color: #000;
  font-size: 2.4em;
`;

function SelectOemModal({
  close,
  goBack,
}: {
  close: Function;
  goBack?: Function;
}) {
  const history = useHistory();
  const { messages } = useLanguages();

  return (
    <Modal close={close} style={{ minWidth: '900px' }}>
      <Container>
        <Title>{messages.selectOem}</Title>
        <LogoContainer>
          <ImageContainer onClick={() => history.push('/mmal/signup')}>
            <OemLogo
              $isIE={isIE()}
              src="https://oerbchdluxfigwgevqiv.supabase.co/storage/v1/object/sign/1/0/00/MitsubishiLogo.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiIxLzAvMDAvTWl0c3ViaXNoaUxvZ28ucG5nIiwiaWF0IjoxNjU0ODM2OTI0LCJleHAiOjE5NzAxOTY5MjR9.9WN25NrpbI4xCmfF2xTiA2aSgCkj_ZBYqolXhurtMP4&t=2022-06-10T04%3A55%3A24.282Z"
            />
          </ImageContainer>
          <ImageContainer onClick={() => history.push('/honda/signup')}>
            <OemLogo
              $isIE={isIE()}
              src="https://oerbchdluxfigwgevqiv.supabase.co/storage/v1/object/sign/3/00/00/H-Honda_logo.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiIzLzAwLzAwL0gtSG9uZGFfbG9nby5wbmciLCJpYXQiOjE2NTM2MzA1OTYsImV4cCI6MTk2ODk5MDU5Nn0.w0Hnymyl3WwU_ZfAwEXsrfhBRb-qJ0FanbplZtscsQA&t=2022-05-27T05%3A49%3A55.309Z"
            />
          </ImageContainer>
          <ImageContainer onClick={() => history.push('/subaru/signup')}>
            <OemLogo
              $isIE={isIE()}
              src="https://oerbchdluxfigwgevqiv.supabase.co/storage/v1/object/sign/2/0/00/Subaru%20CIM%20Stacked%20RGB%20logo.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiIyLzAvMDAvU3ViYXJ1IENJTSBTdGFja2VkIFJHQiBsb2dvLnBuZyIsImlhdCI6MTY1MzYyNjM2NywiZXhwIjoxOTY4OTg2MzY3fQ.xncXz7skfW5B5XNHvF-cmxbicqZ_FtLbQCqXtyypphg&t=2022-05-27T04%3A39%3A26.378Z"
            />
          </ImageContainer>
        </LogoContainer>
        <PrimaryButton
          borderRadius="4px"
          margin="3em 0 0 0"
          onClick={goBack}
          width="200px"
        >
          {messages.back}
        </PrimaryButton>
      </Container>
    </Modal>
  );
}

export default SelectOemModal;
