export const blobToBase64 = (blob): Promise<any> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export function formatDate(dateString: string) {
  const d = new Date(dateString);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();
  let hours = d.getHours().toString();
  let minutes = d.getMinutes().toString();
  let seconds = d.getSeconds().toString();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hours.length < 2) hours = '0' + hours;
  if (minutes.length < 2) minutes = '0' + minutes;
  if (seconds.length < 2) seconds = '0' + seconds;

  const date = [year, month, day].join('/');
  const time = [hours, minutes, seconds].join(':');
  return `${date} ${time}`;
}

export function getUTCNow(indate = undefined) {
  const date = indate || new Date();
  const offset = date.getTimezoneOffset();
  // console.log(date.getTimezoneOffset());
  return new Date(+date + offset);
}

export function formatDateUTC(dateString: string | number) {
  console.log('DATE ::::', dateString);
  const d = new Date(dateString);
  // const d = getUTCNow(new Date(dateString));
  let month = '' + (d.getUTCMonth() + 1);
  let day = '' + d.getUTCDate();
  let year = d.getUTCFullYear();
  let hours = d.getUTCHours().toString();
  let minutes = d.getUTCMinutes().toString();
  let seconds = d.getUTCSeconds().toString();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hours.length < 2) hours = '0' + hours;
  if (minutes.length < 2) minutes = '0' + minutes;
  if (seconds.length < 2) seconds = '0' + seconds;

  const date = [year, month, day].join('/');
  const time = [hours, minutes, seconds].join(':');
  return `${date} ${time}`;
}
