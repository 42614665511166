import styled from 'styled-components';

export const MyAccountContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 1em 2em 2em 2em;
  width: 800px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  width: 100%;
`;

export const Input = styled.div<any>`
  transform: ${(props) =>
    props.$display ? 'translateX(-100%)' : 'transition(0)'};
  width: 100%;
  transition: transform 1s;
`;

export const FullName = styled.h2`
  font-size: 1.6rem;
`;

export const Body = styled.div`
  display: flex;
  /* justify-content: flex-start; */
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  width: 70%;
`;

export const OktaInputs = styled.div<any>`
  /* display: ${(props) => (props.$display ? 'block' : 'none')}; */
  transform: ${(props) =>
    props.$display ? 'translateX(-100%)' : 'translateX(100%)'};
  transition: transform 1s;
  width: 100%;
`;

export const UserImage = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`;

export const Line = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 40px;
  width: 100%;
`;
