import { atom } from 'recoil';

export const authState = atom({
  key: 'auth',
  default: {
    user: undefined,
    status: 'loading',
  },
  dangerouslyAllowMutability: true,
});

export const rootState = atom({
  key: 'root',
  default: {},
});

export const configtState = atom({
  key: 'config',
  default: {},
});
