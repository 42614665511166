import { useEffect, useState } from 'react';
import PDF from 'react-pdf-watermark';
import styled from 'styled-components';

import { useConfig, useLanguages, useLoggedIn } from 'utils/hooks';
import ErrorBoundary from 'utils/errorBoundary';
import { useOEM } from 'features/OEM/hooks';
import { OEM } from 'types';

const Container = styled.div<any>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
  canvas {
    pointer-events: none;
    ${(props) => props?.$canvas}
  }
`;

const NoPath = styled.div`
  width: 100%;
  text-align: center;
  padding: 4rem;
`;

const Loading = styled.div`
  padding: 4rem;
`;

const Input = styled.input`
  width: 4rem;
  padding: 0.5rem;
`;

export default function PDFViewer({
  path,
  noWatermark,
  canvas,
}: {
  path?: string;
  noWatermark?: boolean;
  canvas?: string;
}) {
  const { messages } = useLanguages();
  const { user } = useLoggedIn(true, true);
  const [state, setState] = useState<any>();
  const [status, setStatus] = useState<'loading' | 'error' | 'done'>('loading');
  const { data: oem }: { data: OEM[] | undefined } = useOEM();

  useEffect(() => {
    const t = setTimeout(() => {
      if (status === 'loading') setStatus('error');
    }, 30000);

    return () => {
      clearTimeout(t);
    };
  }, [status]);

  function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
    for (var n = 0; n < text.length; n++) {
      var line = text[n];
      // var metrics = ctx.measureText(testLine);
      // var testWidth = metrics.width;
      ctx.fillText(line, x, y);
      y += lineHeight;
    }
    // ctx.fillText(line, x, y);
  }

  const applyWatermark = (canvas, context) => {
    context.globalAlpha = 0.35;
    context.font = '45px bold Arial';
    context.textAlign = 'center';
    context;
    // context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate(-Math.atan(canvas.height / canvas.width)); // Rotate watermark to show diagonally

    console.log({ user }, oem);
    const text = [
      messages.wm1,
      messages.wm2.replace('{oem}', oem?.[0]?.oemdescription),
      messages.wm3.replace('{user}', user.id || user.email),
      // messages.wm4
      //   .replace('{from}', '01 January 2022')
      //   .replace('{to}', '15 January 2022'),
    ];
    // const metrics = context.measureText(text);
    // context.fillText(text, -metrics.width / 2, 55 / 2);
    wrapText(
      context,
      text,
      canvas.width < canvas.height ? -350 : 600,
      canvas.width < canvas.height ? 1350 : 1450,
      canvas.width < canvas.height ? canvas.width - 100 : canvas.width / 2,
      75
    );
  };

  const handlePrevious = () => {
    setState({ ...state, page: state.page - 1 });
  };

  const handleNext = () => {
    console.log(state);
    setState({ ...state, page: state.page + 1 });
  };

  const renderPagination = (page, pages) => {
    let previousButton: any = (
      <button className="previous" onClick={handlePrevious}>
        <i className="fa fa-arrow-left"></i> Previous
      </button>
    );
    if (page === 1) {
      previousButton = null;
    }
    let nextButton: any = (
      <button
        style={{ zIndex: 99999999 }}
        className="next"
        onClick={handleNext}
      >
        Next <i className="fa fa-arrow-right"></i>
      </button>
    );
    if (page === pages) {
      nextButton = null;
    }
    return (
      <div
        style={{
          position: 'sticky',
          bottom: '0',
          background: 'white',
        }}
      >
        <nav>
          <div className="pager" style={{ paddingBottom: '1rem' }}>
            {previousButton}
            {previousButton && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            <Input
              type="number"
              value={page}
              onChange={(e) => {
                const val = parseInt(e.target.value);
                if (val < 1 || val > state.pages) return;
                setState({ ...state, page: val });
              }}
            />
            <span> of {state.pages} pages</span>
            {nextButton && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            {nextButton}
          </div>
        </nav>
      </div>
    );
  };
  let pagination: any = null;
  if (state?.pages) {
    pagination = renderPagination(state.page, state.pages);
  }

  if (!path) {
    return <NoPath>{messages.noFile}</NoPath>;
  }

  console.log({ state, user });
  return (
    <ErrorBoundary>
      <Container $canvas={canvas}>
        {status === 'loading' && <Loading>{messages.loading}...</Loading>}
        {status === 'error' && <Loading>{messages.loadingError}</Loading>}
        <PDF
          // file="/test.pdf"
          // file="https://rfgygvspshscbdcjisdj.supabase.co/storage/v1/object/public/mmal/0/M/MY22/M.MY22.pdf"
          file={path}
          page={state?.page}
          watermark={noWatermark ? '' : applyWatermark}
          // watermarkOptions={{
          //   transparency: 0.5,
          //   fontSize: 55,
          //   fontStyle: 'Bold',
          //   fontFamily: 'Arial',
          //   transform: 'rotate(-90deg)',
          // }}
          scale={3}
          onError={() => {
            setStatus('error');
          }}
          onDocumentComplete={() => {
            setStatus('done');
            setState({ ...state, page: 1 });
            /* Do anything on document loaded like remove loading, etc */
          }}
          onPageRenderComplete={(pages, page) => setState({ page, pages })}
        />
        {pagination}
      </Container>
    </ErrorBoundary>
  );
}
