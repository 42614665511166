import { AssetCategory } from 'types';
import { Link } from '../styled';
import { useAnalytics, useLanguages } from 'utils/hooks';

function AssetCatRenderer({
  assetCat,
  assetCategory,
  client,
  defaults,
  index,
  onClick,
  to,
}: {
  assetCat: AssetCategory;
  assetCategory: string;
  client: string;
  defaults: any;
  index: number;
  onClick: any;
  to?: string;
}) {
  const { messages } = useLanguages();
  let label = messages[assetCategory.toLowerCase()];
  const { menu } = useAnalytics();

  const handleClick = () => {
    menu(label, assetCat?.assetcategoryid);
    onClick(assetCategory);
  };

  return (
    <li key={index} style={{ whiteSpace: 'nowrap' }}>
      {assetCat?.assetcategoryimageurl ||
        (assetCat.assetcategoryimageurlthumbnail && (
          <img
            style={{
              width: '20px',
              height: '20px',
              display: 'inline',
            }}
            src={
              assetCat?.assetcategoryimageurlthumbnail ||
              assetCat?.assetcategoryimageurl
            }
          />
        ))}
      &nbsp;&nbsp;
      <Link
        $client={client}
        $data={defaults}
        onClick={handleClick}
        to={to || '#'}
      >
        {label}
      </Link>
    </li>
  );
}

export default AssetCatRenderer;
