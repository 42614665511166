import styled from 'styled-components';
import TextInput2 from 'components/TextInput2';

export const AlignerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  width: 70%;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: 500px; */
  width: 900px;
  padding: 20px 20px 60px 20px;
`;

export const Input = styled(TextInput2)`
  width: 100%;
`;

export const InputGroup = styled.div`
  align-items: center;
  display: flex;
  height: 57.95px;
  width: 100%;
`;

export const IconContainer = styled.div`
  height: 80px;
  width: 80px;
`;

export const Image = styled.img`
  object-fit: contain;
  max-width: 400px;
  /* padding-bottom: 30px; */
`;

export const ImageIE = styled.img`
  height: '100px';
  width: '100px';
  padding-bottom: 30px;
`;

export const LeftSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 50%;
`;

export const RightSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

export const GuideImage = styled.img`
  width: 800px;
`;

export const Content = styled.section`
  display: flex;
  width: 100%;
`;
