import { atom } from 'recoil';

import { RepairGroup, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: RepairGroup[];
  ids?: RepairGroup[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const RepairGroupState = atom({
  key: 'RepairGroup',
  default: initialState,
});

export function useRepairGroup() {
  const xport = useGeneric(
    RepairGroupState,
    'tb_repairgroup',
    'repairgroup',
    (data, iData) => {
      delete data?.brandid;
      delete data?.divisionid;
    }
  );

  return xport;
}
