import { useContext } from 'react';
import styled from 'styled-components';

import { LanguageContext } from './Context';
import * as S from './styled';
import { Language } from 'types';

const Select = styled.select`
  background-color: transparent;
  color: #ccc;
  width: 120px;
  font-size: 14px;
`;

const Option = styled.option`
  color: black;
`;

export default function LanguageSelector({
  color,
}: {
  color?: string;
}): JSX.Element {
  const { setLocale, messages, locale, locales, languageId } =
    useContext(LanguageContext);

  return (
    <S.LocalesContainer>
      <Select
        value={languageId}
        onChange={(e: any) => {
          setLocale(e.target.value);
          console.log(e.target.value);
        }}
        className="p-2 w-full"
        style={{ color: color || 'grey' }}
      >
        {locales?.map((l: Language) => {
          const t = l.languageisocode.substring(0, 2);
          return (
            <Option key={t} value={l.languageid}>
              {messages[t]}
            </Option>
          );
        })}
      </Select>
    </S.LocalesContainer>
  );
}
