import { atom } from 'recoil';

import { ModelYear, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: ModelYear[];
  ids?: ModelYear[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const ModelYearState = atom({
  key: 'ModelYear',
  default: initialState,
});

export function useModelYear() {
  const xport = useGeneric(
    ModelYearState,
    'tb_modelyear',
    'modelyear',
    (data: ModelYear, idData: ModelYear) => {
      // delete data?.oemid;
      delete data?.brandid;
      delete data?.divisionid;
      delete data?.modelfamilyid;
      delete data?.modelseriesid;
      delete data?.modelid;
    },
    (data: ModelYear[], iData: ModelYear[]) => {
      return data.map((d) => {
        const i = iData?.find(
          ({ modelyearid }) => modelyearid === d.modelyearid
        );
        return {
          ...d,
          oemid: parseInt(i?.oemid?.toString() || '0'),
          divisionid: i?.divisionid,
          brandid: i?.brandid,
          modelfamilyid: i?.modelfamilyid,
          modelseriesid: i?.modelseriesid,
          modelid: i?.modelid,
        };
      });
    },
    false,
    false,
    true
  );

  return xport;
}
