import styled from 'styled-components';
import TextInput2 from 'components/TextInput2';

export const AlignerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  width: 70%;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
`;

export const Container = styled.div`
  height: 500px;
  width: 900px;
`;
export const FlexContainer = styled.div`
  height: 500px;
  width: 900px;
`;
export const Input = styled(TextInput2)``;

export const InputGroup = styled.div`
  align-items: center;
  place-content: center;
  display: flex;
  flex-direction: column;
  height: 57.95px;
`;

export const IconContainer = styled.div`
  height: 80px;
  width: 80px;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  padding-bottom: 30px;
`;

export const GuideImg = styled.img`
  margin: 2em 0;
  width: 800px;
`;

export const LeftSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 50%;
`;

export const RightSection = styled.div`
  width: 50%;
`;

export const H1 = styled.h1`
  font-size: 24px;
  font-family: 'mmcbold', sans-serif;
`;
