import { atom } from 'recoil';

import { RepairSubGroup, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: RepairSubGroup[];
  ids?: RepairSubGroup[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const RepairSubGroupState = atom({
  key: 'RepairSubGroup',
  default: initialState,
});

export function useRepairSubGroup() {
  const xport = useGeneric(
    RepairSubGroupState,
    'tb_repairsubgroup',
    'repairsubgroup',
    (data: RepairSubGroup, idData: RepairSubGroup) => {
      idData.repairgroupid = data.repairgroupid;
    }
  );

  return xport;
}
