import { useContext, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';

import LanguageProvider from 'components/Language/Provider';
import ThemeProvider from 'components/Theme/Provider';
import APIProvider from 'components/API/Provider';
import { AppContext } from 'components/App/Context';
import App from 'features/page';

const helmetContext = {};

function AppRoot() {
  const { user, oem, setUser } = useContext(AppContext);

  // const json: any = sessionStorage.getItem('auth');
  // const store = JSON.parse(json || '{}');
  const cli = window.location.pathname.split('/')[1];

  useEffect(() => {
    if (!oem && cli && !user) setUser(undefined);
  }, [oem, cli, setUser, user]);

  // console.log('APPROOT :::::', cli, oem, user);

  // if (!oem && !cli) {
  //   return <span>Loading...</span>;
  // }

  return (
    <RecoilRoot key={`${oem || cli}` || 'none'}>
      {/* <RecoilRoot> */}
      <LanguageProvider>
        <ToastContainer />
        <HelmetProvider context={helmetContext}>
          <ThemeProvider>
            <APIProvider>
              <Router>
                <App />
              </Router>
            </APIProvider>
          </ThemeProvider>
        </HelmetProvider>
      </LanguageProvider>
    </RecoilRoot>
  );
}

export default AppRoot;
