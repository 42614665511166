import TextInput2 from 'components/TextInput2';
import { useLanguages } from 'utils/hooks';

function AddressForm({
  data,
  setData,
  noFocus = false,
  required = false,
  validateAbnPostcode,
  disabled = false,
}: {
  data: any;
  setData: Function;
  noFocus?: boolean;
  required?: boolean;
  validateAbnPostcode?: any;
  disabled?: boolean;
}) {
  const { messages } = useLanguages();

  return (
    <div style={{ marginTop: '2.1em' }}>
      <TextInput2
        value={data?.street1}
        label={messages.street1}
        // autoFocus={!noFocus}
        required={required}
        example="21 Jump Street"
        onChange={(e) => setData({ ...data, street1: e })}
        disabled={disabled}
        style={{ padding: '0.5em' }}
      />
      <TextInput2
        value={data?.street2}
        label={messages.street2}
        example=""
        onChange={(e) => setData({ ...data, street2: e })}
        disabled={disabled}
        style={{ padding: '0.5em' }}
      />
      <TextInput2
        value={data?.city}
        label={messages.city}
        required={required}
        example="Brisbane"
        onChange={(e) => setData({ ...data, city: e })}
        disabled={disabled}
        style={{ padding: '0.5em' }}
      />
      <TextInput2
        value={data?.state}
        label={messages.state}
        required={required}
        example="QLD"
        onChange={(e) => setData({ ...data, state: e })}
        disabled={disabled}
        style={{ padding: '0.5em' }}
      />
      <TextInput2
        value={data?.postcode}
        label={messages.postcode}
        required={required}
        example="4000"
        onChange={(e) => setData({ ...data, postcode: e })}
        onBlur={validateAbnPostcode}
        disabled={disabled}
        style={{ padding: '0.5em' }}
      />
    </div>
  );
}

export default AddressForm;
