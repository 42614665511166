import styled from 'styled-components';
import { useConfig, useLanguages } from 'utils/hooks';

const oemFullName = {
  mmal: 'Mitsubishi Motors Australia LTD',
  subaru: 'Subaru (Aust) Pty Ltd',
  honda: 'Honda Australia Pyt Ltd',
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  margin-top: 1.2em;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1px 8px 0 0;
  gap: 10px;
  text-align: right;
`;

const OEMNAme = styled.h3`
  font-family: 'mmcregular', sans-serif;
  font-size: 1rem;
  font-weight: bold;
`;

const AssetCategory = styled.h2<{ $color?: string; $fontSize?: string }>`
  color: ${(props) => (props.$color ? props.$color : '#000000')};
  font-family: 'mmcregular', sans-serif;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '1rem')};
`;

const RedLine = styled.div`
  border: 2px solid #c00000;
  margin-bottom: 1em;
  width: 100%;
`;

const SubTitle = styled.h3`
  color: gray;
  font-family: 'mmcregular', sans-serif;
  font-size: 0.8rem;
  max-width: 500px;
`;

function ModalHeader({
  client,
  description,
}: {
  client: string;
  description?: string;
}) {
  const { defaults } = useConfig();
  const { messages } = useLanguages();

  if (!defaults) return null;

  const renderLogoImage = () => {
    return (
      <img
        className="w-auto"
        style={{ height: '126px' }}
        src={defaults?.popuplogourl}
        alt="oem logo image"
      />
    );
  };

  const renderAssetCategory = () => {
    if (client === 'mmal')
      return <AssetCategory $color="#c00000">{description}</AssetCategory>;
    if (client === 'subaru')
      return <AssetCategory $color="#0b4499">{description}</AssetCategory>;
    if (client === 'honda')
      return <AssetCategory $color="#c00000">{description}</AssetCategory>;
  };

  const renderSubTitle = () => {
    if (client === 'mmal' && description === 'Contact Us')
      return <SubTitle>{messages.mmalContactUsSubtitle}</SubTitle>;
  };

  return (
    <>
      <Header>
        {renderLogoImage()}
        <RightSection>
          <OEMNAme>{oemFullName[client]}</OEMNAme>
          {renderAssetCategory()}
          {renderSubTitle()}
        </RightSection>
      </Header>
      {client === 'mmal' && <RedLine />}
    </>
  );
}

export default ModalHeader;
