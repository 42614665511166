import { atom } from 'recoil';

import { Model, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: Model[];
  ids?: Model[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const ModelState = atom({
  key: 'Model',
  default: initialState,
});

export function useModel() {
  const xport = useGeneric(
    ModelState,
    'tb_model',
    'model',
    (data: Model, idData: Model) => {
      idData.modelfamilyid = data.modelfamilyid;
      idData.modelseriesid = data.modelseriesid;
      // delete data?.oemid;
      delete data?.brandid;
      delete data?.divisionid;
    },
    (data: Model[], iData: Model[]) => {
      return data.map((d) => {
        const i = iData?.find(({ modelid }) => modelid === d.modelid);
        return {
          ...d,
          oemid: parseInt(i?.oemid?.toString() || '0'),
          divisionid: i?.divisionid,
          brandid: i?.brandid,
          modelfamilyid: i?.modelfamilyid,
          modelseriesid: i?.modelseriesid,
        };
      });
    }
  );

  return xport;
}
