/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { debounce } from 'lodash';
import writeXlsxFile from 'write-excel-file';

import PrimaryButton from 'components/PrimaryButton';
import Select from 'components/Select';
import * as S from './Search.styled';
import { useModelFamily } from 'features/modelFamily/hooks';
import { useLanguages, useAdminNav, useClient } from 'utils/hooks';
import TextInput2 from 'components/TextInput2';
import { useModelYear } from 'features/modelYear/hooks';
import { useModelSeries } from 'features/modelSeries/hooks';
import { useModel } from 'features/model/hooks';
import { AssetCategory, Model, ModelSeries, ModelYear } from 'types';
import { useAssetCategories, useAssets } from 'features/assets/hooks';
import ResultsTable from '../components/ResultsTable';
import { useSearch, Result, SearchData } from '../hooks';
import { useAssetTypes } from 'features/assetsType/hooks';
import { useOEMUserList } from 'features/page/hooks';

export type State = {
  catName: string;
  modelfamilyid: number;
  vin: string;
  modelyearid: number;
  modelseriesid: number;
  modelid: number;
  search: string;
  assettypeid?: number;
  assetcategoryid?: number;
};

export default function EditTenant({
  assetOnboard,
  children,
  hasNewButton,
  heading,
  canDownloadAssets,
}: {
  assetOnboard?: boolean;
  children?: React.ReactNode;
  hasNewButton?: boolean;
  heading?: string;
  canDownloadAssets?: boolean;
}) {
  const { userSubs } = useOEMUserList();
  const { client } = useClient();
  const { assetCategoryName: catName }: any = useParams();
  const history = useAdminNav();
  const [data, setData] = useState<State>({ search: '', vin: '' } as State);
  const { messages, languageId } = useLanguages();
  const { data: modelFamily } = useModelFamily();
  const { data: modelYear } = useModelYear();
  const { data: modelSeries } = useModelSeries();
  const { data: assetCategories } = useAssetCategories();
  const { data: assetTypes } = useAssetTypes();
  const { data: models }: { data: Model[] | undefined } = useModel();
  const [filter, setFilter] = useState<string | undefined>();
  const [filtered, setFiltered] = useState<Result[] | undefined>();
  const [results, setResults] = useState<Result[]>();
  const {
    search,
    status,
    fullList,
    vinSearch,
    vinResults,
    buildResults,
    data: searchData,
    resetVinSearch,
  } = useSearch(catName);
  const cat: AssetCategory | undefined = assetCategories?.find(
    ({ assetcategorydescription, languageid }: AssetCategory) =>
      languageid === languageId &&
      assetcategorydescription.toLowerCase().replaceAll(' ', '-') ===
        catName?.toString()
  );
  const isVinSearch = location.pathname.split('/')[4] === 'vin-search';
  const isDiagSoft = location.pathname.split('/')[4] === 'diagnostic-software';
  const { hasClientAdmin } = useOEMUserList();

  const reset = useCallback(() => {
    setData({
      search: '',
      vin: '',
      modelfamilyid: -1,
      assetcategoryid: -1,
    } as State);
    setResults(fullList);
    setFilter('');
    setFiltered(undefined);
    resetVinSearch();
    /* eslint-disable-next-line */
  }, [fullList]);

  useEffect(() => {
    if (isDiagSoft && !userSubs?.find((x: any) => x.subType === 'diagnostic')) {
      history.push(`/${client}`);
    }
  }, [userSubs, isDiagSoft, history, client]);

  useEffect(() => {
    reset();
  }, [catName, reset]);

  useEffect(() => {
    setResults(fullList);
  }, [fullList]);

  const doSearch = useCallback(
    async (data) => {
      if (data.vin) {
        const q = {
          vin: data.vin,
          assetcategoryid: cat?.assetcategoryid || data.assetcategoryid,
        };
        setData(q as any);
        const r = await vinSearch(q);
        setResults(r);
      } else {
        const results = await search({ ...data });
        console.log('doSearch ::::', { data, results });
        setResults(results);
      }
    },
    [search, vinSearch, cat]
  );

  useEffect(() => {
    doSearch(data);
    /* eslint-disable-next-line */
  }, [languageId]);

  const setDataProxy = (newData: State) => {
    setData(newData);
    doSearch(newData);
  };

  // console.log(
  //   'data ::::',
  //   data?.modelid,
  //   modelYear?.filter(
  //     ({ modelid }) => parseInt(modelid) === parseInt(data?.modelid?.toString())
  //   )
  // );

  const dbFilter = useMemo(
    () =>
      debounce((e: string | undefined) => {
        if (e) {
          setFiltered(
            results?.filter((x) => {
              return e.split(' ').every((y) => {
                const a = !Number.isNaN(parseInt(y))
                  ? parseInt(x.assetid.toString()) === parseInt(y)
                  : false;
                const b = x.description.toLowerCase().includes(y.toLowerCase());
                return a || b;
              });
            })
          );
        } else {
          setFiltered(undefined);
        }
      }, 750),
    [results]
  );

  const download = async () => {
    const HEADER_ROW = [
      {
        value: 'assetType',
        fontWeight: 'bold',
      },
      {
        value: 'assetid',
        fontWeight: 'bold',
      },
      {
        value: 'category',
        fontWeight: 'bold',
      },
      {
        value: 'date',
        fontWeight: 'bold',
      },
      {
        value: 'description',
        fontWeight: 'bold',
      },
      {
        value: 'model',
        fontWeight: 'bold',
      },
      {
        value: 'modelFamily',
        fontWeight: 'bold',
      },
      {
        value: 'modelSeries',
        fontWeight: 'bold',
      },
      {
        value: 'year',
        fontWeight: 'bold',
      },
    ];
    const data =
      (filtered || results)?.map((rec) => {
        const {
          assetType,
          assetid,
          category,
          date,
          description,
          modelCode,
          modelFamilyDescription,
          modelSeriesDescription,
          year,
        } = rec;
        return [
          { type: String, value: assetType },
          { type: Number, value: assetid },
          { type: String, value: category },
          { type: Date, value: new Date(date), format: 'mm/dd/yyyy' },
          { type: String, value: description },
          { type: String, value: modelCode },
          { type: String, value: modelFamilyDescription },
          { type: String, value: modelSeriesDescription },
          { type: String, value: year },
        ];
      }) || [];
    const dload: any = [HEADER_ROW, ...data];
    const fname = new Date().toISOString();
    await writeXlsxFile(dload, {
      // columns, // (optional) column widths, etc.
      fileName: `assets_${fname}.xlsx`,
    });
  };

  if (!cat && !assetOnboard)
    return <div className="w-100 pt-20 text-center">{messages.noCategory}</div>;

  return (
    <div
      className="flex items-center flex-col text-white"
      style={{
        height: '100%',
        // minHeight: '1000px',
        marginBottom: '1.4em',
      }}
    >
      {!assetOnboard && (
        <Helmet>
          <title>{cat?.assetcategorydescription || heading}</title>
        </Helmet>
      )}
      <main
        className="w-full text-black pt-4 flex flex-col"
        style={{ height: '100%' }}
      >
        <div style={{ margin: '0 2em 1em 2em' }}>
          <S.H1 className="text-2xl text-bold">
            {cat?.assetcategorydescription || heading}
          </S.H1>
          <S.H4 className="text-sm">{messages.assetSearchH2}</S.H4>

          <div className="inline flex-wrap xl:flex justify-between">
            <div
              style={{ flexGrow: 1, gap: '12px' }}
              className="xl:mr-6 search__input-box"
            >
              <TextInput2
                style={{ height: '42px' }}
                value={data?.vin}
                label={messages.enterVin}
                example={messages.enterVin}
                onChange={(e) => setDataProxy({ ...data, vin: e } as State)}
              />
            </div>
            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={modelFamily}
                value={data?.modelfamilyid || vinResults?.modelfamilyid}
                onChange={async (e) =>
                  setDataProxy({
                    ...data,
                    modelfamilyid: parseInt(e.target.value),
                  } as State)
                }
                idKey="modelfamilyid"
                valueKey="modelfamilydescription"
                placeholder={messages.modelFamily}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>
            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={modelSeries?.filter(
                  ({ modelfamilyid }) =>
                    modelfamilyid === data?.modelfamilyid ||
                    modelfamilyid === vinResults?.modelfamilyid
                )}
                value={data?.modelseriesid || vinResults?.modelseriesid}
                onChange={async (e) =>
                  setDataProxy({
                    ...data,
                    modelseriesid: parseInt(e.target.value),
                  } as State)
                }
                idKey="modelseriesid"
                valueKey="modelseriesdescription"
                placeholder={messages.modelSeries}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>
            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={models?.filter(
                  ({ modelseriesid }) =>
                    modelseriesid === data.modelseriesid ||
                    modelseriesid === vinResults?.modelseriesid
                )}
                value={data?.modelid || vinResults?.modelid}
                onChange={async (e) =>
                  setDataProxy({
                    ...data,
                    modelid: parseInt(e.target.value),
                  } as State)
                }
                idKey="modelid"
                valueKey="modeldescription"
                placeholder={messages.model}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>
            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={modelYear?.filter(
                  ({ modelid }) =>
                    parseInt(modelid) === parseInt(data?.modelid?.toString()) ||
                    modelid === vinResults?.modelid
                )}
                value={data?.modelyearid || vinResults?.modelyearid}
                onChange={async (e) =>
                  setDataProxy({
                    ...data,
                    modelyearid: parseInt(e.target.value),
                  } as State)
                }
                idKey="modelyearid"
                valueKey="modelyeardescription"
                placeholder={messages.modelYear}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>
            {isVinSearch && (
              <div
                style={{ flexGrow: 1, maxWidth: 300 }}
                className="xl:mr-6 search__input-box"
              >
                <Select
                  data={assetCategories
                    .filter((x) => !x?.assetcategorydescription.includes('->'))
                    .filter((x) => [4, 5, 6, 7].includes(x?.assetcategoryid))}
                  value={data?.assetcategoryid}
                  onChange={async (e) =>
                    setDataProxy({
                      ...data,
                      assetcategoryid: parseInt(e.target.value),
                    } as State)
                  }
                  idKey="assetcategoryid"
                  valueKey="assetcategorydescription"
                  placeholder={messages.assetCategory}
                  withNullOption
                  containerStyle={{ width: '100%', marginLeft: '0' }}
                />
              </div>
            )}
            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <TextInput2
                style={{ height: '42px', maxWidth: 450 }}
                value={filter}
                label={messages.search}
                example={messages.search}
                onChange={(e) => {
                  setFilter(e);
                  dbFilter(e);
                }}
              />
            </div>
          </div>
        </div>
        {assetOnboard && (
          <div
            style={{
              margin: '-1em 0 1rem 2rem',
              maxWidth: '600px',
              display: 'flex',
            }}
          >
            <Select
              data={assetTypes}
              value={data?.assettypeid}
              onChange={async (e) =>
                setDataProxy({
                  ...data,
                  assettypeid: parseInt(e.target.value),
                } as State)
              }
              idKey="assettypeid"
              valueKey="assettypedescription"
              placeholder={messages.assetType}
              withNullOption
              containerStyle={{ width: '100%', marginLeft: '0' }}
            />
            <span style={{ display: 'inline-block', width: '3rem' }}></span>
            <Select
              data={assetCategories?.filter(
                (x) => !x?.assetcategorydescription.includes('->')
              )}
              value={data?.assetcategoryid}
              onChange={async (e) =>
                setDataProxy({
                  ...data,
                  assetcategoryid: parseInt(e.target.value),
                } as State)
              }
              idKey="assetcategoryid"
              valueKey="assetcategorydescription"
              placeholder={messages.assetCategory}
              withNullOption
              containerStyle={{ width: '100%', marginLeft: '0' }}
            />
          </div>
        )}
        <div style={{ marginLeft: '2em' }}>
          <div className="flex justify-start mb-1">
            {hasNewButton && !hasClientAdmin && (
              <PrimaryButton
                onClick={() => history.push('/assets/edit')}
                width="115px"
                margin="0 28px 0 0"
              >
                {messages.new}
              </PrimaryButton>
            )}
            <PrimaryButton onClick={reset} width="115px" margin="0 28px 0 0">
              Reset
            </PrimaryButton>
            {canDownloadAssets && (
              <PrimaryButton onClick={download} width="115px">
                Download
              </PrimaryButton>
            )}
          </div>
        </div>

        {status === 'loading' ? (
          <div className="w-100 text-center p-10 text-black">
            {messages.loading}...
          </div>
        ) : null}
        <>
          <ResultsTable
            assetOnboard={assetOnboard}
            type={cat?.assetcategorydescription}
            results={filtered || results}
            fullList={fullList}
            searchData={searchData}
          >
            {children}
          </ResultsTable>
          {/* {results.length === 0 && (
              <div className="w-100 text-center p-4 text-black">
                {messages.noResults}
              </div>
            )} */}
        </>
      </main>
    </div>
  );
}
