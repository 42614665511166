import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import secondsToHours from 'date-fns/secondsToHours';
import millisecondsToSeconds from 'date-fns/millisecondsToSeconds';
import hoursToSeconds from 'date-fns/hoursToSeconds';
import secondsToMinutes from 'date-fns/secondsToMinutes';
import minutesToSeconds from 'date-fns/minutesToSeconds';
import { formatDate } from 'utils/functions';

const Span = styled.span<any>`
  color: ${(props) => (!props.$hasValidToDate ? '#94a3b8' : '#000')};
`;

function CountDown({ validToDate, messages, label, asText = false }) {
  const intervalRef = useRef<any>();
  const [remainingTime, setRemainingTime] = useState<string>('');
  const [remainingTimeNoSeconds, setRemainingTimeNoSeconds] =
    useState<string>('');

  const getUTCDate = (date?: any) => {
    const dateToConvert = date ? new Date(date) : new Date();
    const utcDate = Date.UTC(
      dateToConvert.getUTCFullYear(),
      dateToConvert.getUTCMonth(),
      dateToConvert.getUTCDate(),
      dateToConvert.getUTCHours(),
      dateToConvert.getUTCMinutes(),
      dateToConvert.getUTCSeconds()
    );
    return new Date(utcDate);
  };

  const updateRemainingTime = useCallback(() => {
    if (!validToDate) {
      setRemainingTime(messages.notValidTo);
      return;
    }

    const expiryDate = getUTCDate(validToDate);
    const currentTime = getUTCDate();

    if (currentTime > expiryDate) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      setRemainingTime(messages.expired);
      return;
    }

    const millis = expiryDate.getTime() - currentTime.getTime();
    const totalSeconds = millisecondsToSeconds(millis);

    const rDays = Math.floor(totalSeconds / (3600 * 24));
    const rDiff1 = totalSeconds - rDays * (3600 * 24);

    const rHours = secondsToHours(rDiff1);
    const rDiff2 = rDiff1 - hoursToSeconds(rHours);

    const rMinutes = secondsToMinutes(rDiff2);
    const rSeconds = rDiff2 - minutesToSeconds(rMinutes);

    setRemainingTime(
      `${rDays} days ${rHours} hours ${rMinutes} mins ${rSeconds} secs`
    );
    setRemainingTimeNoSeconds(`${rDays} days ${rHours} hours ${rMinutes} mins`);
  }, [messages.expired, messages.notValidTo, validToDate]);

  useEffect(() => {
    updateRemainingTime();

    const id = setInterval(() => {
      updateRemainingTime();
    }, 1000);
    intervalRef.current = id;

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      setRemainingTime(messages.remainingTime);
    };
  }, [messages.remainingTime, updateRemainingTime]);

  if (asText) {
    return <span>{remainingTimeNoSeconds}</span>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '8px',
        width: '480px',
      }}
    >
      <div className="flex flex-col justify-start">
        <label>{label}</label>
      </div>
      <div
        className="relative bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 bg-down-arrow bg-no-repeat bg-bottom-4"
        style={{ height: 34, width: 300 }}
      >
        <Span $hasValidToDate={validToDate}>{remainingTime}</Span>
      </div>
    </div>
  );
}

export default CountDown;
