import { memo } from 'react';
import { isEmpty } from 'lodash';

import * as S from './styled';

function LaunchContent({ launchContent }: { launchContent: string }) {
  const defaultContent = `
  <article role="article">
    <div>
      <div>
        <section class="body-content d-flex flex-column">
          <div class="home-page" >
            <p>Welcome to the Right-2-Repair.</p>
            <br/>
            <p>The motor vehicle service and repair information sharing scheme (the scheme) will promote competition in the Australian automotive sector and establish a level playing field for all repairers. It will allow consumers to have greater choice of repairers who are able to safely and effectively repair their vehicles. </p>
            <br/>
	          <p>The scheme will require data providers (usually car manufacturers) to make motor vehicle service and repair information available for purchase at a fair market price. It will include passenger and light goods vehicles (this includes cars, vans and utility vehicles) manufactured from 2002 onwards.</p>
            <br/>
	          <p>	The scheme commenced on 1 July 2022.</p>
            <br/>
	          <h3><strong>Why is the scheme required?</strong></h3>
	          <p>Motor vehicle servicing and repair is a $23 billion industry in Australia, with nearly 35,000 businesses employing over 106,000 Australians. Around one in ten motor vehicles taken to repair workshops are affected by lack of access to service and repair information. When this is the case, it results in higher service costs for consumers. </p>
            <br/>
	          <p>Following consultation with industry, the Government committed to implementing a mandatory scheme in primary legislation as a new part in the Consumer and Competition Act 2010.</p>
            <br/>
		        <h3><strong>Who can access scheme information?</strong></h3>
		        <p>The scheme requires data providers to make service and repair information available to purchase by all repairers as well as registered training organisations (RTOs) providing motor vehicle training in Australia. The scheme includes requirements to help ensure information is accessible and affordable.</p>
            <br/>
		        <p>Information related to safety and security will only be available to individuals that meet specified access criteria. This will protect consumers, repairers, and the general public by ensuring these repairs are only performed by appropriately qualified individuals. A list of safety and security information and its access criteria will be set out by the Minister in scheme rules to be developed in close consultation with industry.</p>
            <br/>
		        <p><strong>Industry cooperation will be crucial for success </strong></p>
		        <p>The scheme creates a statutory scheme adviser who has a key role in supporting the day-to-day operation of the scheme.</p>
            <br/>
		        <p>It is the Government’s intention to confer this role on a joint industry-led organisation to assist with the mediation of disputes and report on the operation of the scheme. This will help ensure manufacturers, repairers, RTOs, and consumers have a voice in helping to keep the scheme effective and relevant.</p>
          </div>
        </section>
    </div>
  </div>
</article>
`;
  const contentMarkup = () => {
    return { __html: !isEmpty(launchContent) ? launchContent : defaultContent };
  };

  return (
    <S.Container>
      <div dangerouslySetInnerHTML={contentMarkup()}></div>
    </S.Container>
  );
}

export default memo(LaunchContent);
