import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';

export const Link = styled(RRLink)<any>`
  color: ${(props) =>
    props.$client === 'mmal'
      ? '#707070'
      : props.$data?.fontcolor || 'darkblue'};
  :hover {
    color: ${(props) => props.$data?.primarycolour || '#e90000'};
  }
`;
