import { ChangeEventHandler, useCallback } from 'react';

import { useLanguages } from 'utils/hooks';
import styled from 'styled-components';

type Props = {
  data: any;
  idKey: string;
  valueKey: string;
  value: any;
  placeholder?: string;
  example?: string;
  onChange?: Function;
  disabled?: boolean;
  withNullOption?: boolean;
  containerStyle?: any;
  id?: string;
};
const SelectLabel = styled.div`
  font-size: 14px;
`;

function Select({
  data,
  idKey,
  valueKey,
  value,
  onChange,
  placeholder,
  example,
  disabled,
  withNullOption,
  containerStyle,
  id,
}: Props) {
  const { messages } = useLanguages();
  return (
    <div className="ml-8 w-40" style={{ width: '15rem', ...containerStyle }}>
      <SelectLabel className="ml-2 mt-2 text-gray-400 uppercase">
        {placeholder}
      </SelectLabel>
      <select
        value={value}
        onChange={onChange as ChangeEventHandler<HTMLSelectElement>}
        title={placeholder}
        disabled={disabled}
        className="relative pt-2 bg-gray-100 outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 bg-no-repeat bg-bottom-4 w-full"
        style={{ height: '42px' }}
        id={id}
      >
        {withNullOption && (
          <option value="-1">{example ? example : messages.selectDots}</option>
        )}
        {data &&
          data?.map?.((row, index) => {
            return (
              <option key={index} value={row[idKey]}>
                {row[valueKey]}
              </option>
            );
          })}
      </select>
    </div>
  );
}

export default Select;
