import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLock, faMobileAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import * as S from '../styled';
import isIE from 'utils/isIE';
import authyImg from 'assets/Authy128X128.png';

const iconStore = {
  lock: faLock,
  mobile: faMobileAlt,
  user: faUser,
};

type Props = {
  iconName: string;
  id?: string;
  onChange?: Function;
  name?: string;
  password?: boolean;
  placeholder?: string;
  value: string;
  autoFocus?: boolean;
  onBlur?: Function;
  disabled?: boolean;
};

function InputGroup({
  disabled,
  id,
  onChange,
  value,
  iconName,
  name,
  password,
  placeholder,
  autoFocus,
  onBlur,
}: Props) {
  return (
    <>
      {isIE() ? (
        <S.InputGroupIE>
          <S.Input
            autoFocus={autoFocus}
            containerStyle={{
              heigth: '100%',
              marginTop: '-8px',
              width: '100%',
            }}
            id1={id}
            disabled={disabled}
            example={placeholder}
            name={name}
            password={password}
            onChange={onChange}
            style={{
              height: '100%',
              padding: '1em',
              marginBottom: '1em',
            }}
            value={value}
            onBlur={onBlur}
          />
        </S.InputGroupIE>
      ) : (
        <S.InputGroup>
          <div
            className="flex items-center relative bg-gray-100 appearance-none
        outline-none border-b-2 border-t-0 border-l-0 border-r-2 border-select-1
        mt-2 p-4 bg-down-arrow bg-no-repeat bg-bottom-4 h-full"
            style={{ width: '4rem !important' }}
          >
            {iconName === 'authy' ? (
              <img src={authyImg} style={{ height: 'auto', width: '50px' }} />
            ) : (
              <FontAwesomeIcon
                icon={iconStore[iconName] as IconProp}
                size="lg"
              />
            )}
          </div>
          <S.Input
            autoFocus={autoFocus}
            containerStyle={{
              height: '100%',
              marginTop: '-8px',
              width: '100%',
            }}
            id1={id}
            disabled={disabled}
            example={placeholder}
            height="100%"
            name={name}
            password={password}
            onChange={onChange}
            style={{ height: '100%', padding: '1em', width: '100%' }}
            value={value}
            onBlur={onBlur}
          />
        </S.InputGroup>
      )}
    </>
  );
}
export default InputGroup;
