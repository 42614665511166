import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from 'components/App/Context';
import { blobToBase64 } from 'utils/functions';
import { useSupabase } from 'utils/supabase';
import { useConfig } from 'utils/hooks';

function SubaruLaunchContent() {
  const { downloadFile } = useSupabase();
  const { defaults } = useConfig();
  const { openFooterModal } = useContext(AppContext);

  const openPdfModal = async (type: string) => {
    let pdfStr;
    try {
      const data = await downloadFile(defaults?.[type], defaults.oemid);
      pdfStr = await blobToBase64(data?.data);
    } catch (error) {
      return;
    }
    openFooterModal(type, pdfStr);
  };

  return (
    <article role="article" style={{ padding: '2em' }}>
      <div>
        <div>
          <section className="body-content d-flex flex-column">
            <div className="home-page">
              <h3 style={{ fontWeight: 'bold' }}>Background</h3>
              <p>
                Subaru (Aust) Pty Limited (Subaru Australia) is the exclusive
                importer and distributor of new Subaru branded motor vehicles in
                Australia. &nbsp;As such, Subaru Australia is deemed to be a
                data provider for the purposes of the Motor Vehicle Information
                Scheme (MVIS) which is effective as of 1 July 2022.&nbsp;
              </p>
              <p>
                Subaru Australia primarily seeks to meet its obligations under
                the MVIS via this website.
              </p>
              <br />
              <h3 style={{ fontWeight: 'bold' }}>
                {' '}
                What is the motor vehicle information scheme (MVIS)?
              </h3>
              <p>
                <span>
                  The Motor Vehicle Information Scheme (MVIS) is a scheme set up
                  under the&nbsp;
                </span>
                <em>
                  Competition and Consumer Amendment (Motor Vehicle Service and
                  Repair Information Sharing Scheme) Act 2021
                </em>
                <span>
                  , pursuant to which motor vehicle manufacturers are required
                  to make certain repair and service information available to
                  certain persons at a fair market price.
                </span>
              </p>
              <p>
                As the importer and distributor of Subaru branded motor vehicles
                in Australia, Subaru Australia is subject to the MVIS which is
                intended to promote competition in the Australian automotive
                sector and establish a level playing field for all repairers.
                &nbsp;Subaru Australia is committed to meeting its obligations
                under the MVIS, primarily via this website.
              </p>
              <p>
                You can find out more about the MVIS by visiting{' '}
                <a href="https://www.accc.gov.au/focus-areas/motor-vehicle-information-scheme-mvis">
                  https://www.accc.gov.au/focus-areas/motor-vehicle-information-scheme-mvis
                </a>
              </p>
              <br />
              <h3 style={{ fontWeight: 'bold' }}>
                What service and repair information is available on this
                website?
              </h3>
              <p>
                On this website you can access the following information for
                Subaru motor vehicles from Model Year 2002 to the latest model
                year:
              </p>
              <p>
                <strong>
                  <span>
                    Service Manuals (including wiring diagrams, technical
                    specifications for components and lubricants and testing
                    procedures, information and codes for computerised systems,
                    information such as the steps involved in doing a scheduled
                    service or technical specifications for components and
                    lubricants and testing procedures, even where it is
                    contained in a log book), Body Repair Manuals, Technical
                    Service Bulletins, Recall Campaigns Bulletins, Software
                    updates and Key codes.
                  </span>
                </strong>
              </p>
              <br />
              <h3 style={{ fontWeight: 'bold' }}>
                How can I access Subaru service and repair information?
              </h3>
              <p>
                For Model Year 2002 and beyond, you can instantly access service
                and repair information by &ldquo;subscribing&rdquo; to the
                relevant information via this website. &nbsp; Subscriptions can
                be purchased for 1 day, 1 month or 12 months. &nbsp;
              </p>
              <p>
                Under the MVIS, Subaru Australia is entitled to charge a fair
                market price for access to the information.
              </p>
              <br />
              <h3 style={{ fontWeight: 'bold' }}>
                Safety and Security Information and Fit and Proper Person test
              </h3>
              <p>
                The MVIS requires Subaru Australia to make certain security and
                safety related repair and service information available only to
                &ldquo;fit and proper persons&rdquo; (as prescribed by the MVIS
                rules). &nbsp;As such, where you subscribe for access to such
                information you will be required to provide certain additional
                information in order to allow Subaru Australia to make this
                assessment. &nbsp;Subaru Australia will be unable to provide
                access to the information in the event you cannot provide the
                additional information or are assessed not to be a fit and
                proper person.
              </p>
              <p>
                In order to obtain a fit and proper person check, you will need
                to create an AASRA account through the AASRA website (
                <a href="https://aasra.com.au/">https://aasra.com.au/</a>) using
                the same email address that you will/have created an account for
                on our page.
              </p>
              <br />
              <h3 style={{ fontWeight: 'bold' }}>
                {' '}
                What other terms and conditions apply to my access of the
                service and repair information?
              </h3>
              <p>
                Your access to the service and repair information provided via
                this website is subject to{' '}
                <strong>
                  Subaru Australia&rsquo;s Motor Vehicle Information Scheme
                  Platform terms and conditions&nbsp;
                </strong>
                which you can access{' '}
                <u>
                  <Link
                    style={{ color: 'blue' }}
                    id="redirectLink"
                    onClick={() => openPdfModal('Terms And Conditions')}
                    to="#"
                  >
                    <span>
                      <strong>here</strong>
                    </span>
                  </Link>
                </u>
                . &nbsp;
                <span style={{ fontSize: '11px', lineHeight: '107%' }}></span>By
                subscribing to access any Subaru service and repair information
                via this website, you will be deemed to have read and accepted
                the terms and conditions, and that you have agreed to abide by
                them.
              </p>
              <p>In particular you should note that:</p>
              <br />
              <ol style={{ listStyleType: 'decimal', marginLeft: '3em' }}>
                <li>
                  The service and repair information has been drafted for use
                  solely by suitably trained and qualified technicians.
                  &nbsp;You should not proceed to attempt a repair or service if
                  you do not understand any part of the information and/or do
                  not have suitable tools.
                </li>
                <li>
                  You should carefully consider and abide by all warnings and
                  cautions contained within the repair and service information.
                </li>
                <li>
                  Copyright in the majority of the service and repair
                  information is owned by Subaru Corporation, Japan. &nbsp;Your
                  access to the information is intended purely to repair or
                  service a relevant Subaru motor vehicle and for no other
                  purpose. &nbsp;Copying or reproducing the information without
                  consent is prohibited and a breach of the law.
                </li>
                <li>
                  Repair and service information contained on this website may
                  be updated from time to time. &nbsp;You should always
                  subscribe for access to the latest repair and service
                  information when servicing or repairing a Subaru motor
                  vehicle.&nbsp;
                </li>
                <li>
                  If you are a motor mechanic accessing service information for
                  the purposes of servicing a Subaru motor vehicle for your
                  customer, you agree to sign, date, and stamp the service and
                  warranty handbook (where indicated) that is located with
                  Owner&rsquo;s Manual wallet.
                </li>
              </ol>

              <br />
              <h3 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Pricing (inclusive of GST)
              </h3>
              <h3 style={{ fontWeight: 'bold' }}>
                Service and Repair Information Access Fees
              </h3>
              <img src="/SubaruSubOptions.png" style={{ width: '80rem' }} />
              {/* <table
                style={{
                  borderCollapse: 'collapse',
                  border: 'none',
                  marginBottom: '1.2em',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        <strong>
                          <span>Subscription Duration</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        <strong>
                          <span>Cost in AUD</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>1 Day</p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 35.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>1 Month</p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 249.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid  #0004F1',
                        borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>1 Year</p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1,949.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <p style={{ marginBottom: '1rem' }}>
                <b>Key codes</b>: $55 per key code.
              </p>
              <h3 style={{ fontWeight: 'bold' }}>
                Diagnostic Software- SSM Fees
              </h3>

              <img src="/SubaruDSOptions.png" style={{ width: '80rem' }} />
              {/* <table
                style={{
                  borderCollapse: 'collapse',
                  border: 'none',
                  marginBottom: '1.2em',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        <strong>
                          <span>Subscription Duration</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        <strong>
                          <span>Cost in AUD</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>1 Day</p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 35.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>1 Month</p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 299.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>1 Year</p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                        // borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2,499.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        // borderTop: 0,
                        borderLeft: '4px solid  #0004F1',
                        borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        Diagnostic Hardware (DST- 010 / DSTI)
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        // borderTop: 0,
                        borderLeft: '4px solid #0004F1',
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1,138.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <p>
                A Subaru diagnostic subscription allows the user to download the
                diagnostic software. Refer to{' '}
                <a
                  style={{ color: 'blue', fontWeight: 'bold' }}
                  target="_blank"
                  href="/Subaru R2R Requirement & Disclaimer document 2024.pdf"
                >
                  this document
                </a>{' '}
                for PC / laptop and supported operating system requirements.
              </p>
              <br />
              <br />

              {/* <h3 style={{ fontWeight: 'bold' }}>Software Update</h3>
              <p style={{ marginBottom: '1rem' }}>
                Subaru Australia provides software updates for Subaru vehicles
                via remote assistance. Please select the appropriate service
                during the sign up or log in process (after your AASRA
                authentication) and submit your order, once your order has been
                submitted you will be contacted via the email address associated
                with your account to outline the process and book a time for the
                remote assistance install. Please allow up to 10 working days
                for the first time as you will need the hardware which will be
                couriered to you. For all other service’s please allow 2 working
                days. Service and pricing information: Software Updates (Sold on
                Annual Basis) all prices include GST.
              </p>
              <ul>
                <li style={{ marginLeft: '1em', listStyleType: 'square' }}>
                  Year 1 (12 months) Includes interface hardware, Licence fees
                  and 1 remote install software update.
                  <ul>
                    <li style={{ marginLeft: '2em', listStyleType: 'circle' }}>
                      A$2607
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li style={{ marginLeft: '1em', listStyleType: 'square' }}>
                  Year 2 and each year after Includes licence fees and 1 remote
                  install software update.
                  <ul>
                    <li style={{ marginLeft: '2em', listStyleType: 'circle' }}>
                      A$1122
                    </li>
                  </ul>
                </li>
              </ul>
              <ul style={{ marginBottom: '1rem' }}>
                <li style={{ marginLeft: '1em', listStyleType: 'square' }}>
                  Additional remote install software updates.
                  <ul>
                    <li style={{ marginLeft: '2em', listStyleType: 'circle' }}>
                      A$198
                    </li>
                  </ul>
                </li>
              </ul> */}
              <h3 style={{ fontWeight: 'bold' }}>Contact Us</h3>
              <p>
                If you have any questions regarding this website or your
                subscription, email us at{' '}
                <strong>help.subaru@right-2-repair.com.au.</strong>
              </p>
            </div>
          </section>
        </div>
      </div>
    </article>
  );
}

export default SubaruLaunchContent;
