import { useEffect, useState } from 'react';
import styled from 'styled-components';

import version from 'version';
import { supabase } from 'utils/supabase';

// const Backdrop = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: 9999;
//   background-color: #ccc;
//   opacity: 0.5;
// `;

const VersionUpdate = styled.div`
  width: 500px;
  padding: 1.5rem;
  background-color: #4f79d8;
  color: white;
  text-align: center;
  position: fixed;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 4rem;
  z-index: 99999;
  font-size: 1rem;

  button {
    text-decoration: underline;
  }
`;

function VersionCheck() {
  const [newVersion, setNewVersion] = useState<Boolean>(false);
  const sb = supabase();

  useEffect(() => {
    const f = async () => {
      const r = await sb
        .from('tb_feature_flags')
        .select('value')
        .eq('name', 'version');
      if (r?.data?.[0]?.value !== version) {
        setNewVersion(true);
      }
    };
    f();
    const t = setInterval(f, 1000 * 60 * 10);
    return () => {
      clearInterval(t);
    };
  }, [sb]);

  const refresh = () => {
    window.location.reload();
  };

  if (newVersion) {
    return (
      <>
        {/* <Backdrop /> */}
        <VersionUpdate>
          A new version is available, to update please refresh the page, or
          &nbsp;<button onClick={refresh}>click here to refresh</button>
        </VersionUpdate>
      </>
    );
  }

  return null;
}

export default VersionCheck;
