import React from 'react';

type T = {
  setLocale: Function;
  locale: string;
  messages: any;
  locales: any;
  languageId: number;
};

export const LanguageContext = React.createContext<T>({
  locale: '',
  messages: {},
  setLocale: () => {},
  locales: [],
  languageId: 0,
});
