import { atom } from 'recoil';

import { ModelFamily, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: ModelFamily[];
  ids?: ModelFamily[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const ModelFamilyState = atom({
  key: 'ModelFamily',
  default: initialState,
});

export function useModelFamily() {
  const xport = useGeneric(
    ModelFamilyState,
    'tb_modelfamily',
    'modelfamily',
    (data: ModelFamily, idData: ModelFamily) => {
      delete data.brandid;
      delete data.divisionid;
      // delete data.oemid;
    },
    (data: ModelFamily[], iData: ModelFamily[]) => {
      return data.map((d) => {
        const i = iData?.find(
          ({ modelfamilyid }) => modelfamilyid === d.modelfamilyid
        );
        return {
          ...d,
          oemid: parseInt(i?.oemid?.toString() || '0'),
          divisionid: i?.divisionid,
          brandid: i?.brandid,
        };
      });
    }
  );

  return xport;
}
