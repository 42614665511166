import { useState, useEffect, useCallback, useContext } from 'react';

import { APIContext } from './Context';

import { useModelFamily } from 'features/modelFamily/hooks';
import { useModelSeries } from 'features/modelSeries/hooks';
import { useModel } from 'features/model/hooks';
import { useModelYear } from 'features/modelYear/hooks';
import { useOEM } from 'features/OEM/hooks';
import { useDivisions } from 'features/divisions/hooks';
import { useBrands } from 'features/brand/hooks';
import { useRepairGroup } from 'features/repairGroup/hooks';
import { useRepairSubGroup } from 'features/repairSubGroup/hooks';
import { useRepairComponent } from 'features/repaircomponent/hooks';
import { useAASRAProfiles } from 'features/aasraProfile/hooks';
import { useAssetCategories } from 'features/assets/hooks';
import { useAssetTypes } from 'features/assetsType/hooks';

import {
  useAssets,
  useAssetsCats,
  useAssetsDescription,
  useAssetsModelFamily,
  useAssetsModelSeries,
  useAssetsModel,
  useAssetsModelYear,
  useAssetsLocation,
  useAssetsOEM,
  useAssetsBrand,
  useAssetsDivision,
  useAssetsRepairGroup,
  useAssetsRepairSubGroup,
  useAssetsRepairComponent,
  useAssetsAASRAProfile,
  useAssetsType,
} from 'features/assets/hooks';
import * as Access from 'features/assets/accessHooks';
import { useLoggedIn, useConfig } from 'utils/hooks';
import isIE from 'utils/isIE';
import { AppContext } from 'components/App/Context';

let count1 = 0;
let count2 = 0;

let _done = {};
let _x = {};

export default function APIProvider({ children }): JSX.Element {
  const { oem } = useContext(AppContext);
  const [incr, setIncr] = useState<number>(0);
  const { defaults } = useConfig();
  const [done, setDone] = useState<any>({});
  // const { user } = useLoggedIn();

  // const { reset: r1 } = useAssetCategories();
  // const { reset: r2 } = useModelFamily();
  // const { reset: r3 } = useModelSeries();
  // const { reset: r4 } = useModel();
  // const { reset: r5 } = useModelYear();
  // const { reset: r6 } = useOEM();
  // const { reset: r7 } = useDivisions();
  // const { reset: r8 } = useBrands();
  // const { reset: r9 } = useRepairGroup();
  // const { reset: r10 } = useRepairSubGroup();
  // const { reset: r11 } = useRepairComponent();
  // const { reset: r12 } = useAASRAProfiles();

  const { fetchData: n1 } = useAssets();
  const { fetchData: n2 } = useAssetsCats();
  const { fetchData: n3 } = useAssetsDescription();
  const { fetchData: n4 } = useAssetsModelFamily();
  const { fetchData: n5 } = useAssetsModelSeries();
  const { fetchData: n6 } = useAssetsModel();
  const { fetchData: n7 } = useAssetsModelYear();
  // const { fetchData: n8 } = useAssetsLocation();
  const { fetchData: n9 } = useAssetsOEM();
  const { fetchData: n10 } = useAssetsDivision();
  const { fetchData: n11 } = useAssetsBrand();
  const { fetchData: n12 } = useAssetsRepairGroup();
  const { fetchData: n13 } = useAssetsRepairSubGroup();
  const { fetchData: n14 } = useAssetsRepairComponent();
  const { fetchData: n15 } = useAssetsAASRAProfile();
  const { fetchData: n16 } = useAssetTypes();
  const { fetchData: n17 } = useAssetsType();

  const { fetchData: a1 } = Access.useAssets();
  const { fetchData: a2 } = Access.useAssetsCats();
  const { fetchData: a3 } = Access.useAssetsDescription();
  const { fetchData: a4 } = Access.useAssetsModelFamily();
  const { fetchData: a5 } = Access.useAssetsModelSeries();
  const { fetchData: a6 } = Access.useAssetsModel();
  const { fetchData: a7 } = Access.useAssetsModelYear();
  // const { fetchData: a8 } = Access.useAssetsLocation();
  const { fetchData: a9 } = Access.useAssetsOEM();
  const { fetchData: a10 } = Access.useAssetsDivision();
  const { fetchData: a11 } = Access.useAssetsBrand();
  const { fetchData: a12 } = Access.useAssetsRepairGroup();
  const { fetchData: a13 } = Access.useAssetsRepairSubGroup();
  const { fetchData: a14 } = Access.useAssetsRepairComponent();
  // const { fetchData: a15 } = Access.useAssetsAASRAProfile();

  const { fetchData: fd1 } = useAssetCategories();
  const { fetchData: fd2 } = useModelFamily();
  const { fetchData: fd3 } = useModelSeries();
  const { fetchData: fd4 } = useModel();
  const { fetchData: fd5 } = useModelYear();
  const { fetchData: fd6 } = useOEM();
  const { fetchData: fd7 } = useDivisions();
  const { fetchData: fd8 } = useBrands();
  const { fetchData: fd9 } = useRepairGroup();
  const { fetchData: fd10 } = useRepairSubGroup();
  const { fetchData: fd11 } = useRepairComponent();
  const { fetchData: fd12 } = useAASRAProfiles();

  const client = window.location.pathname.split('/')[1];

  // const start = useCallback(() => {
  //   setCount1(count1 + 1);
  // }, [count1]);

  // const finish = useCallback(() => {
  //   setCount2(count2 + 1);
  // }, [count2]);

  const start = () => count1++;
  const finish = () => {
    count2++;
    setIncr(count2);
  };

  useEffect(() => {
    let tout;
    if (count1 !== count2) {
      tout = setTimeout(() => {
        // setCount2(count1);
        count1 = count2;
      }, 2000);
    }
    return () => tout && clearTimeout(tout);
    /* eslint-disable-next-line */
  }, [incr]);

  const reset = () => {
    return new Promise((resolve) => {
      // [r1, r2, r3, r4, r5, r6, r7, r8, r9, r10, r11, r12].forEach((f: any) => f.call());
      // [n1, n2, n3, n4, n5, n6, n7, n8, n9, n10, n11, n12, n13, n14, n15].forEach((f: any) => f.call());
      resolve(true);
    });
  };

  const prefetch = useCallback(async () => {
    if (!oem) return;
    if (!defaults || !defaults.oemid) return;
    if (_done[defaults?.oemid]) return;
    _done[defaults?.oemid] = true;

    setTimeout(() => {
      _done[defaults?.oemid] = false;
    }, 10000);

    const fdProms = [
      fd1,
      fd2,
      fd3,
      fd4,
      // fd5,
      fd6,
      fd7,
      fd8,
      fd9,
      fd10,
      fd11,
      fd12,
    ];

    const nProms = [
      n1,
      n2,
      n3,
      n4,
      n5,
      n6,
      n7,
      // n8,
      n9,
      n10,
      n11,
      n12,
      n13,
      n14,
      n15,
      n16,
      n17,
    ];

    const aProms = [
      a1,
      a2,
      a3,
      a4,
      a5,
      a6,
      a7,
      // Promise.resolve,
      a9,
      a10,
      a11,
      a12,
      a13,
      a14,
    ];

    console.log('defaults :::::', defaults);
    console.log('PREFETCH HERE *****', window.sessionStorage.getItem('client'));

    if (isIE()) {
      const promAll = async (proms) => {
        for (let i = 0; i < proms.length; i++) {
          await proms[i]?.();
        }
      };
      await promAll(fdProms);
      await promAll(nProms);
      await promAll(aProms);
      _done = { f: true, n: true, a: true };
      _x[client] = { ..._done };
      setDone({ ..._done });
      return;
    }

    let f = false;
    let n = false;
    let a = false;
    // _x[client] = {};
    _x[client] = { f: true, n: true, a: true };
    _done[defaults?.oemid] = true;
    setDone({ f: true, n: true, a: true });

    // Promise.all(fdProms.map((p) => p()) as any).then(() => {
    //   _x[client].f = true;
    //   f = true;
    //   setDone({ f, n, a });
    //   console.log('prefetch done f', f, n, a);
    // });
    // Promise.all(nProms.map((p) => p()) as any).then(() => {
    //   _x[client].n = true;
    //   n = true;
    //   setDone({ f, n, a });
    //   console.log('prefetch done n', f, n, a);
    // });
    Promise.all(aProms.map((p) => p()) as any).then(() => {
      _x[client].a = true;
      a = true;
      setDone({ f, n, a });
      console.log('prefetch done a', f, n, a);
    });

    const xecute = async (proms, n) => {
      if (n === proms.length) return false;
      await proms[n]();
      await xecute(proms, n + 1);
      return true;
    };
    await xecute(fdProms, 0);
    await xecute(nProms, 0);
    // await xecute(aProms, 0);

    fd5().then(() => {});
  }, [
    fd1,
    fd2,
    fd3,
    fd4,
    fd5,
    fd6,
    fd7,
    fd8,
    fd9,
    fd10,
    fd11,
    fd12,
    n1,
    n2,
    n3,
    n4,
    n5,
    n6,
    n7,
    // n8,
    n9,
    n10,
    n11,
    n12,
    n13,
    n14,
    n15,
    n16,
    n17,
    a1,
    a2,
    a3,
    a4,
    a5,
    a6,
    a7,
    a9,
    a10,
    a11,
    a12,
    a13,
    a14,
    defaults,
    client,
    oem,
  ]);

  // console.log('count', incr, count1, count2);
  return (
    <APIContext.Provider
      value={{ inProgress: _x[client], start, finish, reset, prefetch }}
    >
      {children}
    </APIContext.Provider>
  );
}
