import { atom } from 'recoil';

import { Division, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: Division[];
  ids?: Division[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const DivisionsState = atom({
  key: 'Divisions',
  default: initialState,
});

export function useDivisions() {
  const xport = useGeneric(
    DivisionsState,
    'tb_division',
    'division',
    (data: Division, iData: Division) => {
      // delete data?.oemid;
    },
    (data: Division[], iData: Division[]) => {
      return data.map((d) => {
        const i = iData?.find(({ divisionid }) => divisionid === d.divisionid);
        return { ...d, oemid: parseInt(i?.oemid?.toString() || '0') };
      });
    }
  );

  return xport;
}
