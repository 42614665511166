import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'components/Theme/Context';
import styled from 'styled-components';

type Props = {
  value: any;
  label?: string;
  example?: string;
  onChange?: Function;
  style?: object;
  disabled?: boolean;
  password?: boolean;
  id1?: string;
  containerStyle?: any;
  rows?: number;
};

const TextAreaLabel = styled.div`
  font-size: 14px;
`;

function TextArea({
  value,
  label,
  example,
  onChange,
  style,
  disabled,
  password,
  id1,
  containerStyle,
  rows = 3,
}: Props) {
  const [id, setId] = useState<string>();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!id1) {
      import('uuid').then((uuid) => {
        setId(uuid.v1());
      });
    } else {
      setId(id1);
    }
  }, [id1]);

  return (
    <div style={{ ...containerStyle }}>
      <TextAreaLabel className="ml-8 mt-2 text-gray-400 uppercase">
        {label}
      </TextAreaLabel>
      {disabled && (
        <textarea
          className="relative bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-full bg-down-arrow bg-no-repeat bg-bottom-4"
          cols={50}
          rows={rows}
          id={id}
          placeholder={example || label}
          autoComplete={password ? 'password' : label?.split(' ').join('-')}
          value={value}
          style={style}
          disabled
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
        />
      )}

      {!disabled && (
        <textarea
          className="relative pt-2 bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-full bg-down-arrow bg-no-repeat bg-bottom-4"
          cols={53}
          rows={5}
          id={id}
          placeholder={example || label}
          value={value}
          style={style}
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
        />
      )}
    </div>
  );
}

export default TextArea;
