import { atom } from 'recoil';

import { OEM, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: OEM[];
  ids?: OEM[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const OEMsState = atom({
  key: 'OEMs',
  default: initialState,
});

export function useOEM() {
  const xport = useGeneric(OEMsState, 'tb_oem', 'oem');

  return xport;
}
