import { useEffect, useState } from 'react';
import * as S from './styled';
import { MyAccountData, Subscription } from 'types';
import Modal from 'components/Modal';
import InputGroup from './components/InputGroup';
import SwitchButton from 'components/SwitchButton';
import Countdown from './components/CountDown';
import { useGAPList, useOEMUserList } from 'features/page/hooks';
import { supabase as getSupabase } from 'utils/supabase';
import { useConfig, useLanguages, useLoggedIn } from 'utils/hooks';
import { formatDate, formatDateUTC } from 'utils/functions';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type inputState = {
  field: string;
  label: string;
  placeholder: string;
  value: string;
};

function MyAccountModal({
  close,
  data,
  fromTable,
}: {
  close: Function;
  data: MyAccountData;
  fromTable?: boolean;
}) {
  const { messages } = useLanguages();
  const { defaults } = useConfig();
  const userData = useOEMUserList();
  const { aasra } = useOEMUserList();
  const { data: gapData, list: fetchGaps } = useGAPList();
  const supabase = getSupabase();
  const [subscription, setSubscription] = useState<Subscription>();
  const [gap, setGap] = useState<any>();
  const [aasraRoles, setAasraRoles] = useState<string>('');
  const [gapSubsData, setGapSubsData] = useState<any>();
  const [myAccount, setAccount] = useState<inputState[]>([
    {
      field: 'firstname',
      label: messages.firstName,
      placeholder: messages.firstName,
      value: '',
    },
    {
      field: 'surname',
      label: messages.surname,
      placeholder: messages.surname,
      value: '',
    },
    {
      field: 'email',
      label: messages.email,
      placeholder: messages.email,
      value: '',
    },
    {
      field: 'mobilenumber',
      label: messages.phoneNumber,
      placeholder: messages.phoneNumber,
      value: '',
    },
    {
      field: 'address',
      label: messages.address,
      placeholder: messages.address,
      value: '',
    },
    { field: 'gap', label: messages.gap, placeholder: messages.gap, value: '' },
    {
      field: 'company',
      label: messages.company,
      placeholder: messages.company,
      value: '',
    },
    { field: 'abn', label: 'ABN', placeholder: 'Abn', value: '' },
    {
      field: 'aasraRoles',
      label: messages.aasraRoles,
      placeholder: messages.aasraRoles,
      value: '',
    },
    {
      field: 'subscription',
      label: messages.subscription,
      placeholder: messages.subscription,
      value: '',
    },
    {
      field: 'hasloggedin',
      label: messages.loggedin,
      placeholder: messages.loggedin,
      value: '',
    },
    {
      field: 'validtodate',
      label: messages.validToUtc,
      placeholder: messages.notValidTo,
      value: '',
    },
    {
      field: 'okta_firstName',
      label: messages.oktaFirstName,
      placeholder: messages.oktaFirstName,
      value: '',
    },
    {
      field: 'okta_lastName',
      label: messages.oktaLastName,
      placeholder: messages.oktaLastName,
      value: '',
    },
    {
      field: 'okta_login',
      label: messages.oktaLogin,
      placeholder: messages.oktaLogin,
      value: '',
    },
    {
      field: 'okta_email',
      label: messages.oktaEmail,
      placeholder: messages.oktaEmail,
      value: '',
    },
    {
      field: 'okta_subscription_end_date',
      label: messages.oktaEndDate,
      placeholder: messages.oktaEndDate,
      value: '',
    },
    {
      field: 'okta_countryCode',
      label: messages.oktaCountryCode,
      placeholder: messages.oktaCountryCode,
      value: '',
    },
    {
      field: 'okta_preferredLanguage',
      label: messages.oktaPreferLang,
      placeholder: messages.oktaPreferLang,
      value: '',
    },
    {
      field: 'okta_userType',
      label: messages.oktaUserType,
      placeholder: messages.oktaUserType,
      value: '',
    },
    {
      field: 'okta_employeeNumber',
      label: messages.oktaEmployeeNumber,
      placeholder: messages.oktaEmployeeNumber,
      value: '',
    },
    {
      field: 'okta_valid_until',
      label: messages.oktaEndDate,
      placeholder: messages.notValidTo,
      value: '',
    },
    {
      field: 'currentUtcTime',
      label: messages.currentUtcTime,
      placeholder: messages.currentUtcTime,
      value: '',
    },
    {
      field: 'remainingTime',
      label: messages.remainingTime,
      placeholder: messages.remainingTime,
      value: '',
    },
    {
      field: 'countdown',
      label: 'countdown test',
      placeholder: 'countdown test',
      value: '',
    },
  ]);
  const [showOkta, setShowOkta] = useState<boolean>(false);
  const client = window.location.pathname.split('/')[1];
  const { user } = useLoggedIn(true, true);

  /* if MyAccount not open from support tables need to fetch validtodate data */
  useEffect(() => {
    if (fromTable) return;
    const f = async () => {
      let res;
      if (defaults?.signuponlyindividual) {
        res = await supabase
          .from('tb_user_subscriptions')
          .select('*')
          .eq('userid', data?.email)
          .eq('subscriptionid', data?.currentsessionsubscriptionid);
      } else {
        res = await supabase
          .from('tb_gap_subscriptions')
          .select('*')
          .eq('userid', data?.email)
          .eq('gapid', data?.currentsessiongapid);
      }
      if (!res) return;
      setGapSubsData(res.data[0]);
    };
    f();
  }, [data, defaults?.signuponlyindividual, fromTable, supabase]);

  useEffect(() => {
    fetchGaps();
    const gap = gapData?.find((x) => x.gapid === data?.currentsessiongapid);
    setGap(gap);
  }, [fetchGaps, data?.currentsessiongapid, gap, gapData]);

  useEffect(() => {
    if (!data?.subscriptionid && !data?.currentsessionsubscriptionid) return;
    const subid = data?.subscriptionid || data?.currentsessionsubscriptionid;
    const f = async () => {
      const res: any = await supabase
        .from('tb_oem_subscriptions')
        .select('*')
        .eq('subscriptionid', subid);
      if (!res) return;
      setSubscription(res?.data[0]);
    };
    f();
  }, [
    supabase,
    defaults,
    data.subscriptionid,
    data.currentsessionsubscriptionid,
  ]);

  useEffect(() => {
    const f = async () => {
      if (!data.currentaasrarole) return;
      const roles = data.currentaasrarole.split(',');

      const d2: any = [];
      const results: any = await supabase
        .from('tb_aasraprofile_id')
        .select('*');

      if (!results || !defaults?.oemid) return;
      results.data
        .filter((p1) => p1['oemid'] === defaults?.oemid)
        .filter((p2) => roles.includes(p2['aasraprofiledescriptionshort']))
        .forEach((p3) => d2.push(p3['aasraprofiledescription']));

      setAasraRoles(d2.join(', '));
    };
    f();
  }, [aasra, supabase, data, defaults?.oemid]);

  const formatAddress = (address: any) => {
    if (!address) return '';
    return `${address['street1'] || ''}${
      address['street1'] && address['street2']
        ? ',  ' + address['street2']
        : address['street2']
        ? address['street2']
        : ''
    }
${address['suburb'] || ''}${
      address['suburb'] && address['city']
        ? ',  ' + address['city']
        : address['city']
        ? address['city']
        : ''
    }${
      address['state'] && (address['suburb'] || address['city'])
        ? ',  ' + address['state']
        : address['state']
        ? address['state']
        : ''
    }
${address['postcode'] || ''}${
      address['postcode'] && address['country']
        ? ',  ' + address['country']
        : address['country']
        ? address['country']
        : ''
    } `;
  };

  function isActive(): boolean {
    const expiryDate = data?.validtodate || data?.currentsessionvalidto;
    if (!expiryDate || new Date() > new Date(expiryDate)) return false;
    return true;
  }

  function subscriptionType(): string {
    return subscription?.subscriptionmyaccount
      ? subscription?.subscriptionmyaccount
      : messages.noSub;
  }

  function getAbn(): string {
    if (data?.currentsessiongapid === 1 || data?.currentsessiongapid === null) {
      return data?.abn ? data?.abn : messages.notApplicable;
    }

    return gap?.abn;
  }

  const formatOktaDate = (date: any) => {
    if (!date) return '';

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hour = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    return `${year}/${month > 9 ? month : '0' + month}/${
      day > 9 ? day : '0' + day
    } ${hour > 9 ? hour : '0' + hour}:${
      minutes > 9 ? minutes : '0' + minutes
    }:${seconds > 9 ? seconds : '0' + seconds}`;
  };

  const convertOktaDate = (date?: string) => {
    if (!date || date === 'error') return;

    const year = Number(date?.slice(0, 4));
    const month = Number(date?.slice(4, 6));
    const day = Number(date?.slice(6, 8));
    const hour = Number(date?.slice(8, 10));
    const minutes = Number(date?.slice(10, 12));
    const seconds = Number(date?.slice(12, 14));

    return new Date(Date.UTC(year, month - 1, day, hour, minutes, seconds));
  };

  const getCurrentUtcTime = () => {
    const currentTime = new Date().toLocaleString('en-US');
    return formatDateUTC(currentTime);
  };

  function renderInputGroup(input: any) {
    if (!data) return;

    let validTo: string = '';
    if (data.validtodate) {
      const toConvertDate = data.validtodate;
      const toLocalTime = new Date(toConvertDate).toLocaleString('en-US');
      validTo = formatDate(toLocalTime);
    } else if (data?.currentsessionvalidto) {
      const toLocalTime = new Date(data.currentsessionvalidto).toLocaleString(
        'en-US'
      );
      validTo = formatDate(toLocalTime);
    } else if (gapSubsData?.validtodate) {
      const toConvertDate = gapSubsData?.validtodate;
      const toLocalTime = new Date(toConvertDate).toLocaleString('en-US');
      validTo = formatDate(toLocalTime);
    } else {
      validTo = 'unknown';
    }

    console.log('HERE ******', data, validTo);

    if (input.field === 'address') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          textarea={true}
          value={
            defaults?.signuponlyindividual || gap?.gapid === 1
              ? formatAddress(data)
              : formatAddress(gap)
          }
        />
      );
    }

    if (input.field === 'gap' && !defaults?.signuponlyindividual) {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={gap?.gapcompanyname}
        />
      );
    }

    if (input.field === 'company' && defaults?.signuponlyindividual) {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={data?.companyname}
        />
      );
    }

    if (input.field === 'validtodate') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={validTo.length === 0 ? validTo : formatDateUTC(validTo)}
        />
      );
    }

    if (input.field === 'aasraRoles') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={aasraRoles}
          textarea={true}
          rows={3}
        />
      );
    }

    if (input.field === 'subscription') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={subscriptionType()}
        />
      );
    }

    if (input.field === 'abn') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={getAbn()}
        />
      );
    }

    if (input.field === 'email') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={data[input.field] || data?.useremailid}
        />
      );
    }

    if (input.field === 'currentUtcTime') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={getCurrentUtcTime()}
        />
      );
    }

    if (input.field === 'hasloggedin') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={data?.hasloggedin ? 'yes' : 'no'}
        />
      );
    }

    if (input.field === 'remainingTime') {
      return (
        <Countdown
          label={input.label}
          messages={messages}
          validToDate={validTo}
        />
      );
    }

    if (['firstname', 'surname', 'mobilenumber'].includes(input.field))
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={data[input.field]}
        />
      );
  }

  function renderOktaInfo(input: any) {
    if (
      client === 'mmal' &&
      [
        'okta_login',
        'okta_email',
        'okta_subscription_end_date',
        'okta_firstName',
        'okta_lastName',
        'okta_countryCode',
        'okta_preferredLanguage',
        'okta_userType',
        'okta_employeeNumber',
      ].includes(input.field)
    )
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label.replace('Okta ', '')}
          placeholder={input.placeholder}
          value={data[input.field]}
        />
      );
    if (client === 'mmal' && ['okta_valid_until'].includes(input.field))
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={formatOktaDate(
            convertOktaDate(data['okta_subscription_end_date'])
          )}
        />
      );

    if (input.field === 'currentUtcTime') {
      return (
        <InputGroup
          disabled={true}
          key={input.field}
          label={input.label}
          placeholder={input.placeholder}
          value={getCurrentUtcTime()}
        />
      );
    }

    if (input.field === 'remainingTime') {
      return (
        <Countdown
          label={input.label}
          messages={messages}
          validToDate={convertOktaDate(data['okta_subscription_end_date'])}
        />
      );
    }
  }

  return (
    <Modal close={close} overflow="auto">
      <S.MyAccountContainer>
        <S.Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <S.FullName>{`${data?.firstname} ${data?.surname}`}</S.FullName>
            <div style={{ display: 'flex' }}>
              {isActive() ? (
                <div
                  className="flex align-center bg-green-400 h-8 text-white font-bold mt-1 px-4 rounded-full text-xs"
                  style={{ paddingTop: '6px' }}
                >
                  {messages.active.toUpperCase()}
                </div>
              ) : (
                <div
                  className="flex align-center bg-red-500 h-8 text-white font-bold mt-1 px-4 rounded-full text-xs"
                  style={{ paddingTop: '6px' }}
                >
                  {messages.inactive.toUpperCase()}
                </div>
              )}
              <div
                className="flex align-center bg-gray-600 h-8 text-white font-bold mt-1 px-4 rounded-full text-xs"
                style={{
                  marginLeft: '1.6em',
                  marginRight: '1.6em',
                  paddingTop: '6px',
                }}
              >
                {subscriptionType()?.toUpperCase()}
              </div>
              {client === 'mmal' && fromTable && (
                <SwitchButton
                  onClick={() => {
                    setShowOkta(!showOkta);
                  }}
                />
              )}
            </div>
          </div>
          <img
            src={defaults?.popuplogourl}
            style={{ height: 90, marginRight: '8px' }}
          />
        </S.Header>
        <S.Line />
        <S.Body>
          <>
            <S.Info>
              <S.Input $display={showOkta}>
                {myAccount.map((input) => renderInputGroup(input))}
              </S.Input>
              {client === 'mmal' && fromTable && (
                <S.OktaInputs $display={showOkta}>
                  {myAccount.map((input) => renderOktaInfo(input))}
                </S.OktaInputs>
              )}
            </S.Info>
          </>
          <S.UserImage>
            <div
              className="flex items-center justify-center bg-gray-300"
              style={{ borderRadius: '50%', height: 140, width: 140 }}
            >
              <FontAwesomeIcon
                icon={faUser as IconProp}
                style={{ height: 100 }}
              />
            </div>
          </S.UserImage>
        </S.Body>
      </S.MyAccountContainer>
    </Modal>
  );
}

export default MyAccountModal;
