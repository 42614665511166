import { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { GAP, OEMList, User, UserData } from 'types';
import { supabase } from 'utils/supabase';
import { userFetch as apiFetchUser, userPost } from 'utils/apis';
import { oemUserList, oemUserListIns, gapCreate } from 'utils/apis';
import { DefaultSerializer } from 'v8';
import { useConfig, useLoggedIn } from 'utils/hooks';
import { String, StringIterator } from 'lodash';

// GAPS
type GapState = OEMList[];

const initialGapState: State | undefined = undefined;

const GAPListState = atom({
  key: 'GAPList',
  default: {
    data: undefined,
  },
});

let _gapsDone = false;

export function useGAPList(): { data: GAP[] | undefined; list: Function } {
  const [state, setState] = useRecoilState(GAPListState);
  const sBase = supabase();

  const list = useCallback(async () => {
    _gapsDone = true;
    if (state.data) return;
    const resp: any = await sBase.from('tb_gap').select('*');
    setState({ data: resp.data });
    /* eslint-disable-next-line */
  }, [state, supabase]);

  useEffect(() => {
    if (_gapsDone) return;
    list();
  }, [list]);

  return { data: state?.data, list };
}

// OEMS
type State = OEMList[];

const initialState: State | undefined = undefined;

const OEMListState = atom({
  key: 'OEMList',
  default: {
    data: undefined,
    ids: undefined,
  },
});

let _oemsDone = false;

export function useOEMList(): {
  data: OEMList[] | undefined;
  ids: any[] | undefined;
  list: Function;
} {
  // const [state, setState] = useRecoilState(OEMListState);
  const [state, setStateX] = useState<any>({});
  const sBase = supabase();

  const setState: Function = useCallback((data) => {
    sessionStorage.setItem('oemlist', JSON.stringify(data));
    setStateX(data);
  }, []);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem('oemlist') || '{}');
    setStateX(data);
  }, []);

  const list = useCallback(async () => {
    _oemsDone = true;
    if (state?.data) return state;
    const resp: any = await sBase.from('tb_oem_list').select('*');
    const resp2: any = await sBase.from('tb_oem_id').select('*');
    console.log('OEMS LIST ::::', resp.data);
    setState({ data: resp.data, ids: resp2.data });
    return { data: resp.data, ids: resp?.data };
    /* eslint-disable-next-line */
  }, [state, supabase]);

  useEffect(() => {
    if (_oemsDone) return;
    list();
  }, [list]);

  return { data: state?.data, ids: state?.ids, list };
}

const OEMUserState = atom({
  key: 'OEMUserList',
  default: {
    data: undefined,
    user: '',
    state: {},
  },
});

const UserState = atom({
  key: 'OEMUser',
  default: {
    data: undefined,
    user: '',
    email: '',
    access: [],
    aasra: [],
    subscription: [{ isvalid: false, validtodate: '', subscriptionid: '' }],
    userSubs: [],
    codeSubscriptions: [],
    hasAdmin: false,
    hasSuperAdmin: false,
    hasAssets: false,
    hasAccessProfiles: false,
    hasOemAdmin: false,
    hasGapAdmin: -1,
    hasGAPAccess: false,
    hasUrlAuth: false,
    hasViewAllAssets: false,
    hasNoAasraLogin: false,
    hasUsersData: false,
    hasActiveSubs: false,
    hasSupport: false,
    hasClientAdmin: false,
    hasPortalAccess: false,
    state: '',
  },
});

let fetching = false;
let fetching2 = false;

export function useOEMUserList() {
  const [state, setState] = useRecoilState(OEMUserState);
  const [user, setUser] = useRecoilState(UserState);
  const sBase = supabase();
  const { defaults } = useConfig();

  const list = async (user: string, gapid: number) => {
    console.log('LIST *******');
    if (!user || fetching) return undefined;
    if (state.user === user) return state.data;
    fetching = true;
    const resp: any = await oemUserList(user, defaults.oemid, gapid);
    fetching = false;
    if (!resp.data?.data) return undefined;
    setState({ ...state, user, data: resp.data?.data });
    return resp.data;
  };

  const setSubscriptionStatus = (status: any) => {
    setUser({ ...user, subscription: status });
  };

  const setCodeSubscriptionStatus = (status: any) => {
    setUser({ ...user, subscription: status });
  };

  const fetchUser = async (email: string, gapid?: number) => {
    if (fetching2) return undefined;
    // if (user.email === email) return user.data;
    fetching2 = true;
    const [resp1, resp2]: any = await Promise.all([
      oemUserList(email, defaults.oemid, gapid || 1),
      apiFetchUser(email, undefined, gapid),
    ]);
    const u: User | undefined = resp2?.data?.user;
    const au = resp1?.data?.data?.find(
      ({ oemid }) => oemid === defaults?.oemid || oemid === 0
    );
    const gapd = resp1?.data?.gapdata;
    fetching2 = false;
    if (!u) return undefined;
    setState({ ...state, user: email, data: resp1?.data?.data, state: 'done' });

    console.log(
      'HERE.fetchUser ::::',
      u?.gapid,
      gapid,
      u?.gapid == gapid && gapid ? parseInt(gapid?.toString()) : undefined
    );
    setUser({
      ...state,
      email,
      data: resp2?.data?.user,
      access: resp2?.data?.access,
      hasGAPAccess: resp2?.data?.user?.hasGAPAccess,
      aasra: resp2?.data?.aasra,
      subscription: resp2?.data?.subscription ?? null,
      userSubs: resp2?.data?.userSubs ?? null,
      codeSubscriptions: resp2?.data?.codeSubscriptions ?? null,
      hasAdmin: !!au?.hasadmin,
      hasSuperAdmin: !!resp1?.data?.hassuperadmin,
      hasAssets: !!au?.hasfindassets || gapd?.isvalid,
      hasAccessProfiles: !!au?.hasaccessprofiles,
      hasOemAdmin: !!au?.hasoemadmin,
      hasViewAllAssets: !!au?.hasviewallassets,
      hasNoAasraLogin: !!au?.hasnoaasralogin,
      hasGapAdmin:
        u?.gapid == gapid && gapid ? parseInt(gapid?.toString()) : undefined,
      hasUrlAuth: !!au?.hasurlauth,
      hasUsersData: !!au?.hasusersdata,
      hasActiveSubs: !!au?.hasactivesubs,
      hasSupport: !!au?.hassupport,
      hasClientAdmin: !!au?.hasclientadmin,
      hasPortalAccess: !!au?.hasportalaccess,
    } as any);
    return { ...resp2?.data?.user, subscription: resp2?.data?.subscription };
  };

  const fetchUser2 = async (email: string, phone: string): Promise<boolean> => {
    if (fetching2) return false;
    fetching2 = true;
    const [resp1, resp2] = await Promise.all([
      apiFetchUser(email),
      apiFetchUser(undefined, phone),
    ]);
    // setState({ ...state, email, data2: resp.data });
    fetching2 = false;
    return !!resp1?.data?.user?.useremailid || !!resp2?.data?.user?.useremailid;
  };

  const fetchUser3 = async (
    email: string,
    gapid?: number
  ): Promise<boolean> => {
    if (fetching2) return false;
    fetching2 = true;
    const resp1 = await apiFetchUser(email, undefined, gapid);
    // setState({ ...state, email, data2: resp.data });
    fetching2 = false;
    return resp1?.data;
  };

  const createGap = async (
    gap: any,
    gapid: number | undefined,
    oemid: number,
    email?: string
  ): Promise<any> => {
    if (fetching2) return false;
    fetching2 = true;
    const resp1: any = await gapCreate(gap, gapid, oemid, email);

    // setState({ ...state, email, data2: resp.data });
    fetching2 = false;
    return resp1;
  };

  const signup = async (
    data: UserData,
    password: string,
    type: string,
    gapid: number,
    aasraRoles: string
  ) => {
    try {
      const { useremailid } = data;
      if (!useremailid) return { error: 'Email missing' };
      const resp: any = await fetchUser3(useremailid, gapid);
      if (resp?.user?.useremailid)
        return { message: 'User already exists', data: resp };
      // if (type === 'gapUser' && !resp) return { message: 'GAP User missing' };
      const resp2: any = await saveUser(data, type, aasraRoles);
      if (resp2?.error) return resp2;
      const { error } = await sBase.auth.api.signUpWithEmail(
        useremailid,
        password || ''
        // { redirectTo: '' }
      );
      return error ? { error } : { status: 'success', data: resp2?.data };
    } catch (e: any) {
      console.log(e);
      return e;
    }
  };

  const confirmPhone = async (phone: string, code: string) => {
    try {
      const { error, data } = await sBase.auth.api.verifyMobileOTP(
        phone,
        code,
        { redirectTo: undefined }
      );
      return error && !error.message?.includes('Network request')
        ? error
        : null;
    } catch (e) {
      return null;
    }
  };

  const saveUser = async (
    user: UserData,
    type: string,
    aasraRoles: string
    // oems: string[]
    // gaps: number[],
    // gap?: GAP
  ) => {
    try {
      const resp: any = await userPost(user, type, aasraRoles);
      return resp;
    } catch (error: any) {
      return error.toString();
    }
  };

  const add = async (user: string, oems: string[]) => {
    if (!user) return 'Email required';
    try {
      await oemUserListIns(user, oems);
      return null;
    } catch (error: any) {
      return error.toString();
    }
  };

  return {
    list,
    isLoading: !state?.data,
    isLoadingUser: !user?.data,
    access: user.access,
    aasra: user.aasra,
    subscription: user.subscription,
    userSubs: user.userSubs,
    setSubscriptionStatus,
    hasAssets: user.hasAssets,
    hasAdmin: user.hasAdmin,
    hasSuperAdmin: user.hasSuperAdmin,
    hasAccessProfiles: user.hasAccessProfiles,
    hasOemAdmin: user.hasOemAdmin,
    hasGapAdmin: user.hasGapAdmin,
    hasGAPAccess: user.hasGAPAccess,
    hasUrlAuth: user.hasUrlAuth,
    hasViewAllAssets: user.hasViewAllAssets,
    hasNoAasraLogin: user.hasNoAasraLogin,
    hasUsersData: user.hasUsersData,
    hasActiveSubs: user.hasActiveSubs,
    hasSupport: user.hasSupport,
    hasClientAdmin: user.hasClientAdmin,
    hasPortalAccess: user.hasPortalAccess,
    data: state.data,
    add,
    signup,
    confirmPhone,
    saveUser,
    fetchUser,
    createGap,
  };
}
