import { useState, useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  data: any[];
  onRowClicked?: any;
  config: {
    cols: any[];
    rows?: number;
    filters?: any[];
  };
};

const Table = styled.table`
  width: 100%;

  tr {
    border: 1px solid grey;
  }
  table {
    width: 100%;
    span,
    div {
      text-align: left !important;
    }
  }
  table:nth-child(even) {
    background-color: #eeeeee;
  }
  th {
    width: 15rem;
    text-align: left;
    font-weight: bold;
  }
  td,
  th {
    padding: 0.5rem;
  }
`;

const Pages = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.8rem 4rem 8rem 4rem;

  button {
    padding: 0.5rem;
    margin: 0.5rem;
  }
`;

export default function MobileTable({
  data,
  config: { cols, rows = 15, filters },
  onRowClicked,
}: Props) {
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderRow = (row: any) => {
    return cols?.map((col) => {
      return (
        <td style={{ width: '75px' }}>
          {col?.cellRendererFramework
            ? col.cellRendererFramework({ value: row[col.field], data: row })
            : row[col.field]}
        </td>
      );
    });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            {cols?.map((col) => (
              <th
                style={{
                  width: '75px',
                  textAlign: col.textAlign || 'left',
                  borderRight: col.noBorder || '1px solid #ccc',
                }}
              >
                {' '}
                {col.headerName}{' '}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.slice(page * rows, page * rows + rows)?.map((row, index) => (
            <tr onClick={() => onRowClicked && onRowClicked(row)}>
              {renderRow(row)}
            </tr>
          ))}
        </tbody>
      </Table>
      <Pages>
        {(() => {
          const pages = data?.length / rows;
          const components: React.ReactNode[] = [];
          for (let i = 0; i < pages; i++) {
            components.push(
              <button
                style={{ background: page === i ? 'lightgrey' : 'transparent' }}
                key={i}
                onClick={() => {
                  setPage(i);
                  document?.querySelector('h1')?.scrollIntoView();
                }}
              >
                {i + 1}
              </button>
            );
          }
          return components;
        })()}
      </Pages>
    </>
  );
}
