import styled from 'styled-components';

export const Container = styled.div<any>`
  position: relative;
  padding-bottom: 15rem;
`;

export const Form = styled.div<any>`
  position: relative;
  width: 15em;
  //margin: auto;
  //padding: 2em;
  /* border: 1px solid ${(props) =>
    props.theme.name === 'light' ? '#d8d1d1' : 'grey'}; */
  border-radius: 5px;
  //margin-top: 100px;
  max-width: 30em;
  margin-top: 5%;
  background: transparent;
`;

export const Logo = styled.img`
  width: 5em;
  display: block;
  margin: auto;
`;
