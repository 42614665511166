import { atom } from 'recoil';

import { AssetCategory, Language } from 'types';
import { useGeneric, useCrud } from 'utils/genericData';
import { useSupabase } from 'utils/supabase';

type State = {
  data?: AssetCategory[];
  ids?: AssetCategory[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const AssetCategorysState = atom({
  key: 'AssetCategorys',
  default: initialState,
});

export function useAssetCategories() {
  const xport = useGeneric(
    AssetCategorysState,
    'tb_assetcategory',
    'assetcategory',
    undefined,
    undefined,
    true
  );

  return xport;
}

// Assets crud
export type Assets = {
  assetid: number;
  isactive: boolean;
};

const iStateAssets: Assets[] | undefined = undefined;

const AssetsState = atom({
  key: 'Assets',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssets() {
  const xport = useCrud(
    AssetsState,
    'tb_assets',
    undefined,
    (left: Assets, right: Assets) => left.assetid === right.assetid,
    (d: Assets) => ({ assetid: d.assetid })
  );

  return xport;
}

// Assets category crud
export type AssetsCat = {
  assetid: number;
  assetcategoryid: number;
};

const iStateAssetsCat: AssetsCat[] | undefined = undefined;

const AssetsCatsState = atom({
  key: 'AssetsCats',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsCats() {
  const xport = useCrud(
    AssetsCatsState,
    'tb_assets_assetcategory',
    'tb_assets',
    (left: AssetsCat, right: AssetsCat) => {
      console.log(left, right);
      return (
        left.assetid === right.assetid &&
        left.assetcategoryid === right.assetcategoryid
      );
    },
    (d: AssetsCat) =>
      ({ assetid: d.assetid, assetcategoryid: d.assetcategoryid } as AssetsCat)
  );

  return xport;
}

// Assets description crud
export type AssetsDescription = {
  assetid: number;
  languageid: number;
  assetdescription: string;
  assetdescriptionshort: string;
};
const iStateAssetsDescr: AssetsDescription[] | undefined = undefined;

const AssetsDescriptionState = atom({
  key: 'AssetsDescriptionCats',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsDescription() {
  const xport = useCrud(
    AssetsDescriptionState,
    'tb_assets_description',
    'tb_assets',
    (left: AssetsDescription, right: AssetsDescription) =>
      left.assetid === right.assetid && left.languageid === right.languageid,
    (d: AssetsDescription) =>
      ({ assetid: d.assetid, languageid: d.languageid } as AssetsDescription)
  );

  return xport;
}

// Assets model family crud
export type AssetsModelFamily = {
  assetid: number;
  modelfamilyid: number;
};
const iStateAssetsModelFamily: AssetsModelFamily[] | undefined = undefined;

const AssetsModelFamilyState = atom({
  key: 'AssetsModelFamilyCats',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsModelFamily() {
  const xport = useCrud(
    AssetsModelFamilyState,
    'tb_assets_modelfamily',
    'tb_assets',
    (left: AssetsModelFamily, right: AssetsModelFamily) =>
      left.assetid === right.assetid &&
      left.modelfamilyid === right.modelfamilyid,
    (d: AssetsModelFamily) => ({
      assetid: d.assetid,
      modelfamilyid: d.modelfamilyid,
    })
  );

  return xport;
}

// Assets model family crud
export type AssetsModelSeries = {
  assetid: number;
  modelseriesid: number;
};
const iStateAssetsModelSeries: AssetsModelSeries[] | undefined = undefined;

const AssetsModelSeriesState = atom({
  key: 'AssetsModelSeriesCats',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsModelSeries() {
  const xport = useCrud(
    AssetsModelSeriesState,
    'tb_assets_modelseries',
    'tb_assets',
    (left: AssetsModelSeries, right: AssetsModelSeries) =>
      left.assetid === right.assetid &&
      left.modelseriesid === right.modelseriesid,
    (d: AssetsModelSeries) => ({
      assetid: d.assetid,
      modelseriesid: d.modelseriesid,
    })
  );

  return xport;
}

// Assets model crud
export type AssetsModel = {
  assetid: number;
  modelid: number;
};
const iStateAssetsModel: AssetsModel[] | undefined = undefined;

const AssetsModelState = atom({
  key: 'AssetsModels',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsModel() {
  const xport = useCrud(
    AssetsModelState,
    'tb_assets_model',
    'tb_assets',
    (left: AssetsModel, right: AssetsModel) =>
      left.assetid === right.assetid && left.modelid === right.modelid,
    (d: AssetsModel) => ({ assetid: d.assetid, modelid: d.modelid })
  );

  return xport;
}

// Assets model year crud
export type AssetsModelYear = {
  assetid: number;
  modelyearid: number;
};
const iStateAssetsModelYear: AssetsModelYear[] | undefined = undefined;

const AssetsModelYearState = atom({
  key: 'AssetsModelYears',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsModelYear() {
  const xport = useCrud(
    AssetsModelYearState,
    'tb_assets_modelyear',
    'tb_assets',
    (left: AssetsModelYear, right: AssetsModelYear) =>
      left.assetid === right.assetid && left.modelyearid === right.modelyearid,
    (d: AssetsModelYear) => ({ assetid: d.assetid, modelyearid: d.modelyearid })
  );

  return xport;
}

// Assets location crud
export type AssetsLocation = {
  assetid: number;
  languageid: number;
  assetname: string;
  assetsystem: string;
};

const iStateAssetsLocation: AssetsLocation[] | undefined = undefined;

const AssetsLocationState = atom({
  key: 'AssetsLocations',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsLocation() {
  const xport = useCrud(
    AssetsLocationState,
    'tb_assets_location',
    'tb_assets',
    (left: AssetsLocation, right: AssetsLocation) =>
      left.assetid === right.assetid && left.languageid === right.languageid,
    (d: AssetsLocation) => ({ assetid: d.assetid, languageid: d.languageid })
  );

  return xport;
}

// Assets oem crud
export type AssetsOEM = {
  assetid: number;
  oemid: number;
};
const iStateAssetsOEM: AssetsOEM[] | undefined = undefined;

const AssetsOEMState = atom({
  key: 'AssetsOEMs',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsOEM() {
  const xport = useCrud(
    AssetsOEMState,
    'tb_assets_oem',
    'tb_assets',
    (left: AssetsOEM, right: AssetsOEM) =>
      left.assetid === right.assetid && left.oemid === right.oemid,
    (d: AssetsOEM) => ({ assetid: d.assetid, oemid: d.oemid })
  );

  return xport;
}

// Assets division crud
export type AssetsDivision = {
  assetid: number;
  divisionid: number;
};
const iStateAssetsDivision: AssetsDivision[] | undefined = undefined;

const AssetsDivisionState = atom({
  key: 'AssetsDivisions',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsDivision() {
  const xport = useCrud(
    AssetsDivisionState,
    'tb_assets_division',
    'tb_assets',
    (left: AssetsDivision, right: AssetsDivision) =>
      left.assetid === right.assetid && left.divisionid === right.divisionid,
    (d: AssetsDivision) => ({ assetid: d.assetid, divisionid: d.divisionid })
  );

  return xport;
}

// Assets brand crud
export type AssetsBrand = {
  assetid: number;
  brandid: number;
};
const iStateAssetsBrand: AssetsBrand[] | undefined = undefined;

const AssetsBrandState = atom({
  key: 'AssetsBrands',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsBrand() {
  const xport = useCrud(
    AssetsBrandState,
    'tb_assets_brand',
    'tb_assets',
    (left: AssetsBrand, right: AssetsBrand) =>
      left.assetid === right.assetid && left.brandid === right.brandid,
    (d: AssetsBrand) => ({ assetid: d.assetid, brandid: d.brandid })
  );

  return xport;
}

// Assets repair group crud
export type AssetsRepairGroup = {
  assetid: number;
  repairgroupid: string;
};
const iStateAssetsRepairGroup: AssetsRepairGroup[] | undefined = undefined;

const AssetsRepairGroupState = atom({
  key: 'AssetsRepairGroups',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsRepairGroup() {
  const xport = useCrud(
    AssetsRepairGroupState,
    'tb_assets_repairgroup',
    'tb_assets',
    (left: AssetsRepairGroup, right: AssetsRepairGroup) =>
      left.assetid === right.assetid &&
      left.repairgroupid === right.repairgroupid,
    (d: AssetsRepairGroup) => ({
      assetid: d.assetid,
      repairgroupid: d.repairgroupid,
    })
  );

  return xport;
}

// Assets repair sub group crud
export type AssetsRepairSubGroup = {
  assetid: number;
  repairsubgroupid: string;
};
const iStateAssetsRepairSubGroup: AssetsRepairSubGroup[] | undefined =
  undefined;

const AssetsRepairSubGroupState = atom({
  key: 'AssetsRepairSubGroups',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsRepairSubGroup() {
  const xport = useCrud(
    AssetsRepairSubGroupState,
    'tb_assets_repairsubgroup',
    'tb_assets',
    (left: AssetsRepairSubGroup, right: AssetsRepairSubGroup) =>
      left.assetid === right.assetid &&
      left.repairsubgroupid === right.repairsubgroupid,
    (d: AssetsRepairSubGroup) => ({
      assetid: d.assetid,
      repairsubgroupid: d.repairsubgroupid,
    })
  );

  return xport;
}

// Assets repair component crud
export type AssetsRepairComponent = {
  assetid: number;
  repaircomponentid: string;
};
const iStateAssetsRepairComponent: AssetsRepairComponent[] | undefined =
  undefined;

const AssetsRepairComponentState = atom({
  key: 'AssetsRepairComponents',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsRepairComponent() {
  const xport = useCrud(
    AssetsRepairComponentState,
    'tb_assets_repaircomponent',
    'tb_assets',
    (left: AssetsRepairComponent, right: AssetsRepairComponent) =>
      left.assetid === right.assetid &&
      left.repaircomponentid === right.repaircomponentid,
    (d: AssetsRepairComponent) => ({
      assetid: d.assetid,
      repaircomponentid: d.repaircomponentid,
    })
  );

  return xport;
}

// Assets repair component crud
export type AssetsAASRAProfile = {
  assetid: number;
  aasraprofileid: string;
};
const iStateAssetsAASRAProfile: AssetsAASRAProfile[] | undefined = undefined;

const AssetsAASRAProfileState = atom({
  key: 'AssetsAASRAProfiles',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsAASRAProfile() {
  const xport = useCrud(
    AssetsAASRAProfileState,
    'tb_assets_aasraprofile',
    'tb_assets',
    (left: AssetsAASRAProfile, right: AssetsAASRAProfile) =>
      left.assetid === right.assetid &&
      left.aasraprofileid === right.aasraprofileid,
    (d: AssetsAASRAProfile) => ({
      assetid: d.assetid,
      aasraprofileid: d.aasraprofileid,
    })
  );

  return xport;
}

// Asset type category crud
export type AssetsType = {
  assetid: number;
  assettypeid: number;
};

const AssetsTypeState = atom({
  key: 'AssetsType',
  default: {
    data: undefined,
    status: undefined,
  },
});

export function useAssetsType() {
  const xport = useCrud(
    AssetsTypeState,
    'tb_assets_type',
    'tb_assets',
    (left: AssetsType, right: AssetsType) =>
      left.assetid === right.assetid && left.assettypeid === right.assettypeid,
    (d: AssetsType) => ({ assetid: d.assetid, assettypeid: d.assettypeid })
  );

  return xport;
}
