/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { debounce } from 'lodash';

import PrimaryButton from 'components/PrimaryButton';
import Select from 'components/Select';
import * as S from './Search.styled';
import { useModelFamily } from 'features/modelFamily/hooks';
import { useConfig, useLanguages } from 'utils/hooks';
import TextInput2 from 'components/TextInput2';
import { useModelYear } from 'features/modelYear/hooks';
import { useModelSeries } from 'features/modelSeries/hooks';
import { useModel } from 'features/model/hooks';
import { AssetCategory, Model, ModelSeries, ModelYear } from 'types';
import { useAssetCategories, useAssets } from 'features/assets/hooks';
import ResultsTable from '../components/ResultsTable';
import { useSearch, Result } from '../hooks';
import { useAssetTypes } from 'features/assetsType/hooks';
import { useOEMUserList } from 'features/page/hooks';
import Search from './Search';

export type State = {
  catName: string;
  modelfamilyid: number;
  vin: string;
  modelyearid: number;
  modelseriesid: number;
  modelid: number;
  search: string;
  assettypeid?: number;
  assetcategoryid?: number;
};

export default function EditTenant({
  assetOnboard,
  children,
}: {
  assetOnboard?: boolean;
  children?: React.ReactNode;
}) {
  const { assetCategoryName: catName }: any = useParams();
  const [data, setData] = useState<State>({ search: '', vin: '' } as State);
  const { messages, languageId } = useLanguages();
  const { data: modelFamily } = useModelFamily();
  const { data: modelYear } = useModelYear();
  const { data: modelSeries } = useModelSeries();
  const { data: assetCategories } = useAssetCategories();
  const { data: assetTypes } = useAssetTypes();
  const { data: models }: { data: Model[] | undefined } = useModel();
  const [filter, setFilter] = useState<string | undefined>();
  const [filtered, setFiltered] = useState<Result[] | undefined>();
  const [results, setResults] = useState<Result[]>();
  const { defaults } = useConfig();
  const {
    search,
    status,
    fullList,
    buildResults,
    data: searchData,
    vinResults,
    vinSearch,
    resetQuery,
  } = useSearch(catName);
  const cat: AssetCategory | undefined = assetCategories?.find(
    ({ assetcategorydescription, languageid }: AssetCategory) =>
      languageid === languageId &&
      assetcategorydescription.toLowerCase().replaceAll(' ', '-') ===
        catName?.toString()
  );

  const reset = useCallback(() => {
    setData({
      search: '',
      vin: '',
      modelfamilyid: -1,
      assetcategoryid: -1,
    } as State);
    setResults([]);
    resetQuery();
    setFilter('');
    setFiltered(undefined);
    /* eslint-disable-next-line */
  }, []);

  const dbFilter = useMemo(
    () =>
      debounce((e: string | undefined) => {
        if (e) {
          setFiltered(
            results?.filter((x) => {
              return e.split(' ').every((y) => {
                const a = !Number.isNaN(parseInt(y))
                  ? parseInt(x.assetid.toString()) === parseInt(y)
                  : false;
                const b = x.description.toLowerCase().includes(y.toLowerCase());
                return a || b;
              });
            })
          );
        } else {
          setFiltered(undefined);
        }
      }, 750),
    [results]
  );

  useEffect(() => {
    reset();
  }, [catName, reset]);

  // useEffect(() => {
  //   setResults(fullList);
  // }, [fullList]);

  const doSearch = useCallback(
    async (data: State) => {
      console.log('doSearch ::::', data);
      const results = await vinSearch(data);
      setResults(results);
    },
    [vinSearch]
  );

  useEffect(() => {
    doSearch(data);
    /* eslint-disable-next-line */
  }, [languageId]);

  const setDataProxy = (newData: State) => {
    setData(newData);
    doSearch(newData);
  };

  console.log(
    'data ::::',
    data?.modelid,
    modelYear?.filter(
      ({ modelid }) => parseInt(modelid) === parseInt(data?.modelid?.toString())
    )
  );
  // if (!cat && !assetOnboard)
  //   return <div className="w-100 pt-20 text-center">{messages.noCategory}</div>;

  return (
    <div
      className="flex items-center flex-col text-white"
      style={{
        height: '100%',
        // minHeight: '1000px',
        marginBottom: '1.4em',
      }}
    >
      {!assetOnboard && (
        <Helmet>
          <title>{cat?.assetcategorydescription}</title>
        </Helmet>
      )}
      <main
        className="w-full text-black pt-4 flex flex-col"
        style={{ height: '100%' }}
      >
        <div style={{ margin: '0 2em 1em 2em' }}>
          <S.H1 className="text-2xl text-bold">
            {cat?.assetcategorydescription}
          </S.H1>
          <S.H4 className="text-sm">{messages.assetSearchH2}</S.H4>

          <div className="inline flex-wrap xl:flex justify-between">
            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <TextInput2
                style={{ height: '42px' }}
                value={data?.vin}
                label={messages.enterVin}
                example={messages.enterVin}
                onChange={(e) => setDataProxy({ ...data, vin: e } as State)}
              />
            </div>

            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={modelFamily?.filter(
                  (x) => x?.modelfamilyid === vinResults?.modelfamilyid
                )}
                value={vinResults?.modelfamilyid}
                // onChange={async (e) =>
                //   setDataProxy({
                //     ...data,
                //     modelfamilyid: parseInt(e.target.value),
                //   } as State)
                // }
                idKey="modelfamilyid"
                valueKey="modelfamilydescription"
                placeholder={messages.modelFamily}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>

            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={modelSeries?.filter(
                  ({ modelseriesid }) =>
                    modelseriesid === vinResults?.modelseriesid
                )}
                value={vinResults?.modelseriesid}
                // onChange={async (e) =>
                //   setDataProxy({
                //     ...data,
                //     modelseriesid: parseInt(e.target.value),
                //   } as State)
                // }
                idKey="modelseriesid"
                valueKey="modelseriesdescription"
                placeholder={messages.modelSeries}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>

            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={models?.filter(
                  ({ modelid }) => modelid === vinResults?.modelid
                )}
                value={vinResults?.modelid}
                // onChange={async (e) =>
                //   setDataProxy({
                //     ...data,
                //     modelid: parseInt(e.target.value),
                //   } as State)
                // }
                idKey="modelid"
                valueKey="modeldescription"
                placeholder={messages.model}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>

            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <Select
                data={modelYear?.filter(
                  ({ modelyearid }) =>
                    parseInt(modelyearid) === vinResults?.modelyearid
                )}
                value={vinResults?.modelyearid}
                // onChange={async (e) =>
                //   setDataProxy({
                //     ...data,
                //     modelyearid: parseInt(e.target.value),
                //   } as State)
                // }
                idKey="modelyearid"
                valueKey="modelyeardescription"
                placeholder={messages.modelYear}
                withNullOption
                containerStyle={{ width: '100%', marginLeft: '0' }}
              />
            </div>

            {!cat && (
              <div
                style={{ flexGrow: 1, maxWidth: 300 }}
                className="xl:mr-6 search__input-box"
              >
                <Select
                  data={assetCategories
                    ?.filter((x) => !x?.assetcategorydescription.includes('->'))
                    ?.filter(
                      (x) =>
                        x?.assetcategorydescription
                          .toLowerCase()
                          .includes('manual') ||
                        x?.assetcategorydescription
                          .toLowerCase()
                          .includes('schedule') ||
                        (defaults?.oemid !== 1 &&
                          x?.assetcategorydescription
                            .toLowerCase()
                            .includes('bulletins'))
                    )}
                  value={data?.assetcategoryid}
                  onChange={async (e) =>
                    setDataProxy({
                      ...data,
                      assetcategoryid: parseInt(e.target.value),
                    } as State)
                  }
                  idKey="assetcategoryid"
                  valueKey="assetcategorydescription"
                  placeholder={messages.assetCategory}
                  withNullOption
                  containerStyle={{ width: '100%', marginLeft: '0' }}
                />
              </div>
            )}

            <div style={{ flexGrow: 1 }} className="xl:mr-6 search__input-box">
              <TextInput2
                style={{ height: '42px' }}
                value={filter}
                label={messages.search}
                example={messages.search}
                onChange={(e) => {
                  setFilter(e);
                  dbFilter(e);
                }}
              />
            </div>
          </div>
        </div>

        {/* {assetOnboard && (
          <div
            style={{
              margin: '0 0 2rem 2rem',
              maxWidth: '600px',
              display: 'flex',
            }}
          >
            <Select
              data={assetTypes}
              value={data?.assettypeid}
              onChange={async (e) =>
                setDataProxy({
                  ...data,
                  assettypeid: parseInt(e.target.value),
                } as State)
              }
              idKey="assettypeid"
              valueKey="assettypedescription"
              placeholder={messages.assetType}
              withNullOption
              containerStyle={{ width: '100%', marginLeft: '0' }}
            />
            <span style={{ display: 'inline-block', width: '3rem' }}></span>
            <Select
              data={assetCategories.filter(
                (x) => !x?.assetcategorydescription.includes('->')
              )}
              value={data?.assetcategoryid}
              onChange={async (e) =>
                setDataProxy({
                  ...data,
                  assetcategoryid: parseInt(e.target.value),
                } as State)
              }
              idKey="assetcategoryid"
              valueKey="assetcategorydescription"
              placeholder={messages.assetCategory}
              withNullOption
              containerStyle={{ width: '100%', marginLeft: '0' }}
            />
          </div>
        )} */}
        <div style={{ marginLeft: '2em' }}>
          <div>
            {/* <button
              onClick={reset}
              style={{ height: '42px', width: '200px' }}
              className="bg-black border-black text-white p-3 mb-8"
              // className="bg-transparent border-black border-2 text-black p-1 pr-5 pl-5 w-full"
            >
              Reset
            </button> */}
            {/* <button onClick={doSearch} className="bg-black text-white p-1 pr-5 pl-5 m4 ml-5">Search</button> */}
            <PrimaryButton onClick={reset} width="200px">
              Reset
            </PrimaryButton>
          </div>
        </div>

        {status === 'loading' ? (
          <div className="w-100 text-center p-10 text-black">
            {messages.loading}...
          </div>
        ) : null}
        <>
          <ResultsTable
            assetOnboard={assetOnboard}
            type={cat?.assetcategorydescription}
            results={filtered || results}
            fullList={[]}
            searchData={searchData}
          >
            {children}
          </ResultsTable>
          {/* {results.length === 0 && (
              <div className="w-100 text-center p-4 text-black">
                {messages.noResults}
              </div>
            )} */}
        </>
      </main>
    </div>
  );
}
