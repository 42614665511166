import { atom } from 'recoil';

import { Brand, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: Brand[];
  ids?: Brand[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const BrandsState = atom({
  key: 'Brands',
  default: initialState,
});

export function useBrands() {
  const xport = useGeneric(
    BrandsState,
    'tb_brand',
    'brand',
    (data: Brand, iData: Brand) => {
      // delete data?.oemid;
      delete data?.divisionid;
    },
    (data: Brand[], iData: Brand[]) => {
      return data.map((d) => {
        const i = iData?.find(({ brandid }) => brandid === d.brandid);
        return {
          ...d,
          oemid: parseInt(i?.oemid?.toString() || '0'),
          divisionid: i?.divisionid,
        };
      });
    }
  );

  return xport;
}
