import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';

import { useAssetsNav, useLanguages, useToast } from 'utils/hooks';
import Modal from 'components/Modal';
import ModalHeader from './components/ModalHeader';
import * as S from './styled';

const oemSchemeName = {
  mmal: 'MTIA',
  subaru: 'Subaru Service Information Sharing Scheme (SSISS)',
  honda: 'Honda Service Information Sharing Scheme (HSISS)',
};

const oemSchemeAbbreviation = {
  mmal: 'MTIA',
  subaru: 'SSISS',
  honda: 'HSISS',
};

function AssetMenuModals({
  client,
  close,
  type,
  sendClipEmail,
}: {
  client: string;
  close: Function;
  type: string;
  sendClipEmail: Function;
}) {
  const { messages } = useLanguages();
  const history = useAssetsNav();

  const handleCloseAndRedirect = (to: string) => {
    history.push(client, to);
    close();
  };

  const renderContent = () => {
    if (type === 'Special Tools') {
      return (
        <>
          <S.Paragraph>
            <span style={{ fontWeight: 'bold' }}>
              {messages.specialToolsSpan1}
            </span>
            {messages.specialToolsP1}
          </S.Paragraph>
          <S.Paragraph>
            {messages.specialToolsP2}
            <a
              href="https://product-catalog.boschaftermarket.com.au/mitsubishi/mitsubishi/?page=2"
              target="_blank"
            >
              <span
                style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
              >
                {messages.here}
              </span>
            </a>
          </S.Paragraph>
          <S.Paragraph>{messages.specialToolsP3}</S.Paragraph>
          <S.Paragraph>
            <span style={{ fontWeight: 'bold' }}>
              {messages.specialToolsSpan2}
            </span>
            {messages.specialToolsP4}
          </S.Paragraph>
          <S.Paragraph style={{ margin: '0 0 0 1.5em' }}>
            {messages.specialToolsP5}
          </S.Paragraph>
          <S.Paragraph style={{ margin: '0 0 0 1.5em' }}>
            {messages.specialToolsP6}
          </S.Paragraph>
          <S.Paragraph style={{ margin: '0 0 0 1.5em' }}>
            {messages.specialToolsP7}
          </S.Paragraph>
          <S.Paragraph style={{ margin: '0 0 1em 1.5em' }}>
            {messages.specialToolsP8}
          </S.Paragraph>
          <S.Paragraph>{messages.specialToolsP13}</S.Paragraph>
          <S.Paragraph>{messages.specialToolsP9}</S.Paragraph>
          <S.Paragraph>{messages.specialToolsP10}</S.Paragraph>
          <S.Paragraph style={{ marginBottom: 0 }}>
            {messages.specialToolsP11}
          </S.Paragraph>
          <S.Paragraph>{messages.specialToolsP12}</S.Paragraph>
        </>
      );
    } else if (type === 'MUT Software Info') {
      return (
        <>
          <S.Paragraph>
            {messages.mutModalP1}{' '}
            <strong>
              <span>EXCLUDING</span>
            </strong>
            {messages.mutModalP12}
          </S.Paragraph>
          <S.Paragraph>{messages.mutModalP2}</S.Paragraph>
          <S.Paragraph>
            {messages.mutModalP31}
            <strong>
              <span>NOT</span>
            </strong>
            {messages.mutModalP32}
          </S.Paragraph>
          <S.Paragraph>{messages.mutModalP4}</S.Paragraph>
          <S.Paragraph>{messages.mutModalP5}</S.Paragraph>
          <S.Paragraph>
            {messages.mutModalP6}
            <span>
              <a
                href="https://product-catalog.boschaftermarket.com.au/mitsubishi/mitsubishi"
                style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
                target={'_blank'}
              >
                {messages.here}
              </a>
            </span>
          </S.Paragraph>
          {/* <S.Paragraph>
            {messages.mutModalP7}{' '}
            <span>
              <Link
                style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
                onClick={() =>
                  handleCloseAndRedirect('diagnostic-software-download')
                }
                to="#"
              >
                {messages.here}
              </Link>
            </span>
          </S.Paragraph>
          <S.Paragraph>{messages.mutModalP8}</S.Paragraph>
          <S.Paragraph>
            {messages.mutModalP9}{' '}
            <span>
              <a
                href="https://product-catalog.boschaftermarket.com.au/mitsubishi/mitsubishi"
                style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
                target={'_blank'}
              >
                {messages.here}
              </a>
            </span>
          </S.Paragraph> */}
          <S.Paragraph>{messages.mutModalP10}</S.Paragraph>
        </>
      );
    } else if (type === 'CLIP Software Info') {
      return (
        <>
          <S.Paragraph>{messages.clipModalP1}</S.Paragraph>
          <S.Paragraph>{messages.clipModalP2}</S.Paragraph>
          <S.Paragraph>{messages.clipModalP3}</S.Paragraph>
          <S.Paragraph>
            {messages.clipModalP4}{' '}
            <a
              href="https://product-catalog.boschaftermarket.com.au/mitsubishi/mitsubishi"
              style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
              target="_blank"
            >
              {messages.here}.
            </a>
          </S.Paragraph>
          <S.Paragraph>{messages.clipModalP5}</S.Paragraph>
          <S.BoldHeading $textDecoration="underline">
            {messages.clipModalP9}
          </S.BoldHeading>
          <S.Paragraph>{messages.clipModalP10}</S.Paragraph>
          <ul style={{ marginBottom: '1em' }}>
            <S.ListItem style={{ padding: 0 }}>
              {messages.bizWorkshopName}
            </S.ListItem>
            <S.ListItem style={{ padding: 0 }}>{messages.surname}</S.ListItem>
            <S.ListItem style={{ padding: 0 }}>{messages.givenName}</S.ListItem>
            <S.ListItem style={{ padding: 0 }}>
              {messages.emailAddress}
            </S.ListItem>
          </ul>
          <S.Paragraph>
            {messages.clipModalP61}{' '}
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                sendClipEmail();
              }}
              style={{
                color: 'RGB(192, 0, 0)',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {messages.clipModalP62}
            </button>
          </S.Paragraph>
          <S.Paragraph>
            {messages.clipModalP7}{' '}
            <span>
              <a
                download
                style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
                // onClick={() =>
                //   handleCloseAndRedirect('diagnostic-software-download')
                // }
                href="https://oerbchdluxfigwgevqiv.supabase.co/storage/v1/object/sign/1/0/00/MTIA%20-%20Setup%20for%20Clip%20token%20and%20Alliance%20VCI%20v5.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiIxLzAvMDAvTVRJQSAtIFNldHVwIGZvciBDbGlwIHRva2VuIGFuZCBBbGxpYW5jZSBWQ0kgdjUucGRmIiwiaWF0IjoxNjU2MzA2NzEyLCJleHAiOjE5NzE2NjY3MTJ9.SIzmNjcmCgw4MzksTtkFuBdvTVVknqYif-MXNJXe48A&t=2022-06-27T05%3A11%3A52.981Z"
                target="_blank"
              >
                {messages.here}
              </a>
            </span>
            {messages.clipModalP72}
          </S.Paragraph>
          <S.Paragraph>{messages.clipModalP8}</S.Paragraph>
        </>
      );
    } else if (type === 'Security') {
      return (
        <>
          <S.Paragraph>
            {messages.securityModalP21}
            <span>({oemSchemeName[client]})</span>
            {messages.securityModalP22}
          </S.Paragraph>
          <S.Paragraph>
            <span>{oemSchemeAbbreviation[client]}</span>
            {messages.safetyModalP3}
          </S.Paragraph>
          <S.Paragraph>
            {messages.visitAASRAWebsite}
            <a
              href="https://aasra.com.au/"
              style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
              target="_blank"
            >
              {messages.aasraWebsite}
            </a>
          </S.Paragraph>
        </>
      );
    } else if (type === 'Safety') {
      return (
        <>
          <S.Paragraph>
            {messages.safetyModalP21}
            <span>({oemSchemeName[client]})</span>
            {messages.safetyModalP22}
          </S.Paragraph>
          <S.Paragraph>
            <span>{oemSchemeAbbreviation[client]}</span>
            {messages.safetyModalP3}
          </S.Paragraph>
          <S.Paragraph>
            {messages.visitAASRAWebsite}{' '}
            <a
              href="https://aasra.com.au/"
              style={{ color: 'RGB(192, 0, 0)', textDecoration: 'underline' }}
              target="_blank"
            >
              {messages.aasraWebsite}
            </a>
          </S.Paragraph>
        </>
      );
    }
  };

  return (
    <Modal
      style={{ padding: '3em 3em' }}
      close={close}
      closeXPosition="-0.2%"
      overflow="auto"
    >
      <ModalHeader client={client} type={type} />
      <S.ContentContainer $type={type}>{renderContent()}</S.ContentContainer>
    </Modal>
  );
}

export default AssetMenuModals;
