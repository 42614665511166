import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useCallback, useEffect, useState, useContext } from 'react';

import Modal from 'components/Modal';
import * as S from './styled';
import { useAuth, useConfig, useLanguages, useLoggedIn } from 'utils/hooks';
import products from './productData';
import { supabase as getSupabase } from 'utils/supabase';
import { urlApi } from 'utils/apis';
import stepSevenImg from 'assets/login_step_7.jpeg';
import { AppContext } from 'components/App/Context';
import { useHistory } from 'react-router';
import TextInput from 'components/TextInput';
import AsyncButton from 'components/AsyncButton';

const Button = styled.button<{ $bgcolor: string }>`
  background-color: ${(props) => props?.$bgcolor};
  color: #fff;
  border: none;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 0.5em;
  padding: 0.4em;
`;
const Card = styled.div`
  align-items: center;
  border-radius: 4px;
  box-shadow: 5px 10px 18px lightgray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  width: 300px;
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const CardTitle = styled.h4`
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 1em;
`;
const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
`;
const PlanDescription = styled.div`
  align-self: flex-start;
  display: flex;
  margin-bottom: 30px;
  width: 100%;
`;
const SubTitle = styled.h3`
  font-size: 1em;
  margin-bottom: 3em;
`;
const Title = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
`;

export const NoSubBtn = styled.button`
  font-size: 1em;
  padding: 1em;
`;

const _testid = 'price_1LCyAyAMPxqRk1Rz80ZXOPDh';

function StripePayment({
  close,
  type = 'subscription',
  vin = null,
  gapid = undefined,
  email,
  subType = '',
  meta,
  sub,
  hideImage = false,
  fromSignup = false,
  originatingGap,
}: {
  close: Function;
  type?: string;
  vin?: any;
  gapid?: number;
  email?: string;
  subType?: string;
  meta?: string;
  sub?: any;
  hideImage?: boolean;
  fromSignup?: boolean;
  originatingGap?: number;
}) {
  const { messages } = useLanguages();
  const { defaults } = useConfig();
  const { user } = useLoggedIn(true, true);
  const supabase = getSupabase();
  const [items, setItems] = useState<any[]>();
  const [status, setStatus] = useState<'loading' | 'done' | ''>('');
  const { setNoSubSignin } = useContext(AppContext);
  const history = useHistory();
  const [showHWMeta, setShowHWMeta] = useState<any>(null);
  const [state, setState] = useState({
    name: '',
    address1: '',
    address2: '',
    suburb: '',
    state: '',
    postcode: '',
    mobile: '',
  });

  const client = window.location.pathname.split('/')[1];

  const doSubscribe = useCallback(
    (item, hwState: any) => {
      const url = urlApi; // `${process.env.REACT_APP_HOST}.netlify/functions`;
      const host: any = process.env.REACT_APP_HOST;
      const hwPart = hwState
        ? `&name=${hwState.name}&address1=${hwState.address1}&address2=${hwState.address2}&suburb=${hwState.suburb}&state=${hwState.state}&postcode=${hwState.postcode}&mobile=${hwState.mobile}`
        : '';
      const href = `${url}/stripeRedirect?subid=${
        item.subscriptionid
      }&type=${type}&subtype=${subType}&priceid=${
        item.subscriptionstripeid
        // item.subscriptionstripeid
      }&vin=${vin || ''}&gapid=${gapid || ''}&originatingGap=${
        originatingGap || ''
      }&email=${
        email || user?.email
      }&client=${client}&host=${encodeURIComponent(
        host
      )}&meta=${meta}${hwPart}`;
      // console.log({ href });
      // return;
      window.location.href = href;
    },
    [
      client,
      email,
      vin,
      meta,
      gapid,
      subType,
      type,
      user?.email,
      originatingGap,
    ]
  );

  useEffect(() => {
    if (sub) doSubscribe(sub, null);
  }, [sub, doSubscribe]);

  useEffect(() => {
    if (!defaults?.oemid) return;
    const f = async () => {
      setStatus('loading');
      const results: any = await supabase
        .from('tb_oem_subscriptions')
        .select('*')
        // .eq(
        //   'subscriptiontype',
        //   ['resubscribe', 'individual', 'gap', 'gapresub'].includes(type)
        //     ? 'subscription'
        //     : type
        // )
        .eq('oemid', defaults?.oemid);
      const isDev =
        window.location.host.includes('dev') ||
        window.location.host.includes('localhost');
      console.log('TYPE :::::', type);
      const itms: any[] = results?.data
        ?.filter((x) => {
          console.log(type, x.id, x.subscriptiontype);
          if (
            [
              'resubscribe',
              'individual',
              'gap',
              'gapresub',
              'subscription',
            ].includes(type) &&
            x.subscriptiontype !== 'subscription'
          )
            return false;
          if (type === 'diagnostic' && !x.subscriptiontype.includes(type))
            return false;
          if (type === 'keycode' && !x.subscriptiontype.includes(type))
            return false;
          if (isDev) return true;
          if (x.inactive) return false;
          return true;
        })
        ?.sort((a, b) => a?.subscriptionsortorder - b?.subscriptionsortorder);
      if (
        defaults?.signinnosub &&
        ['resubscribe', 'individual', 'gap', 'gapresub'].includes(type)
      ) {
        itms.push({
          id: 'free',
          subscriptionunit:
            client === 'subaru'
              ? 'Keycode & Diagnostics'
              : 'Diagnostic Software',
          subscriptionvalue: undefined,
          subscriptiondescriptionid:
            client === 'subaru'
              ? [
                  'Request key code.',
                  'Diagnostic Hardware',
                  'Diagnostic Software',
                  'Software update.',
                ]
              : ['Diagnostic Software'],
          choose: 'Click to view subscrpition options',
        });
      }
      setItems(itms);
      setStatus('done');
    };
    f();
  }, [supabase, defaults, type, client]);

  const signInNoSub = () => {
    setNoSubSignin(true);
    if (fromSignup) history.push(`/${client}/login`);
  };

  if (sub) return null;

  return (
    <>
      {showHWMeta && (
        <Modal
          close={() => setShowHWMeta(null)}
          overflow="auto"
          style={{
            width: '30rem',
            backgroundColor: '#cecece',
            zIndex: 999999,
          }}
        >
          <Container style={{}}>
            <h2>Delivery Details</h2>
            <TextInput
              value={state.name}
              label="Contact Name"
              onChange={(e) => setState({ ...state, name: e })}
              autoFocus
            />
            <TextInput
              value={state.address1}
              label="Address Line 1"
              onChange={(e) => setState({ ...state, address1: e })}
            />
            <TextInput
              value={state.address2}
              label="Address Line 2"
              onChange={(e) => setState({ ...state, address2: e })}
            />
            <TextInput
              value={state.suburb}
              label="Suburb"
              onChange={(e) => setState({ ...state, suburb: e })}
            />
            <TextInput
              value={state.state}
              label="State"
              onChange={(e) => setState({ ...state, state: e })}
            />
            <TextInput
              value={state.postcode}
              label="Postcode"
              onChange={(e) => setState({ ...state, postcode: e })}
            />

            <TextInput
              value={state.mobile}
              label="Mobile"
              onChange={(e) => setState({ ...state, mobile: e })}
            />
            <AsyncButton
              messageId="submit2"
              onClick={() => {
                if (
                  !state.name ||
                  !state.address1 ||
                  !state.suburb ||
                  !state.state ||
                  !state.postcode ||
                  !state.mobile
                ) {
                  alert('Please enter compelete delivery details');
                }
                doSubscribe(showHWMeta, state);
              }}
            />
          </Container>
        </Modal>
      )}
      <Modal
        close={close}
        overflow="auto"
        style={{ width: 'auto', maxWidth: '98vw' }}
      >
        <Container>
          {!hideImage && <S.GuideImg src={stepSevenImg} />}
          {!!items?.length && (
            <>
              <Title>{messages?.pricing}</Title>
              <SubTitle>{messages?.choosePlan}</SubTitle>
            </>
          )}
          <CardContainer>
            {status !== 'done' && <div>Loading...</div>}
            {status === 'done' && !items?.length && (
              <div>No subscriptions found</div>
            )}
            {items?.map((item, index) => {
              return (
                <Card key={index}>
                  <CardTitle>{item.subscriptionunit}</CardTitle>
                  {item.subscriptionvalue !== undefined && (
                    <p style={{ marginBottom: '1em' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                        ${item.subscriptionvalue}
                      </span>{' '}
                      / {item.subscriptionunit}
                    </p>
                  )}
                  {item['GST Setting'] && (
                    <p style={{ marginBottom: '1em', textAlign: 'center' }}>
                      <span style={{ fontStyle: 'italic', fontSize: '1em' }}>
                        {item['GST Setting']}
                      </span>
                    </p>
                  )}
                  {Array.isArray(item.subscriptiondescriptionid) ? (
                    <PlanDescription>
                      <ul style={{ width: '100%' }}>
                        {item.subscriptiondescriptionid.map((x) => (
                          <li
                            className="mb-0"
                            style={{ listStyleType: 'none', display: 'flex' }}
                          >
                            <i className="fa fa-check mr-2 text-primary"></i>
                            <p> {x}</p>
                          </li>
                        ))}
                      </ul>
                    </PlanDescription>
                  ) : (
                    <PlanDescription>
                      <ul style={{ width: '100%' }}>
                        <li
                          className="mb-3"
                          style={{ listStyleType: 'none', display: 'flex' }}
                        >
                          <i className="fa fa-check mr-2 text-primary"></i>
                          <p> {item.subscriptiondescriptionid}</p>
                        </li>
                      </ul>
                    </PlanDescription>
                  )}
                  <Button
                    $bgcolor={defaults?.primarycolour}
                    onClick={() => {
                      if (
                        client === 'subaru' &&
                        item.subscriptiontype === 'diagnostic_hardware'
                      ) {
                        setShowHWMeta(item);
                        return;
                      }
                      item.id === 'free'
                        ? signInNoSub()
                        : doSubscribe(item, null);
                    }}
                  >
                    {item.choose || 'Choose'}
                  </Button>
                </Card>
              );
            })}
          </CardContainer>
          {/* {defaults?.signinnosub && (
          <NoSubBtn onClick={signInNoSub}>
            Click here to continue without a subscription, to purchase key codes
            or software updates.
          </NoSubBtn>
        )} */}
        </Container>
      </Modal>
    </>
  );
}

export default StripePayment;
