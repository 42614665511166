import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function ({ imageList }: { imageList: string[] }) {
  const host = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
  const defaultCarouselList: string[] = [
    `${host}/images/R2R_001.jpg`,
    `${host}/images/R2R_002.jpg`,
    `${host}/images/R2R_003.jpg`,
    `${host}/images/R2R_004.jpg`,
    `${host}/images/R2R_005.jpg`,
  ];
  const getCarouselList = () => {
    return imageList && imageList?.length ? imageList : defaultCarouselList;
  };
  return (
    <Carousel
      statusFormatter={() => ''}
      showArrows={true}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      animationHandler="fade"
      width="100%"
      transitionTime={1000}
    >
      {getCarouselList().map((carousel: string, index: number) => (
        <div key={index}>
          <img src={carousel} />
        </div>
      ))}
    </Carousel>
  );
}
