import styled from 'styled-components';
import Color from 'color';

import isIE from 'utils/isIE';

export const Button = styled.button<{
  $width?: string;
  $margin?: string;
  $borderRadius?: string;
}>`
  background-color: #2d2c2c;
  border: 1px solid transparent;
  color: rgb(255, 255, 255) !important;
  height: 48px;
  outline: 0;
  margin: ${(props) => (props.$margin ? props.$margin : 0)};
  padding: 12px;
  width: ${(props) => (props.$width ? props.$width : 'auto')};
  border-radius: ${(props) => (props.$borderRadius ? props.$borderRadius : 0)};

  &:hover {
    background-color: ${() =>
      isIE() ? '#2D2C2C' : Color('#2D2C2C').lighten(0.5).toString()};
  }

  &:active,
  :focus {
    border-radius: 0;
    outline: 0;
  }
`;
