import { $CombinedState } from '@reduxjs/toolkit';
import styled from 'styled-components';

export const ContentContainer = styled.div<any>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 50vw;
`;

export const BoldHeading = styled.h3<any>`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.2em;
  text-decoration: ${(props) =>
    props.$textDecoration ? props.$textDecoration : ''};
`;

export const ListItem = styled.li`
  list-style-type: disc;
  margin: 0 0 0 3em;
`;

export const Paragraph = styled.p`
  color: RGB(0, 0, 0);
  font-family: 'mmcregular', sans-serif;
  font-size: 1em;
  margin-bottom: 1em;
  word-break: normal;
  word-wrap: normal;
  max-width: 100%;
`;
