import styled from 'styled-components';

export const Container = styled.div`
  /* border: 1px solid #e8e8e8; */
  color: black;
  height: 100%;
  /* margin: 2em; */
  padding: 1em 2em;
`;

export const Header = styled.div`
  width: 100%;
  background-color: #e8e8e8;
  font-size: 12px;
  font-family: 'mmcbold', sans-serif;
  display: flex;
  text-transform: uppercase;
`;

export const Document = styled.div`
  width: 40%;
  padding: 12px;
`;

export const Date = styled.div`
  width: 6.6%;
  padding: 12px;
  display: flex;
  align-items: center;
`;

export const Language = styled.div`
  width: 17%;
  padding: 12px;
  display: flex;
  align-items: center;
`;

export const Action = styled.div`
  width: 6.6%;
  padding: 12px;
  display: flex;
  align-items: center;
`;

export const Type = styled.div`
  font-size: 14px;
  font-family: 'mmcbold', sans-serif;
  color: black;
  padding: 0;
`;

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  font-family: 'mmcnormal', sans-serif;
  color: black;
  &:nth-last-of-type() {
    border: none;
  }
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const TableHeader = styled.thead`
  /* width: 100%; */
  background-color: #e8e8e8;
  /* font-size: 12px; */
  font-family: 'mmcbold', sans-serif;
  /* display: flex; */
  text-transform: uppercase;
`;

export const TableRowType = styled.p`
  /* font-size: 14px; */
  font-family: 'mmcbold', sans-serif;
  color: black;
  padding: 0;
  text-align: left;
  /* added to override css_ForN_WIwFm_EkMzNORYxOzzP3WazIhpOpmo8jL4_ZXs styling */
  margin-bottom: 0;
`;
