import { useState } from 'react';
import styled from 'styled-components';

import TextInput2 from 'components/TextInput2';
import { useLanguages } from 'utils/hooks';
import AddressForm from './AddressForm';
import { UserData } from 'types';

const ToggleButton = styled.button`
  padding: 1rem 0;
`;

function UserForm({
  data,
  setData,
  validateUser,
  existingUser,
  disableEmail,
  signuponlyindividual,
}: {
  data: UserData;
  setData: Function;
  validateUser: Function;
  existingUser: boolean;
  disableEmail?: boolean;
  signuponlyindividual?: boolean;
}) {
  const [toggleAddress, setToggleAddress] = useState<boolean>(false);
  const { messages } = useLanguages();

  return (
    <div style={{ marginTop: '1.6em' }}>
      {!toggleAddress && (
        <>
          <TextInput2
            value={data?.useremailid}
            label={messages.email}
            autoFocus={!disableEmail}
            required
            example="joe@email.com"
            onChange={(e) => {
              const email = e?.toLowerCase();
              setData({ ...data, useremailid: email });
            }}
            onBlur={validateUser}
            disabled={disableEmail}
            style={{ width: '400px', padding: '0.5rem' }}
          />
          {!existingUser && (
            <>
              <TextInput2
                value={data?.password}
                label={messages.password}
                autoFocus={disableEmail}
                required
                example="*****"
                password
                onChange={(e) => setData({ ...data, password: e })}
                style={{ width: '400px' }}
              />
              <TextInput2
                value={data?.mobilenumber}
                label={messages.mobile}
                required
                example="+61412345678"
                onChange={(e) => {
                  if (!e.startsWith('+61')) {
                    e = '+61';
                  }
                  setData({ ...data, mobilenumber: e });
                }}
                style={{ width: '400px' }}
              />
              <TextInput2
                value={data?.firstname}
                label={messages.firstName}
                required
                example="Joe"
                onChange={(e) => setData({ ...data, firstname: e })}
                style={{ width: '400px' }}
              />
              <TextInput2
                value={data?.surname}
                label={messages.surname}
                required
                example="Smith"
                onChange={(e) => setData({ ...data, surname: e })}
                style={{ width: '400px' }}
              />
              {signuponlyindividual && (
                <>
                  <TextInput2
                    value={data?.companyname}
                    label={messages.companyName}
                    required
                    example="e.g. Ultratune"
                    onChange={(e) => setData({ ...data, companyname: e })}
                    style={{ width: '400px' }}
                  />
                  <TextInput2
                    value={data?.abn}
                    label={messages.abn}
                    required
                    example="1234567890"
                    onChange={(e) => setData({ ...data, abn: e })}
                    style={{ width: '400px' }}
                  />
                </>
              )}
              {/* {toggleAddress && (
                <AddressForm data={data} setData={setData} required={false} />
              )}
              {toggleAddress ? (
                <ToggleButton
                  type="button"
                  onClick={() => setToggleAddress(false)}
                >
                  Account details
                </ToggleButton>
              ) : (
                <ToggleButton
                  type="button"
                  onClick={() => setToggleAddress(true)}
                >
                  Address details (optional)
                </ToggleButton>
              )} */}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UserForm;
