import { useParams } from 'react-router-dom';
import Search from './screens/Search';
import VinSearch from './screens/VinSearch';

export default function SearchRoot({ defaults }: any) {
  const { assetCategoryName: catName }: any = useParams();

  if (catName === 'diagnostic-software') {
    return <Search />;
  }

  if (defaults?.resultsfromvinlookup) return <VinSearch />;
  else return <Search />;
}
