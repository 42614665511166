import { atom } from 'recoil';

import { AssetType, Language } from 'types';
import { useGeneric } from 'utils/genericData';

type State = {
  data?: AssetType[];
  ids?: AssetType[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const AssetTypesState = atom({
  key: 'AssetTypes',
  default: initialState,
});

export function useAssetTypes() {
  const xport = useGeneric(AssetTypesState, 'tb_assettype', 'assettype');

  return xport;
}
