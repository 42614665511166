import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 24px;
  font-family: 'mmcbold', sans-serif;
`;

export const H4 = styled.h4`
  font-size: 16px;
  font-family: 'mmcnormal', sans-serif;
  margin-top: 8px;
`;
